import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-economics',
  templateUrl: './economics.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class EconomicsComponent implements OnInit {
  



tutorData=[  {
  name:"Varnika",
  exp:" 4 years ",
  grade:"1st to 8th"
},{
  name:"Shivangi Kudeshiya",
  exp:"9 years",
  grade:"1st to 10th "
},{
  name:"Kalyani bule",
  exp:"4 years",
  grade:"9th to 12th "

}

]
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is Duration of Online Economic Tuition?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "At Ziyyara, a student studies for 60 minutes which is quite like the normal Economic Tuition classes they take from home tutors or at Economics coaching classes."
    }
  },{
    "@type": "Question",
    "name": "Can I take a break during the Online Economic Tuition?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, if a student wants a break between the class then he/she has the freedom to do so."
    }
  },{
    "@type": "Question",
    "name": "How is Ziyyara's tutor different from Other tutors?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Every teacher is best in their own way, but with tutors at Ziyyara a parent or a student doesn’t have to worry about their background check and expertise as every tutor has to go through a written and verbal assessment to become a tutor at Ziyyara’s platform."
    }
  },{
    "@type": "Question",
    "name": "How do I apply for a course/Classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A student just has to register at www.ziyyara.in and have to fill minimum details like their name, email id & contact number, and once registered then they can find the best economics tutors available on the website and can begin their online tuition journey by booking a class with them."
    }
  },{
    "@type": "Question",
    "name": "Is the online payment taken immediately?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "It’s just like you recharge your prepaid mobile phone, but the best part of Ziyyara’s classes is that it doesn't have an expiration date.If you’re not taking classes this month and you’ve an amount in your wallet, then you can take your online classes at any point of time."
    }
  }]
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }


  cards = [
    // {
    //   title: 'Economics',
    //  // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    //   buttonText: 'Read',
    //   img: '/assets/images/Economics.jpg'
    // },
    {
      title: 'Maths',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Mathematics.jpg'
    },
    {
      title: 'English',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/English.jpg'
    },
    {
      title: 'Physics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Physics.jpg'
    },
    {
      title: 'History',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/History.jpg'
    },
    {
      title: 'Hindi',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Hindi.jpg'
    },
    {
      title: 'Geography',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Geography.jpg'
    },
    {
      title: 'Business Studies',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Business-Studies.jpg'
    },
    {
      title: 'Biology',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Biology.jpg'
    },
    {
      title: 'Political-Science',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Political-Science.jpg'
    },
    {
      title: 'Accountancy',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Accountancy.jpg'
    },
    {
      title: 'Chemistry',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Chemistry.jpg'
    },

    {
      title: 'Computer Science',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Computer-Science.jpg'
    },
    {
      title: 'Vedic Mathematics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/vedic-math.webp'
    },
  ];



  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }











  ngOnInit() {
    //slider
  this.slides = this.chunk(this.cards, 3);
  this.titleService.setTitle( " Best Online Tuition for Economics | Economics Online Tuition");
 
 
  var desc="Ziyyara provides best online tuition for economics. Find the most experienced and qualified tutors at an affordable price. For a free demo call us - +91 9654271931."
  this.meta.updateTag({ name: 'description', content: desc })
  var keywords="economics tuition, economics tutor, economics tutor online, , economics tutors, tutor for economics, best economics tutor, economics home tuition, economics tuition near me, private economics tuition, online tuition for economics   ";
  this.meta.updateTag({ name: 'keywords', content: keywords });
  var url=  location.pathname;

  var ogtitle=" Best Online Tuition for Economics | Economics Online Tuition";
  this.meta.updateTag({ property: 'og:title', content: ogtitle });
  var ogdesc="Ziyyara provides best online tuition for economics. Find the most experienced and qualified tutors at an affordable price. For a free demo call us - +91 9654271931.";
  this.meta.updateTag({ property:'og:description', content: ogdesc });

  var twittertitle=" Best Online Tuition for Economics | Economics Online Tuition";
  this.meta.updateTag({ name:"twitter:title", content: twittertitle });
  var twitterdesc="  Ziyyara provides best online tuition for economics. Find the most experienced and qualified tutors at an affordable price. For a free demo call us - +91 9654271931.";
  this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
  this.meta_service.createCanonicalURL(url);
  this.meta_service.removeStructuredData();
  this.meta_service.insertSchema(this.websiteSchema);


   
 }



  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase()
    if(subject.includes("vedic"))
    
    {
      return "/vedic-math-workshop"
    
    } 
      else if (subject.includes("computer")){
        return '/home-tuition/online-home-tuition-for-computer-science'
      }
      else if (subject.includes("business")){
        return '/home-tuition/online-home-tuition-for-business-studies'
      }
    else {

      return '/home-tuition/online-home-tuition-for-'+subject
    }
  
  }
}
