import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
 
@Injectable({
   providedIn: 'root'
})
 
export class MetaService { 
   constructor(@Inject(DOCUMENT) private dom) { }
    
   createCanonicalURL(url) {
      const head = this.dom.getElementsByTagName('head')[0];
      var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
      if (element==null) {
        element= this.dom.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
      }
      element.setAttribute('rel','canonical')
      element.setAttribute('href',url)
   }

   static scriptType = 'application/json+ld';


	

	removeStructuredData(): void {
		const els = [];
		[ 'structured-data', 'structured-data-org' ].forEach(c => {
			els.push(...Array.from(this.dom.head.getElementsByClassName(c)));
		});
		els.forEach(el => this.dom.head.removeChild(el));
	}

	insertSchema(schema , className = 'structured-data'): void {
		let script;
		let shouldAppend = false;
		if (this.dom.head.getElementsByClassName(className).length) {
			script = this.dom.head.getElementsByClassName(className)[0];
		} else {
			script = this.dom.createElement('script');
			shouldAppend = true;
		}
		script.setAttribute('class', className);
		script.type = MetaService.scriptType;
		script.text = JSON.stringify(schema);
		if (shouldAppend) {
			this.dom.head.appendChild(script);
		}
	}


} 