export const ar = {
    video_url :"https://www.youtube.com/embed/IxJ2gAnB5Bw?rel=0&autoplay=1" ,
    login : {
        login_title:"عندي حساب",
        email_title:"Email",
        password_title:"Password",
        forgot_password:"هل نسيت كلمة المرور؟",
        login_google_stu:"تسجيل الدخول إلى حساب جوجل كطالب",
        login_google_tutor:"تسجيل الدخول إلى حساب جوجل كمعلم",
        dont_have_account:"ليس لديك حساب",
        reister_title:"التسجيل",
    }, 
    signup:{
        register:"التسجيل",
        ful_name:"Full Name",
        email:"Email",
        reg_as_student:"التسجيل كطالب",
        reg_as_tutor:"التسجيل كمعلم",
        password:"Password",
        confirm_password:"Confirm Password",
        already_registered:"مسجل بالفعل",
        login_title:"تسجيل الدخول",
        password_and_confirm_not_match:"Password and confirm password does not match", 
    },
    header:{
        quote:" جرب زيارة بالمجان",
        free:"",
        registernow:"التسجيل",
        login:"عندي حساب",
        register:"التسجيل",
        arabic_text:"اللغة العربية"
    },
    home:{
        label: " Ziyyara:تقدم المناهج مبسطة",
        sub_label:"sss",
        t_life: "التعليم من أجل الحياة",
        t_life_sub: "السبورة التفاعلية",
        t_tommorow: "التعليم من أجل المستقبل",
        t_tommorow_sub: "دردشة فيديو وجها لوجه",
        t_knowledge: "التعليم من أجل المعرفة",
        t_knowledge_sub: "اختر معلمك",
        find_tutor: "Find Tutor",
        find_sub: "ابحث عن الموضوعات",
        education_text: "تقديم الخدمات التعليمية",
        education_content: "نضمن لك على منصة Ziyyara اهتماما شخصيا بنسبة 100% من خلال تقديم خطط دروس تتسم بالطابع الشخصي وجداول دراسية بناء على طلبك.",
        education_text_link: "أنا مستعد للتعلم",
        anywhere_text: "أينما كنت",
        anywhere_content: "سواء آناء الليل أو أطراف النهار، اقضِ بعضًا من الوقت لإنجاز أمورك الهامة وسنتولى نحن مهمة إنجاز ما تبقى منها.",
        anywhere_text_link: "هيا بنا",
        foryou_text: "Ziyyara هنا من أجلك",
        foryou_content: "تتربع مهمة جعل عملية التعليم أكثر سهولة ومتعة على قمة أولوياتنا. نصمم الاحتياجات التعليمية التي يستحقها طفلك ونقدمها له عند الطلب.",
        foryou_text_link: "هيا نبدأ",
        howitworks: "كيف أنضم؟",
        findtutor: "اختر معلمك",
        needthem: "يوجد عدد ضخم من المعلمين لمساعدتك وقتما تحتاج إليهم",
        booksession: "احجز جلسة تعليمية",
        anytime: "في أي وقت ومن أي مكان",
        startlearning: "ابدأ التعلم",
        easyas: "التعلم معنا أسهل ما يمكن",
        readytolearn: "مستعد للتعلم",
        find_tutor_down: "Find Tutor",
        find_sub_down: "ابحث عن الموضوعات",
    },
    footer: {
        company: "الشركة",
        about: "نبذة عنا",
        howitworks: "كيف أنضم؟",
        careers: "الوظائف",
        explore: "ابحث",
        students: "الطلاب",
        tutor: "المعلمين",
        faq: "الأسئلة الشائعة",
        reachus: "تواصل معنا",
        omanaddresstitle: "العنوان في سلطنة عمان:",
        address: "شقة 301، مبنى العز، شارع البركات، الحيل الجنوبية، مسقط، عمان",
        indadresstitle: "العنوان في الهند:",
        indadress: "C-360, LGF، ديفينس كولوني، جنوب دلهي، نيو دلهي – 110024 الهند",
        generalenquires: "للاستفسارات العامة:",

        t_c: "شروط وأحكام سياسة الخصوصية",
        policy: "Privacy Policy",
        copyright: {
        ziyyara: "Ziyyara",
        reserved: " جميع الحقوق محفوظة",
        },
    },
    about: {
        title: "من نحن",
        deliver: "نقدم الخدمات إلى...",
        student: "الطلاب",
        studentcontent: "من خلال معلمك المفضل والمواد المتوفرة لديك، تُلبي زيارة رغبتك في التعلم وتزودك بالإمكانيات، ومزيدًا من الوقت والطاقة لأجلك ولأجل من تُحب.",
        studentlink: "اشترك الآن",
        tutor: "المعلمين",
        tutorcontent: "تساعد حلول Ziyyara المبتكرة التي تركز على عملية التعلم على تعزيز نجاحك من خلال تغيير طريقة تعلُمك. افتح أفاق عقلك للاستفادة من تلك القدرات التعليمية الشاملة ولاحظ نمو تحصيلك حد الوقت الذي تحتاجه.",
        tutorlink: "انضم الآن",
        ziyara: "مؤسسة Ziyyara",
        ziyaracontent: "حذّر البنك الدولي من أنه يوجد أكثر من 260 مليون طفل حول العالم غير ملتحقين بالمدرسة كما أن نصف هذا العدد ممن يلتحقون بالمدرسة لا يتعلمون شيئًا جديدًا. لذا، نسعى في مؤسسة Ziyyara إلى تغيير مفهومة المدرسة ككل. سوف تُخصص نسبة قدرها 15% من أرباح Ziyyara لمساعدة أحد المدارس في المنطقة الريفية عن طريق تزويدها بأداة تعلم فعالة كما نأمل في المُضي بالطالب والأسرة والمجتمع نحو مستقبل أفضل.",
        ziyaralink: "تغيّر الآن",
    },
    howitworks: {
        title: "How it works",
        registertitle: "Tutor's Registration Process",
        login: "Tutor's Login Process",
        stu_reg_log: "Student Registration & Login Process"
    },
    career: {
        title: "Career",
        para1: "At Ziyyara we value our team members as an asset for the organization, believe in empowering them and  enabling  them to discover their potential at work. We create an environment where skills are honed, achievements are   honored, and every contribution is valued.",
        para2: "Today, we are on a rapid growth path wherein we aim to sustain & strengthen our position as providers of  quality  services. This can only be achieved by the contribution of all our team members. Our focus is on    relentless value     addition to enhance the competencies of our people.",
        para3: "Our HR processes help you develop as a professional, grow in life and thereby contribute to the overall        growth of        the organization.",
        para4: " If you want to be a part of “Team Ziyyara”, send your resume to "
    },
    faq: {
        title: "الأسئلة الشائعة",
        q1: "س: كيف استفيد من منصة Ziyyara؟",
        q1a: "تعد Ziyyara منصة إلكترونية تمنحك دعمًا أكاديميًا قويًا حول مجموعة واسعة من الموضوعات على المستوى الدراسي. وسوف يشارك معلمون متخصصون عبر الإنترنت في جلسات بث حي لدورات دراسية وجهًا لوجهه مع الطلاب لتوفير حصص دراسية مخصصة ومساعدتهم في التعلم من مكانهم الموجودين فيه، وذلك عبر الإنترنت.",
        q2: "س: ما مدة كل جلسة من جلسات الدورات التدريبية؟",
        q2a: "تستغرق كل جلسة تعليمية عبر الإنترنت 60 دقيقة. لقد وضعنا خطط دروسنا بطريقة تتيح للطالب والمعلم التفاعل لمدة لا تتجاوز 60 دقيقة حيث اتضح أنه الحد الذي تقل بعده قدرة الطالب على التعلم بشكل كبير.",
        q3:" كيف تقدم منصة Ziyyara س: عملية التعلم عبر الإنترنت؟",
        q3a:"تستخدم Ziyyara نظام القاعات التدريبية عبر الإنترنت التي توفر نفس تجربة الفصول الدراسية، والتي يركز فيها المعلمون على دعم طلابهم بشكل أساسي. كما تتضمن هذه الدورات التدريبية العرض على سبورة بيضاء وعرض الواجهة بالصوت والصورة (فيديو) كما يمكن إجراء محادثة بالإضافة إلى وجود عناصر تعلم مبتكرة ومتعددة من شأنها جعل عملية التعليم ممتعة وفعالة.",
        q4:"س: هل يمكنني الوصول إلى الجلسات المُسجلة؟",
        q4a:"تتوفر كافة المواد التي نوقِشت في الفصل وكذلك تسجيلات جلسات الدورات الدراسية للطلاب خلال فترة زمنية معينة. كما يمكن لأولياء الأمور أيضًا الوصول إلى الجلسات المسجلة بالفيديو ومراجعتها أو الاطلاع عليها في أي وقت.",
        q5:" هل توفر منصة Ziyyara أي مواد دراسية أو ملاحظات علمية؟ س:",
        q5a:"نستخدم نفس الكتاب الذي يتبعه الطلاب في المدرسة كمرجع لهم عند تطوير خطة التعلم الفردية. وسيتم مشاركة خطة التعلم الفردية مع أولياء الأمور والطلاب.",
        q6:"س: هل تعادل فعالية الدورات التدريبية عبر الإنترنت نفس فعالية الدورات العادية؟",
        q6a:"تُعد الدورات التدريبية عبر الإنترنت دورات دراسية تحضرها عبر الإنترنت من المنزل ويتمثل الفرق الوحيد بينهما في الإنترنت الخاص بك، حيث تعد الدورات التدريبية عبر الإنترنت متاحة في أي وقت مناسب لطفلك بالإضافة إلى ميزة أخرى تتمثل في التعلم الآمن المريح له من منزلك الخاص. فمن شأنها أيضًا حماية الطفل من مخاطر التنقل، وبالتالي تفادي السفر لساعات طويلة للوصول إلى المركز التعليمي. حيث يمكنك التعلم من أي مكان وفي أي وقت عن طريق الدورات التدريبية عبر الإنترنت.",
        q7:" س: Ziyyara ما هي متطلبات الدراسة عبر الإنترنت على منصة ؟",
        q7a_p1:"حاسوب شخصي أو حاسوب محمول حديث بنسخة Windows 7 أو الإصدار الأحدث منها.",
        q7a_p2:" سماعة رأس عالية الجودة وميكروفون (ويُفضل: كاميرا الويب)",
        q7a_p3:"اتصال بالإنترنت عال السرعة يمكن الاعتماد عليه (ويُفضل: سرعة أعلى من 2 ميجا بايت/ث)",
        q7a_p4:" لوحة كتابة رقمية/ قلم للكتابة على السبورة البيضاء",
        q7a_p5:"سنقوم بإجراء فحص تقني للنظام الخاص بك كي يتمكن فريق دعم تكنولوجيا المعلومات لدينا من مساعدتك في تهيئة الحاسوب الخاص بك للدورات التدريبية عبر الإنترنت على منصة Ziyyara.",
        q8:" س: هل سيقدم ليّ المعلم إجابة على التساؤلات؟",
        q8a:" تتمثل فلسفة Ziyyara في مساعدة الطلاب على استيعاب المفاهيم التي تنطوي وراء ما يعملون عليه حتى يتسنى لهم حل المشكلات والوصول إلى الإجابات بمفردهم. ولا تُعد منصة Ziyyara خدمة لتقديم الإجابات.",
        q9:"س: أيمكنني التعامل مع نفس المعلم مرة أخرى؟",
        q9a:"نعم. يمكنك إضافة معلمين إلى قائمة المفضلة الخاصة بك لمعرفة متى يكونوا متاحين لتقديم جلسات أخرى. أما في حال كان المعلم المفضل لديك يعمل مع طالب آخر، فيُمكنك انتظاره أو دعنا نرشح لك معلم آخر على مستوى عالي من الكفاءة لمساعدتك.",
        q10:" س: ماذا لو لم يتمكن المعلم الخاص بي من مساعدتي؟",
        q10a:"في حال لم يتمكن من الإجابة على سؤالك، فسيكون سعيدًا بتوجيهك إلى معلم آخر فورًا (ولن تستغرق أي وقت في انتظاره). في حال تلقيت جلسة دراسية غير راضٍ عنها تمامًا، فيُمكنك المطالبة برصيد الدقائق المُستغرقة خلال هذه الجلسة. سنراجع الجلسات، وإن لم تفي بمعايير الجودة والدقة الخاصة بنا، سنعيد تلك الدقائق المُستغرقة إلى رصيدك على الحساب الخاص بك.",
        q11:"س: هل ينبغي أن أكون ملمًا جيدًا بكافة مهارات الحاسوب؟",
        q11a:"لا، بالطبع لا تحتاج ذلك. فقط ستحتاج الإلمام بمهارات الحاسوب الأساسية مثل التصفح عبر الإنترنت أو التحقق من رسائل البريد الإلكتروني. إذا تمكنت من تسجيل الدخول وقراءة هذا المحتوى، فإنك بالفعل ذو مهارة تُمكنك من الاشتراك في هذا البرنامج.",
        q12:" س: هل تتيح المنصة إمكانية حجز جلسة دراسية في نفس الوقت؟",
        q12a:" يُمكنك حجز أي جلسة دراسية قبل 24 ساعة على الأقل. كما نُقدم جلسات دراسية جماعية وفردية.",
        q13:"س: هل هناك أية شروط وأحكام وسياسات خصوصية؟",
        q13a:"نعم، يُمكنك العثور على الشروط والأحكام وكذلك سياسة الخصوصية الخاصة بنا.",
        q14:" س:  في الجلسات الدراسية؟ Google Chrome  لِمَ عليّ استخدام متصفح جوجل كروم",
        q14a:"يُقدم متصفح Google Chrome أفضل تجربة فيديو WebRTC. حيث يشمل أفضل مقاطع فيديو عالية الجودة كما أنه المنصة الأكثر ثباتًا للدروس المُقدمة عبر الفيديو.",
        q15:" س: ما النصائح الموصي بها داخل الفصل الدراسي؟",
        q15a:" عليك الاستعداد قبل تلقي جلستك الدراسية بارتداء سماعة الرأس الخاصة بك. قد يتطلب القيام بذلك خلال الجلسة التعليمية استكمال عملية الإعداد مرة أخرى، مما قد تؤدي إلى إيقاف البث المباشر للدرس. لذا، استغل وقتك بحكمة عن طريق الاستعداد قبل الجلسة بمدة لا تقل عن 15 دقيقة.",
        q16:" س: ماذا يحدث في حال نفذ رصيد الدقائق الخاصة بي؟",
        q16a:"  يُمكنك إضافة رصيد إلى محفظتك قبل بدء الجلسة التعليمية عبر الإنترنت الخاصة بك، وسيتم محاسبتك على أساس برنامج دراسي قائم على الجلسات. ",
        q17:" س: هل يُستدعي القلق بشأن المبلغ الموجود في محفظتي؟",
        q17a:"نستخدم مزود خدمة دفع إلكتروني آخر آمن وموثوق، وبذلك يتسنى لك إضافة رصيد إلى محفظتك من خلال إيداع مبلغ معين عبر بوابة إلكترونية آمنة. وأبرزها، أننا لم نحتفظ بتفاصيل حسابك على موقع الويب.",
        q18:"س: وفي حال التوقيع لمرة واحدة، هل سيُعتبر ذلك عقدًا طويل الأجل؟",
        q18a:"لا. لأن هذه المنصة تعمل على أساس الدفع أولًا بأول.",
        q19:"  س: ماذا لو أبديت عدم تقبلي للجلسة التعليمية عبر الإنترنت للمعلم الخاص بي؟",
        q19a:"فمن الوارد وجود حالات نادرة عندما لا تفي الجلسة التعليمية لمعلمك بتوقعاتك، وليس في مصلحة منصة Ziyyara ولا المعلمين حدوث ذلك. كن مطمئنًا، سننظر في الأمر وسيجري فريقنا تحقيقًا مع كلا الطرفين. في حال هناك خلافًا مع معلم، سنُقيّم الموقف ونبُت فيه وفقًا لهذا التقييم.",
        q20:" س: لماذا ينبغي عليّ الدفع مقابل الدورات التدريبية عبر الإنترنت؟",
        q20a:" يُقدم المعلم عبر منصة Ziyyara المساعدة في أصعب الأوقات التي لا يمكنك فيها الاستعانة بمدرس المدرسة أو الاعتماد على الدروس الخصوصية. فمن شأن منهجنا المرن جعل الدورات التدريبية عبر الإنترنت بديلًا مجديًا من حيث التكلفة، لذا فإنك ستدفع مقابل وقت المعلم وخبرته العلمية بالإضافة إلى مساعدته لكم في الوقت المطلوب.",
        q21:"س: كيف يمكنني التواصل مع ممثلي دعم العملاء؟",
        q21a:"يمكنك إرسال رسالة بريد إلكتروني على admin@ziyyara.in. نحن متوفرون خلال أيام الأسبوع من الأحد إلى السبت من الساعة السابعة صباحًا وحتى التاسعة مساءً بتوقيت مسقط."
    }
    
};
