import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmationBoxComponent } from '../../../../learning/shared/components/confirmation-box/confirmation-box.component';
import { Store, select } from '@ngrx/store';
import { ApiResponse, AppState, IPROPERTY } from '../../../../interfaces';
import { Subscription } from 'rxjs';
import { AppService, HttpService } from '../../../../services';

import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { state } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent {

  stateSub: Subscription;
  property;
  userType;
  notification;
  groupClassActive;
  quranWebinarActive;
  constructor(public dialog: MatDialog, private store: Store<AppState>,
    private appSer: AppService, private _api: HttpService, private _router: Router,
    private _route: ActivatedRoute) {

   //   this.getNotification();

  }
  OSName
  ngOnInit() {
    window.scrollTo(0, 0);
    this.stateSelector();
    this.groupClassActive=localStorage.getItem("group_class");
    this.quranWebinarActive=localStorage.getItem("quran_webinar");
    console.log(this.quranWebinarActive)
    
  }
  navigateToUrl(url: string) {
    this._router.navigate([url])

    if (navigator.appVersion.indexOf("Mac")!=-1){

      setTimeout(() =>{
      
        window.location.reload()
      },500)
     

    }
    
   
    
    if (navigator.appVersion.indexOf("Mac")!=-1) this.OSName="MacOS";
 
  }





  stateSelector() {
    this.stateSub = this.store
      .pipe(select("applicationState"))
      .subscribe((appState: AppState) => {
        if (appState.property&&appState.property.u_type == undefined) {
          this.userType = 0;
        } else {
          try{
            this.userType = appState.property.u_type;
            this.property = appState.property;
          }catch(err){
            
          }
          
        }


      });
  }
  logout() {
    const content = "Are you sure want to logout?";
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      width: '31%',
      data: {
        content: { title: 'Logout', message: content },
        action: 'active'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.response) {
          this.appSer.logout();
         

          if (navigator.appVersion.indexOf("Mac")!=-1){
      
            setTimeout(() =>{
              this.OSName="Mac"
              window.location.reload()
            },500)
           
      
          }
        }
      }
    });
  }



  getNotification(){

  
    const url = 'getNotification/'+localStorage.getItem("user_id");
    this._api
      .getReqAuth(url)
      .subscribe(
        res => this.success(res),
        err => this.error(err),
        
      );


  }

  success(res){
////console.log(res)

if(res.status){

  this.notification=res.result;
  ////console.log(this.notification)

  localStorage.setItem("notification",JSON.stringify(this.notification))

}

  }

error(err){

}
}
