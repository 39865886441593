import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-mumbai',
  templateUrl: './mumbai.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class MumbaiComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How Ziyyara is different from others and how your team works?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara offers one-on-one online tuition classes with a focus on improving the academic skills of the students. Our expert Mumbai online home tutor at Ziyyara stays engaged in offering LIVE, 1-on-1 teaching sessions that can be customized as per the desires and requirements of the students."
    }
  },{
    "@type": "Question",
    "name": "How tutors at Ziyyara teach online?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We at Ziyyara refer to the latest interactive virtual classroom system allowing our students to enjoy a real classroom experience. We use an interactive virtual whiteboard so that all the concepts taught can easily be explained to the students. The entire communication is a two-way video/audio process allowing students to chat with the Mumbai tutor for online tuition instantly."
    }
  },{
    "@type": "Question",
    "name": "Can my father get the access to the recorded online tuition in Mumbai sessions as he doesn't sit with me during the sessions?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, of course. All the concepts discussed by the online tuition tutor in Mumbai during the classes will be provided to the students along with the recordings of the tuition sessions. You are suggested to inform the coordinating team of Ziyyara in advance so that you can get recorded video sessions."
    }
  },{
    "@type": "Question",
    "name": "I am confused between Online tuition in Mumbai versus regular tuitions, is online tuition effective?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Online tutoring in Mumbai are actually virtual home tuitions that are conducted over the internet. The main advantage of online tuition is that your tutors in Mumbai would be available over the internet to clear your doubts. Also you can save a lot of time commuting, which sometimes become quite a challenging task in cities like Mumbai. With online tuitions, you can enjoy the privilege of taking online classes anywhere, at any time."
    }
  }]
}


cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle("  Online Home Tuition in Mumbai | Tutors in Mumbai | Tuition in Mumbai");
   var desc=" Ziyyara have specialized online home tutors in Mumbai for the students of grade 5-12. Get online home tuition in Mumbai and enjoy online live tuition today.   "
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ name: 'keywords', content:"home tutor in mumbai, home tutors in mumbai, home tuition in mumbai, online tuition in mumbai, home tuition in mumbai, home tuition for mumbai, tuition in mumbai, mumbai home tuition, Online tuition in mumbai, tuition online in mumbai, mumbai home tuition, mumbai online tuition, online home tuition in mumbai, home online tuition in mumbai, online home tuition for mumbai"})
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url)
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema)

   
   }
   
}
