import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-kerala',
  templateUrl: './kerala.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class KeralaComponent implements OnInit {
 


  tutorData=[  {
    name:" Nupur Jain",
    exp:"10 years",
    grade:"4th to 12th "
  },{
    name:"Ritu Sharma",
    exp:"10 years",
    grade:"1st to 12th "
 },{
    name:"Prateek Sharma",
    exp:"6 years",
    grade:"9th to 12th "
  
  }

]
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How can I get a tutor for online home tuition for Kerala at Ziyyara?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You just need to visit our website and fill your enquiry based on the subject you want to study. To get in touch with us for the best home tutor in Kerala, you can either fill the enquiry form or get in touch with us by giving a call on the number provided at the website."
    }
  },{
    "@type": "Question",
    "name": "If students are not satisfied with online tuition and with a tutor in Kerala, how will feedback be considered?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We strongly believe in ensuring that all the students taking online classes in Kerala are completely satisfied with our teachings. Our teaching values include transparency and openness, so we respect all the reviews and leave no stone untouched in delivering the best services to them. You are requested to contact our coordinating team for advice and for sharing your reviews related to Kerala online home tutor."
    }
  },{
    "@type": "Question",
    "name": "How effective is online tuition in Kerala?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "As a parent or guardian, we completely understand your concerns. While planning customized classes for the students, we ensure that we meet their expectations and leave no stone untouched in providing what is expected from us. In this high-tech digital world, nothing can replace the comfort of taking classes from online tuition tutors in Kerala from the comfort of our zone. If online tutoring in Kerala takes place at home, we would strongly recommend you to take it from the comfort of your home rather than wasting so much commuting time."
    }
  },{
    "@type": "Question",
    "name": "How does payment work?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Once you are satisfied with the demo or trial online Kerala home tutoring provided by us, you can enroll at our platform. We charge nominal prices for the top-notch services and for us nothing is more important than quality."
    }
  }]
}



cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }


  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
    this.titleService.setTitle("Online Home Tuition in Kerala | Tutors in Kerala | Tuition in Kerala");
    this.meta.updateTag({name:'description', content:"Get tutored from the best online home tutor in Kerala offering holistic learning. Also finish your school syllabus before time by taking Ziyyara's home tuition in Kerala."})
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta.updateTag({name: "keywords", content:"home tuition in kerala, tuition in kerala, home tutor in kerala,home tuition in kerala, home tuition for kerala, tuition in kerala, kerala home tuition, Online tuition in kerala, tuition online in kerala, kerala home tuition, kerala online tuition, online home tuition in kerala, home online tuition in kerala, online home tuition for kerala, home tutor in kerala"})
    this.meta_service.insertSchema(this.websiteSchema);


  }

}
