<div class="card card-max mt-2">
    <div class="row pad-1">
        <div class="col-sm-4">
            <div class="search-image-wrapper ">

                <div class="profile-image-wrapper" [ngStyle]="{background:'url(assets/images/about/three.jpeg)'}">
                </div>

                <div class="search-image-inner">
                    <img src="" alt="">
                </div>
            </div>
        </div>
        <div class="col-sm-8">
            <div class="search-subject-details">
                <h5 class="card-title"> {{tutorData.name}}
                </h5>
                <p class="card-text"><strong>Total Experience: </strong> {{tutorData.exp}}</p>
                <p class="card-text"><strong>Grades: </strong> {{tutorData.grade}}</p>


            </div>
            <div class="actions df">
                <a routerLink="/ad-contact" class="card-link btn btn-warning">Free Demo</a>
                <a href="tel:9654271931" class="card-link btn btn-success">Call</a>
                <a href="https://api.whatsapp.com/send?phone=919654271931" class="card-link btn btn-default">Whatsapp Us</a>
            </div>
        </div>



    </div>

</div>