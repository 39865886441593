import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-physics',
  templateUrl: './physics.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class PhysicsComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Do I get another Demo for Free if i am not satisfied with the physics tutor?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Till the time, you are not satisfied with the Demo, you can take Demo with another tutor."
    }
  },{
    "@type": "Question",
    "name": "How long will the online physics tutor take one Demo?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Demo time is for 30 to 60 mins depending on the topic of the class."
    }
  },{
    "@type": "Question",
    "name": "If I am satisfied with Physics Demo, When can I start my regular classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "After satisfied demo, you can make a payment via Bank Transfer, Paypal and can start the regular classes"
    }
  },{
    "@type": "Question",
    "name": "Do tutor's provide Physics homework and conduct tests and assessments as well?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, based upon the chapters and topic level, periodic assessments are conducted and homework is given accordingly."
    }
  },{
    "@type": "Question",
    "name": "Does Ziyyara have a physics maths tutor?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara provides personalized one-on-one online tuition. Ziyyara has a pool of physics maths tutors who have expertise in teaching physics and maths tuition together without changing the physics maths tutor."
    }
  },{
    "@type": "Question",
    "name": "Is home tuition physics, same as physics tuition online?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara is a one-stop solution that provides physics tuition online at the comfort of your home. You don't have to get out of your home to get a feel of home tuition physics."
    }
  },{
    "@type": "Question",
    "name": "Is it similar to home tuition for Physics ?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "ou call it home tuition for Physics or home tuition physics, it's the same thing. Enjoy your live classes like home tuition for Physics."
    }
  }]
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }


  ngOnInit() {
    //slider
    this.slides = this.chunk(this.cards, 3);
  this.titleService.setTitle( "  Best Online Tuition for Physics | Online Physics Tuition Classes");
 var desc="Ziyyara offers one-on-one live online tuition for physics. We offer online physics tuition classes with the help of experienced tutors. For a free demo call us on +91 9654271931.."
  this.meta.updateTag({ name: 'description', content: desc })
  var keywords="home tuition for physics, home tuition physics, online physics tuition, online physics tutor, online physics tutors, online tuition for physics, physics home tuition, physics home tuitions, physics maths tutor, physics online tutor, physics tuition, physics tuition online, physics tutor, physics tutor near me";
  this.meta.updateTag({ name: 'keywords', content: keywords });
  var url=  location.pathname;

  var ogtitle="Best Online Tuition for Physics | Online Physics Tuition Classes";
  this.meta.updateTag({ property: 'og:title', content: ogtitle });
 var ogdesc="Ziyyara offers one-on-one live online tuition for physics. We offer online physics tuition classes with the help of experienced tutors. For a free demo call us on +91 9654271931.";
 this.meta.updateTag({ property:'og:description', content: ogdesc });

 var twittertitle=" Best Online Tuition for Physics | Online Physics Tuition Classes";
 this.meta.updateTag({ name:"twitter:title", content: twittertitle });
var twitterdesc="Ziyyara offers one-on-one live online tuition for physics. We offer online physics tuition classes with the help of experienced tutors. For a free demo call us on +91 9654271931.";
this.meta.updateTag({ name:'twitter:description', content: twitterdesc }); 
  this.meta_service.createCanonicalURL(url);
  this.meta_service.removeStructuredData();
  this.meta_service.insertSchema(this.websiteSchema);
 
   


 
 }




  cards = [
    {
      title: 'Economics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Economics.jpg'
    },
    {
      title: 'Maths',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Mathematics.jpg'
    },
    {
      title: 'English',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/English.jpg'
    },
    {
      title: 'Physics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Physics.jpg'
    },
    {
      title: 'History',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/History.jpg'
    },
    {
      title: 'Hindi',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Hindi.jpg'
    },
    {
      title: 'Geography',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Geography.jpg'
    },
    {
      title: 'Business Studies',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Business-Studies.jpg'
    },
    {
      title: 'Biology',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Biology.jpg'
    },
    {
      title: 'Political-Science',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Political-Science.jpg'
    },
    {
      title: 'Accountancy',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Accountancy.jpg'
    },
    {
      title: 'Chemistry',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Chemistry.jpg'
    },

    {
      title: 'Computer Science',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Computer-Science.jpg'
    },
    {
      title: 'Vedic Mathematics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/vedic-math.webp'
    },
  ];
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }


  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase()
    if(subject.includes("vedic"))
    
    {
      return "/vedic-math-workshop"
    
    } 
      else if (subject.includes("computer")){
        return '/home-tuition/online-home-tuition-for-computer-science'
      }
      else if (subject.includes("business")){
        return '/home-tuition/online-home-tuition-for-business-studies'
      }
    else {

      return '/home-tuition/online-home-tuition-for-'+subject
    }
  
  }
}
