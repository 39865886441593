<div class="modal-wrapper">
  <div class="header-popup d-flex align-items-center justify-content-center">
    Enter Verification Code
    <!-- <a (click)="no()" class="cross-modal"><i class="fa fa-times"></i></a> -->
  </div>

  <div class="modal-wrap">
    <div class="modal-content-text">
      <p class="forgot-text">{{data?.content?.title}}</p>
    </div>
    <form [formGroup]="otpForm" novalidate>
      <div class="email-field-box">
        <p *ngIf="errorMessage" style="color:red">{{errorMessage}}</p>

        <div class="mat-app-background basic-container">
          <mat-form-field appearance="fill" class="mat-form-custom text-center text-fiels-popup">
            <input matInput type="text" [formControl]="otp" [maxLength]="CONFIG.MAXOTP_LENGTH" [minLength]="CONFIG.MINOTP_LENGTH"
              appOnlyNumber>


          </mat-form-field>
          <mat-error>{{_util.errorMessage(otp,FORM_ERROR?.otp)}}</mat-error>
          <div class="">
            <div class="float-left" *ngIf="data?.content?.message1===1">
              <span *ngIf="timer">{{ timer | async}}</span>
            </div>
            <div class="float-right" *ngIf="data?.content?.message1===1">
              <div *ngIf="!timer">
                <a class="hrefclass " (click)="resendOtp(data?.content?.ok)">RESEND</a>
              </div>
              <div *ngIf="timer">
                <p class="disabledclass">RESEND</p>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>

      <div class="modal-btn-center ">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <div class="forgot-btn">
                <div class="d-md-flex justify-content-between align-items-center mb-3">
                    <button type="button" (click)="submit(data?.content?.ok)" class="btn m-0 btn-ziyyara">Verify</button>
                   
                  </div>
              <!-- <app-loader-button [title]="Verify" (submit)="submit(data?.content?.ok)" [loader]="loaderButton"></app-loader-button>
           -->
            </div>
          </div>
 

        </div>

      </div>
    </form>
    <div class="reola-color back-modal-text">
      <div class="row">
        <div class="col-md-12" >

          <a routerLink="/login" class="hrefclass befault-color">Back To Sign In</a>
        </div>

      </div>

    </div>
  </div>


</div>