import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState} from '../interfaces';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  constructor(private store: Store<AppState>) {

  }

  get appState(): Observable<AppState> {
    return this.store.pipe(select('applicationState'));
  }

}
