import { Component, OnInit } from '@angular/core';
import {Meta,Title} from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-math',
  templateUrl: './math.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class MathComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Will a parent be involved in their kid’s mathematics tuition?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Of course! It is imperative that parents are always connected to their child’s learning process through online tuition for maths. Our physics and maths tutor will provide you with a look into your child’s improvement, and how he or she is growing within our education plan."
    }
  },{
    "@type": "Question",
    "name": "Which is the best maths tuition near me?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our home tuition for maths does not have a rating system. All our tutors are picked carefully on the basis of their knowledge, who have several years of experience in teaching students. Every home tutor for maths is accredited to teach Math to children of different age groups for the best learning upshots."
    }
  },{
    "@type": "Question",
    "name": "What is the difference between math and Vedic Maths?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Vedic math has increased in demand in recent times. It is a set of methods/sutras to do mathematical problems quickly and easily. You will get to learn more about it in our home tuition for mathematics."
    }
  },{
    "@type": "Question",
    "name": "Is the online payment needed immediately?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We believe in building a relationship first. We don't ask for payment immediately. First, we will give you a demo class and after you are content, you can recharge your wallet based on your preference because we have a “pay as you go” option for the payment of mathematics home tuition."
    }
  }]
}



  constructor(private meta:Meta , private meta_service: MetaService, private titleService: Title) { }

  ngOnInit() {
    //slider
 this.slides = this.chunk(this.cards, 3);
  this.titleService.setTitle(" Best Online Tuition for Maths | Maths Tuition Classes Online");
  this.meta.updateTag({name:'description', content:" Maths is known to send a chill down the spine of many students, not anymore. Take Ziyyara’s online tuition for maths and get the concept clarity from maths tuition classes online."})
  var keywords="maths tuition, online tuition for maths, maths tuition near me, maths and physics tutor, maths home tutor, home tutor for maths, physics and maths tutor, maths and physics tutor, online maths tutor, maths home tutors , online tuition for Mathematics, home tuition for maths, maths home tuition, home tuition for maths, maths tuition near me, maths tuition";
 this.meta.updateTag({ name: 'keywords', content: keywords });
  var url=  location.pathname;

  var ogtitle="Best Online Tuition for Maths | Maths Tuition Classes Online";
  this.meta.updateTag({ property: 'og:title', content: ogtitle });
 var ogdesc=" Maths is known to send a chill down the spine of many students, not anymore. Take Ziyyara’s online tuition for maths and get the concept clarity from maths tuition classes online.";
 this.meta.updateTag({ property:'og:description', content: ogdesc });

 var twittertitle="Best Online Tuition for Maths | Maths Tuition Classes Online";
 this.meta.updateTag({ name:"twitter:title", content: twittertitle });
var twitterdesc=" Maths is known to send a chill down the spine of many students, not anymore. Take Ziyyara’s online tuition for maths and get the concept clarity from maths tuition classes online.";
this.meta.updateTag({ name:'twitter:description', content: twitterdesc }); 
  this.meta_service.createCanonicalURL(url);
  this.meta_service.removeStructuredData();
  this.meta_service.insertSchema(this.websiteSchema);

}

  cards = [
    {
      title: 'Economics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Economics.jpg'
    },
    // {
    //   title: 'Maths',
    //  // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    //   buttonText: 'Read',
    //   img: '/assets/images/Mathematics.jpg'
    // },
    {
      title: 'English',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/English.jpg'
    },
    {
      title: 'Physics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Physics.jpg'
    },
    {
      title: 'History',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/History.jpg'
    },
    {
      title: 'Hindi',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Hindi.jpg'
    },
    {
      title: 'Geography',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Geography.jpg'
    },
    {
      title: 'Business Studies',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Business-Studies.jpg'
    },
    {
      title: 'Biology',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Biology.jpg'
    },
    {
      title: 'Political-Science',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Political-Science.jpg'
    },
    {
      title: 'Accountancy',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Accountancy.jpg'
    },
    {
      title: 'Chemistry',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Chemistry.jpg'
    },

    {
      title: 'Computer Science',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Computer-Science.jpg'
    },
    {
      title: 'Vedic Mathematics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/vedic-math.webp'
    },
  ];

  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase()
    if(subject.includes("vedic"))
    
    {
      return "/vedic-math-workshop"
    
    } 
      else if (subject.includes("computer")){
        return '/home-tuition/online-home-tuition-for-computer-science'
      }
      else if (subject.includes("business")){
        return '/home-tuition/online-home-tuition-for-business-studies'
      }
    else {

      return '/home-tuition/online-home-tuition-for-'+subject
    }
  
  }
}
