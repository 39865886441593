import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialogConfig } from '@angular/material';
import { PopupMessage } from '../../../../interfaces';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonUtil } from '../../../../util';
import { AppStateService } from '../../../../services/app-state.service';
import { AppService, HttpService } from '../../../../services';
import { Store, select } from '@ngrx/store';
import * as AppActions from '../../../../store/actions/app.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { ERROR_MESSAGES, Regex, CONFIG, countries } from '../../../../constants';
import { Observable, Subscription } from 'rxjs';
import * as SignupActions from '../../../../store/actions/signup.actions';
import { IPROPERTY, AppState, ApiResponse } from '../../../../interfaces';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  constructor(
    //public _matDialogRef: MatDialogRef<ForgotComponent>,
    public dialogRef: MatDialogRef<SuccessComponent>,
    public _util: CommonUtil,
    private appState: AppStateService,
    @Inject(MAT_DIALOG_DATA) public data: PopupMessage,
    private _fb: FormBuilder,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private appSer: AppService,
    private _api: HttpService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
  }
  no() {
    this.dialogRef.close(false);
  }

} 
 