
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { HttpClientModule } from "@angular/common/http";
import { CommonUtil } from "./util";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginComponent } from './learning/shared/components/login/login.component';
import { SignupComponent } from './learning/shared/components/signup/signup.component';
import { ForgotComponent } from './learning/shared/components/forgot/forgot.component';
import { ResetComponent } from './learning/shared/components/reset/reset.component';
import { StoreModule } from "@ngrx/store";
import {SharedModule} from "./learning/shared/shared.module";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import {
  MatSnackBarModule,
  MatDialog,
  MatDialogModule, MatFormFieldModule, MatInputModule, MatTableModule, MatPaginatorModule, MatSortModule, MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatGridListModule, MatCardModule, MatMenuModule
} from "@angular/material";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { AngularMaterialModule } from './angular-material/angular-material.module';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider
} from "angular-6-social-login";
import { LayoutModule } from '@angular/cdk/layout';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER } from 'ngx-ui-loader';
import { appReducer } from "./store/reducers/app.reducer";
import { HttpService, AppService} from "./services";
import { LogoutModalComponent } from "./components/logout-modal/logout-modal.component";
import { MetaService } from "./services/meta.service";
import { ModalModule } from 'ngx-bootstrap/modal';
import { GroupStudyModule } from './learning/group-study/group-study.module';
import { GroupClassModalComponent } from './components/group-class-modal/group-class-modal/group-class-modal.component';
import { OnlineTuitionAnytimeModule } from './learning/Online-Tuition-Anytime/OnlineTuitionAnytime.module';
import { GetOnlineTuitionModule } from './learning/Get-Online-Tuition/GetOnlineTuition.module';
import { WhyStudentsModule } from './learning/WhyStudents/WhyStudents.module';
import { OnlineHomeTuitionsForAllSubjectsModule } from './learning/Online Home-Tuitions-for-All-Subjects/OnlineHomeTuitionsforAllSubjects.module';
import { BestOnlineHomeTuitionModule } from './learning/Best-Online-Home-Tuitions/BestOnlineHomeTuitions.module';
import { HomeTuitionModule } from './learning/home-tuition/home-tuition.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RegisterModule } from './learning/register/register.module';
import { LogInNewModule } from './learning/register/login-new.module';
import { TeacherRegisterModule } from './learning/register/teacher-register.module';
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider("jjkkkkk")
    },
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(
        "562329509737-743uf7384er5199ud3c46j6h34a7du87.apps.googleusercontent.com"
      )
    }
  ]);
  return config;
} 
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // bgsType: SPINNER.threeStrings,
  fgsType: SPINNER.threeStrings
}

@NgModule({
  declarations: [
    AppComponent,
    LogoutModalComponent,
    LoginComponent,
    SignupComponent,
    ResetComponent,

    ForgotComponent,
    GroupClassModalComponent
   ],

  imports: [
    // NgxUiLoaderModule
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SocialLoginModule,
    
    AppRoutingModule,
    SharedModule,
    GroupStudyModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    WhyStudentsModule,
GetOnlineTuitionModule,
OnlineTuitionAnytimeModule,
BestOnlineHomeTuitionModule,
NgxMatSelectSearchModule,
HomeTuitionModule,
OnlineHomeTuitionsForAllSubjectsModule,
    ModalModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    MatDialogModule,
    MatFormFieldModule, MatInputModule,
    AngularMaterialModule,
    RegisterModule,
    MDBBootstrapModule.forRoot(),
    StoreModule.forRoot({
      applicationState: appReducer
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    }),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    LayoutModule,
    TeacherRegisterModule,
    LogInNewModule
    
  ],
  providers: [
    MetaService,
    CommonUtil,
    HttpService,
    AppService,
    
   // UploaderService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    }

  ],
  bootstrap:[AppComponent],
  entryComponents: [LogoutModalComponent, LoginComponent, SignupComponent, GroupClassModalComponent,ResetComponent, ForgotComponent]
})
export class AppModule { }
// required for AOT compilation
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
 
// }

