import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-kolkata',
  templateUrl: './kolkata.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class KolkataComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What all things do I need to attend an online class offered by a Kolkata home tutor?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You require a Laptop, Tablet or Mobile phone to attend a class. We suggest our students take classes via laptop for enhanced learning experience. Also, you must have a good internet connection while taking classes even on the mobile data. We suggest WiFi rather than mobile data for enhanced connectivity."
    }
  },{
    "@type": "Question",
    "name": "What is the usual Batch Strength?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Every batch has a maximum student strength of 1 only. So there’s 1 teacher for every 1 child, ensuring personalized attention is our top most priority. We offer online one to one classes by arranging the best online tutor for Kolkata students."
    }
  },{
    "@type": "Question",
    "name": "How many free demo classes can I take?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara offers one Free Trial Class for different subjects, once you have signed up, you can take one free trial class from an online tuition tutor in Kolkata which helps you know more about our teaching style."
    }
  },{
    "@type": "Question",
    "name": "I am a parent and looking for a tutor offering tutoring in Kolkata, how can I get in touch with you?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The entire process is quite simple. You can contact us in either of two ways: 1. You can get in touch with us by referring to our official website and do the registration process through the Student sign up link. Post signing up, you can get a chance to book a free trial class. While searching for the home tuition in Kolkata classes, you can apply the filters and easily narrow down your search to select the best timings and classes. 2. You can directly call on the number and get in touch with the customer support team. He/she will offer immediate assistance after asking basic details about you and your requirement. Both option 1 and option 2 related to Kolkata home tutoring are technically the same, so you can choose any of the ways."
    }
  }]
}

cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}

  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {

   this.titleService.setTitle("  Online Home Tuition in Kolkata | Tutors in Kolkata | Tuition in Kolkata");
   var desc="Online home tuition in Kolkata is the need of an hour and at Ziyyara our best online tuition tutors in Kolkata imparts the learning via one-on-one live online classes. "
    this.meta.updateTag({ name: 'description', content: desc })
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema)
    this.meta.updateTag({name: 'keywords', content:"tutors in kolkata, home tutor in kolkata, tutor in kolkata, home tuition in kolkata, online tuition in kolkata, home tuition in kolkata, home tuition for kolkata, kolkata home tuition, Online tuition in kolkata, tuition online in kolkata, kolkata home tuition, kolkata online tuition, online home tuition in kolkata, home online tuition in kolkata, online home tuition for kolkata"})
    this.slides = this.chunk(this.cards, 3);

   
   }
 

}
