<div class="modal-wrapper">
    <div class="header-popup d-flex align-items-center justify-content-center">
        Forgot Password
        <!-- <a (click)="no()" class="cross-modal"><i class="fa fa-times"></i></a> -->
    </div>
    <div class="modal-wrap">
        <div class="modal-content-text">
            <p class="forgot-text">Please enter the Registered Email to receive a verification code to reset
                your password</p>
        </div>
        <form [formGroup]="forgotForm" (ngSubmit)="submit()" novalidate>

            <div class="email-field-box">
                <div class="error" style="color:red;">{{errorMessage}}</div>
                <div class="mat-app-background basic-container">
                    <mat-form-field appearance="fill" class="mat-form-custom text-center text-fiels-popup">
                        <input matInput type="text" placeholder="Email" [formControl]="email"
                            [maxLength]="CONFIG.EMAIL_LENGTH">

                    </mat-form-field>
                    <mat-error>{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>

                </div>
            </div>

            <div class="modal-btn-center ">
                <div class="row">
                    <div class="col-md-6 offset-md-3 ">
                        <div class="forgot-btn">
                            <button class="btn-ziyyara btn" title="Next" [disabled]="loader">Next</button>
                        </div>
                    </div>

                </div>

            </div>
        </form>

        <div class="reola-color back-modal-text">
            <div class="row">
                <div class="col-md-12">

                    <a routerLink="/login" class="hrefclass">Back To Sign In</a>

                </div>

            </div>

        </div>
    </div>


</div>