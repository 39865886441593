import * as AppActions from '../actions/app.actions';

import { AppState, IPROPERTY } from '../../interfaces';

export type Action = AppActions.All;


//
const defaultState: AppState = {
    loader: false,
    property: undefined,
    forgotToken: '',
    cards: [],
    userType: 0
};


// Creating new state from the previous state
const newState = (state, newData) => {
    return Object.assign({}, state, newData);
};


export function appReducer(state: AppState, action: Action) {


    switch (action.type) {

        case AppActions.APP_LOADER_TRUE:
            return newState(state, { loader: true });

        case AppActions.APP_LOADER_FALSE:
            return newState(state, { loader: false });

        case AppActions.APP_PROPERTY_SIGNUP:
            return newState(state, { property: action.payload, userType: 0 });

        case AppActions.APP_PROPERTY_LOGOUT:
            return newState(state, {
                loader: false,
                property: undefined,
                forgotToken: '',
                cards: []
            });

        case AppActions.APP_FORGOT_TOKEN:
            return newState(state, { forgotToken: action.payload });

      



        default:
            return state;

    }
}

