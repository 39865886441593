import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-cbse',
  templateUrl: './cbse.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class CbseComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Explain CBSE board full form?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Central Board of Secondary Education, which is identified as CBSE Board, is a centralized board in India for all the CBSE schools in India that comes under its affiliation. Approx. 20,000 schools located in the country and many schools abroad have received the affiliation from CBSE."
    }
  },{
    "@type": "Question",
    "name": "Which book does Ziyyara tutors refer to during CBSE home tuition classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We recommend all the schoolchildren to thoroughly revise the NCERT books as major part of questions are prepared considering these books. Also, our tutors offering online CBSE home classes refer to the last 10 years of question papers so that pupils can get assistance in getting good scores."
    }
  },{
    "@type": "Question",
    "name": "Am I permitted to use calculators while giving the tests?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "No, they are not allowed while writing the exams. We at Ziyyara don’t allow our students to use these gadgets during online home tuition of CBSE."
    }
  },{
    "@type": "Question",
    "name": "What is the procedure to enroll at Ziyyara?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Initially you need to register at Ziyyara to book a free demo for online CBSE home tuition."
    }
  },{
    "@type": "Question",
    "name": "Can I get at least a score post taking online tuition classes of CBSE at Ziyyara?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Students must get the lowest 1/3 marks (in theory & practical part as well) in five subjects so that they can get encouraged to the next level. In case you work harder and stay attended during all online CBSE tuition classes offered by us, you can get much more than just the passing score. How much time and efforts students put in, helps decide your score."
    }
  }]
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }
  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle(" Online Tuition For CBSE Board | CBSE Tuition Online");
   var desc="  We offer online tuition for CBSE classes to all the schoolchildren of class 1-12. By opting Ziyyara’s CBSE tuition online, students enjoy flexibility to study from the comfort of home.    "
    this.meta.updateTag({ name: 'description', content: desc })
    var url=  location.pathname;
    var ogtitle="Online Tuition For CBSE Board | CBSE Tuition Online";
    this.meta.updateTag({ property: 'og:title', content: ogtitle });
   var ogdesc="   We offer online tuition for CBSE classes to all the schoolchildren of class 1-12. By opting Ziyyara’s CBSE tuition online, students enjoy flexibility to study from the comfort of home.    ";
   this.meta.updateTag({ property:'og:description', content: ogdesc });
 
   var twittertitle="Online Tuition For CBSE Board | CBSE Tuition Online";
   this.meta.updateTag({ name:"twitter:title", content: twittertitle });
  var twitterdesc="   We offer online tuition for CBSE classes to all the schoolchildren of class 1-12. By opting Ziyyara’s CBSE tuition online, students enjoy flexibility to study from the comfort of home. ";
  this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
    this.meta.updateTag({name: 'keywords', content:"CBSE board, cbse board syllabus, cbse tutor, cbse online tuition, cbse tuition classes, cbse home tuition, Cbse Home tutors, Tuition For Cbse Students, Online Tuition For CBSE Students, Cbse Tuition center near me, private tutor for CBSE, Online tuition for CBSE    "})
  }
  
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
  cards = [
    {
      title: 'IB Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-ib-board.webp'
    },
    {
      title: 'ICSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-icse-board.webp'
    },
    {
      title: 'CBSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cbse-board.webp'
    },
    {
      title: 'ISC Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-isc-board.webp'
    },
    {
      title: 'IGCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-igcse-board.webp'
    },
   
    {
      title: 'CISCE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cisce-board.webp'
    },
    {
      title: 'GCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-gcse-board.webp'
    },
    {
      title: 'Cambridge International-Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cambridge-international-board.webp'
    },
  ];
  generateSubjectLink(sub:string){
    var subject=sub.toString().toLowerCase().replace(" ","-");
    return '/home-tuition/online-home-tuition-for-'+subject;
  }
}
