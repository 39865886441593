import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-gurugram',
  templateUrl: './gurugram.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class GurugramComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How does your online tutor for Gurugram complete the syllabus & doubts?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara’s Gurugram online home tutor covers the entire syllabus matching the pace of the students. Also doubt classes are conducted in between the online Gurugram home tuition so that no doubts or problems are carried forward for the next class. We arrange regular doubt solving sessions."
    }
  },{
    "@type": "Question",
    "name": "How should I inform you about my child’s satisfaction related to a teaching style of a particular Gurugram tutor for online tuition classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara takes a survey after all the classes and looks for ways to improve further. We welcome all the feedback and suggestions shared by the parents and students."
    }
  },{
    "@type": "Question",
    "name": "How concepts are taught by your online tuition tutor in Gurugram?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our tutors break down difficult and challenging concepts into simpler ones so that students can easily understand them. All the classes are designed in a well-planned way so that students can know in advance what will be taught to them in the next class. We accordingly plan revision sessions as well."
    }
  },{
    "@type": "Question",
    "name": "Can I download the classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "No, the online Gurugram home tuition classes will not be downloaded. But you can ask for the recording session. At Ziyyara, all the recordings will be made available until the end of the curriculum year. Parents are supposed to pre-inform our coordinating team that they want recordings after every class is ended so that it can be made available to them."
    }
  },{
    "@type": "Question",
    "name": "Am I allowed to pay fees in instalments?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, you can pay the fees in 2 or 3 instalments. But we suggest you make a one-time payment as several discounts are associated with it."
    }
  }]
}

cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}

  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
   this.titleService.setTitle("  Online Home Tuition in Gurugram | Tutors in Gurugram | Tuition in Gurugram");
   var desc=" Ziyyara is a one stop solution for online tuition in Gurugram. Ziyyara's Gurugram home tutors are offering holistic online learning to the students for achieving good marks."
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ name: 'keywords', content:"home tuition in Gurugram, tuition in Gurugram, home tutor in Gurugram, tutors in Gurugram, home tutors in Gurugram, home tuition in Gurugram, home tuition for Gurugram, tuition in Gurugram, Gurugram home tuition, Online tuition in Gurugram, tuition online in Gurugram, Gurugram home tuition, Gurugram online tuition, online home tuition in Gurugram, home online tuition in Gurugram"})
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
    this.slides = this.chunk(this.cards, 3);
 
   
}




}