import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class HistoryComponent implements OnInit {
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What will be the time duration for history home tuition?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The time duration for our history tuition is generally for 1 hour. But if you want more attention and guidance to excel in history then you can take two consecutive online tuition classes. To give you more guidance, our history tutor will teach you more than standard hours."
    }
  },{
    "@type": "Question",
    "name": "What if I want History home tuition classes according to my availability? Is it possible?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, you can get classes according to your need. The timing of the online tuition for history can be personalized according to your preference. You can get classes that are scheduled as per your availability. If you want to study on holiday then you just have to inform our coordinating team and they will arrange according to your preference."
    }
  },{
    "@type": "Question",
    "name": "Are virtual classes from Ziyyara's private tutor for History effective?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, Ziyyara’s History tutor at home is working virtually to give you classes. We haven’t left any stone unturned to make the studies easy and fun for you."
    }
  }]
}
cards = [
  {
    title: 'Economics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Economics.jpg'
  },
  {
    title: 'Maths',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Mathematics.jpg'
  },
  {
    title: 'English',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/English.jpg'
  },
  {
    title: 'Physics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Physics.jpg'
  },
  // {
  //   title: 'History',
  //  // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
  //   buttonText: 'Read',
  //   img: '/assets/images/History.jpg'
  // },
  {
    title: 'Hindi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Hindi.jpg'
  },
  {
    title: 'Geography',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Geography.jpg'
  },
  {
    title: 'Business Studies',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Business-Studies.jpg'
  },
  {
    title: 'Biology',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Biology.jpg'
  },
  {
    title: 'Political-Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Political-Science.jpg'
  },
  {
    title: 'Accountancy',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Accountancy.jpg'
  },
  {
    title: 'Chemistry',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Chemistry.jpg'
  },

  {
    title: 'Computer Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Computer-Science.jpg'
  },
  {
    title: 'Vedic Mathematics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/vedic-math.webp'
  },
];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
ngOnInit() {
  //slider
this.slides = this.chunk(this.cards, 3);
this.titleService.setTitle("  - Online Tuition for History | History Online Tuition Classes");
var desc=" Looking for history online tuition classes? Try Ziyyara’s one-on-one live online tuition for history from experienced tutors. For a free demo call us on +91 9654271931. "
this.meta.updateTag({ name: 'description', content: desc });
var keywords="history tutor, the tutor of history, history teacher, history tuition, history classes, , History tutor, History home tutor, tutor for History, History home tutor, History tutor at home, History tution tutor, History private tutor, private tutor for History, , Online tuition for History, online tuition History, History online tuition, tuition for History";
this.meta.updateTag({ name: 'keywords', content: keywords });
var url=  location.pathname;

var ogtitle="Online Tuition for History | History Online Tuition Classes";
this.meta.updateTag({ property: 'og:title', content: ogtitle });
var ogdesc=" Looking for history online tuition classes? Try Ziyyara’s one-on-one live online tuition for history from experienced tutors. For a free demo call us on +91 9654271931. ";
this.meta.updateTag({ property:'og:description', content: ogdesc });

var twittertitle="Online Tuition for History | History Online Tuition Classes";
this.meta.updateTag({ name:"twitter:title", content: twittertitle });
var twitterdesc="   Looking for history online tuition classes? Try Ziyyara’s one-on-one live online tuition for history from experienced tutors. For a free demo call us on +91 9654271931. ";
this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
this.meta_service.createCanonicalURL(url);
this.meta_service.removeStructuredData();
this.meta_service.insertSchema(this.websiteSchema);

}

 generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  if(subject.includes("vedic"))
  
  {
    return "/vedic-math-workshop"
  
  } 
    else if (subject.includes("computer")){
      return '/home-tuition/online-home-tuition-for-computer-science'
    }
    else if (subject.includes("business")){
      return '/home-tuition/online-home-tuition-for-business-studies'
    }
  else {

    return '/home-tuition/online-home-tuition-for-'+subject
  }

}

}
