import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-english',
  templateUrl: './english.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class EnglishComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How long are the English lessons?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "For the English lessons, our online English tutor recommends 1 hour per day. But if you need more classes you can make a request and schedule two classes of 1 hour."
    }
  },{
    "@type": "Question",
    "name": "Do you have individual classes or group English classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We only offer one-on-one classes with the help of an English tutor online. Our English lessons are customized particularly for your requirements and goals. This enables you to grow as quickly as possible to get the best results."
    }
  },{
    "@type": "Question",
    "name": "What is your teaching style?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "One of Ziyyara's core values is uprightness. In other words, our online English tutor works in an upfront and honest manner. They point out areas of improvement instantly and give feedback. They sometimes also produce custom notes formed around students' weaknesses. As much as attainable, we try to make lessons enjoyable so that students learn the lessons."
    }
  },{
    "@type": "Question",
    "name": "What happens if my child misses a lesson?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Depending on availability, a replacement English online class for the lesson will be provided."
    }
  }]
}
cards = [
  {
    title: 'Economics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Economics.jpg'
  },
  {
    title: 'Maths',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Mathematics.jpg'
  },
  // {
  //   title: 'English',
  //  // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
  //   buttonText: 'Read',
  //   img: '/assets/images/English.jpg'
  // },
  {
    title: 'Physics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Physics.jpg'
  },
  {
    title: 'History',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/History.jpg'
  },
  {
    title: 'Hindi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Hindi.jpg'
  },
  {
    title: 'Geography',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Geography.jpg'
  },
  {
    title: 'Business Studies',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Business-Studies.jpg'
  },
  {
    title: 'Biology',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Biology.jpg'
  },
  {
    title: 'Political-Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Political-Science.jpg'
  },
  {
    title: 'Accountancy',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Accountancy.jpg'
  },
  {
    title: 'Chemistry',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Chemistry.jpg'
  },

  {
    title: 'Computer Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Computer-Science.jpg'
  },
  {
    title: 'Vedic Mathematics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/vedic-math.webp'
  },
];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    //slider
 this.slides = this.chunk(this.cards, 3);
  this.titleService.setTitle("Best Online Tuition for English | Online English Tuition Classes ");
 var desc="Get the best online tuition for English. Ziyyara provides online English tuition classes at the comfort of your home. For a free demo call us on +91 9654271931 "
  this.meta.updateTag({ name: 'description', content: desc })
  var keywords="online english tutor, english tutor, english tutor online, , english home tutor, home english, english tutor, online english tutor, english tutor near me, english tutor online, english home tutor, online english tutor india, english tuition classes, english tuition online, online english tuition, tuition for english, home tuition for english, english home tuition, online tuition for english";
  this.meta.updateTag({ name: 'keywords', content: keywords });
  var url=  location.pathname;

  var ogtitle="Best Online Tuition for English | Online English Tuition Classes";
  this.meta.updateTag({ property: 'og:title', content: ogtitle });
 var ogdesc="Get the best online tuition for English. Ziyyara provides online English tuition classes at the comfort of your home. For a free demo call us on +91 9654271931 ";
 this.meta.updateTag({ property:'og:description', content: ogdesc });

 var twittertitle="Best Online Tuition for English | Online English Tuition Classes";
 this.meta.updateTag({ name:"twitter:title", content: twittertitle });
var twitterdesc="Get the best online tuition for English. Ziyyara provides online English tuition classes at the comfort of your home. For a free demo call us on +91 9654271931 ";
this.meta.updateTag({ name:'twitter:description', content: twitterdesc });  
  this.meta_service.createCanonicalURL(url);
  this.meta_service.removeStructuredData();
  this.meta_service.insertSchema(this.websiteSchema);

}
 generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  if(subject.includes("vedic"))
  
  {
    return "/vedic-math-workshop"
  
  } 
    else if (subject.includes("computer")){
      return '/home-tuition/online-home-tuition-for-computer-science'
    }
    else if (subject.includes("business")){
      return '/home-tuition/online-home-tuition-for-business-studies'
    }
  else {

    return '/home-tuition/online-home-tuition-for-'+subject
  }

}
}


