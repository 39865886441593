<app-header></app-header>
<!-- <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScroll(activData)">
    -->
<div class="reola-breadcum">
    <div class="container">
        <div class="col-md-12">
            <ol class="breadcrumb pt-3">
                <li class="breadcrumb-item " routerLink="/dashboard" aria-current="page">Home</li>
                <li class="breadcrumb-item active" aria-current="page">Group Study</li>
            </ol>
        </div>
    </div>
</div>
<div class="container">
    <div class="property-header">
        <h1>Group Study</h1>

    </div>
</div>
<div class="container">
    
    <div class="row min-height">
        <div class="col-md-12">
            <div class="property-listing mb-4">
               
                <div class="listing-header">

                    <ul class="clearfix">
                      <li><a (click)="property('teacher')" [class]="active"> Group classes </a></li>
                      <!-- <li><a (click)="property('school')" [class]="activeA">Schools/Institutions  </a></li>
                      <li><a (click)="property('upcoming')" [class]="activeU">Upcoming Classes  </a></li> -->
            
                      
            
                    </ul>
                  </div>
                  
                   <div *ngIf="active.length ">

                    <div *ngIf="groupClass">
                    <mat-card class="example-card" *ngFor="let list of groupClass?.slice().reverse(); ">
                        <img [src]="(list?.teacher.dp)?list?.teacher.dp:'assets/images/about/user_dp.jpeg'" class="float-left rounded-circle dp">
                           <div class="message pd">
                               <mat-card-header>
                             <mat-card-title class="card-title"><span class="cap">{{list.group_name}}</span>
                                </mat-card-title>
                             <mat-card-subtitle class="card-subtitle mb-2 text-muted cize"><b>Teacher: </b><strong class="ziya"><a [routerLink]="['./view',{id :list.teacher?.id}]">{{(list?.teacher.name)?list?.teacher.name:'NA'}}</a></strong> 
                                &nbsp; &nbsp; &nbsp; <span><b>  Subject:</b> {{list.subject}} </span>
                                 <!-- &nbsp;<span><b>  Price: </b>{{(list?.price)?list?.price:'NA'}}</span> -->
                                
                               
                                 
                                
                                </mat-card-subtitle>
                                <mat-card-subtitle class="card-subtitle mb-2 text-muted sze"> <span><b>  Total Session:</b>&nbsp;  {{list.num_session}}</span>  &nbsp; &nbsp; &nbsp; <span><b>  Class Date:</b> {{getDate(list.class_date)}}</span></mat-card-subtitle>
                            
                                <br>
                              
                                 <mat-card-subtitle class="card-subtitle mb-2 text-muted sze"> <span>
                                     <b>  Class Url:</b> &nbsp; <a [href]="list.class_url"  class="grn"   title="click here to attend the class">{{list.class_url}}</a></span> </mat-card-subtitle>
                                  
                                     <mat-card-subtitle *ngIf="userType==2" class="card-subtitle mb-2 text-muted sze"> <b>  Students:</b>&nbsp;  <span *ngFor="let std of list.student_list; index as i"> {{i+1}} . {{std.name}}</span> </mat-card-subtitle>
                            
                            </mat-card-header>
                            </div> 
                         </mat-card>
</div>

<div *ngIf="!groupClass">You are not added in any group class yet.</div>
<div *ngIf="groupClass !=undefined && !groupClass.length">You are not added in any group class yet.</div>
</div>

                  <!-- <div *ngIf="activeA.length">
                                        
                                       
                                       
                                        <mat-card class="example-card" *ngFor="let list of groupClass?.slice().reverse(); ">
                                         <img src="assets/images/about/user_dp.jpeg" class="float-left rounded-circle dp">
                                            <div class="message pd">
                                                <mat-card-header>
                                              <mat-card-title class="card-title"><span class="cap">{{list.group_name}}</span>&nbsp; <span>Number of Session: {{list.num_session}}</span></mat-card-title>
                                              <mat-card-subtitle class="card-subtitle mb-2 text-muted cize">Teacher:<strong class="ziya">{{(list?.teacher.name)?list?.teacher.name:'NA'}}!</strong> &nbsp; <span>Subject: {{list.subject}} </span> &nbsp;<span>Price: {{(list?.price)?list?.price:'NA'}}</span></mat-card-subtitle>
                                              <mat-card-subtitle class="card-subtitle mb-2 text-muted sze">join this group to take group classes, Your one stop online tuition platform.</mat-card-subtitle>
                                              </mat-card-header>
                                             </div> 
                                          </mat-card>
                                         
                    
                  </div>

                   <div *ngIf="activeU.length">
                                                           Upcoming Group Classes
                                                          
                   </div> -->


                                                        
                                                        
                
            </div>
        </div>
    </div>
</div>

<div class="property-footer">
    <app-footer></app-footer>
</div>

