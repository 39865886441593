import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { CommonComponentModule } from '../common-component/common-component.module';

import { WhyStudentsComponent } from './why-students/why-students.component';


const pageRoutes: Routes = [

  { path: '', component: WhyStudentsComponent },

];

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    SharedModule,
    CommonComponentModule,
    RouterModule.forChild(pageRoutes),

  ],
  declarations: [WhyStudentsComponent],
  entryComponents: []
})
export class WhyStudentsModule { }


