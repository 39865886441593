import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-ahmedabad',
  templateUrl: './ahmedabad.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class AhmedabadComponent implements OnInit {
  websiteSchema ={
    "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is Online Tutoring?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Online tutoring means offering teaching over the internet and at Ziyyara, we believe in offering one to one online live classes for better understanding. With the growth in online tutoring, we have made our platform more user friendly and offer audio-video classes so that concepts can get cleared with the help of diagrams."
    }
  },{
    "@type": "Question",
    "name": "Is it safe to take Online Tutoring in Ahmedabad?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, taking online tuitions is completely safe. You don’t have to worry about anything, in fact, all doubts of your children will get solved in a more productive way. Online Ahmedabad home tuition serves as the best alternative option to other kinds of teaching and helps students in judging their performance. We at Ziyyara offer the best Ahmedabad online tuition based on the comfort of our students."
    }
  },{
    "@type": "Question",
    "name": "What are the charges of online tutoring?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The charges of online home tuition in Ahmedabad offered at Ziyyara is much lower as compared to the prices charged by coaching institutes or in-person tutors. You can get in touch with us now to know more about our affordable fee structure."
    }
  }]
 
}


cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 

  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

 ngOnInit() {
   this.titleService.setTitle( " Online Home Tuition in Ahmedabad | Home Tutor in Ahmedabad");
  var desc="Parents are looking for online home tuition in Ahmedabad these days, thus getting an online home tutor in Ahmedabad is now easy with Ziyyara. Try 1-hour free class today."
   this.meta.updateTag({ name: 'description', content: desc })

   this.meta.updateTag({ name: 'keywords', content:"home tuition in Ahmadabad, tuition in Ahmadabad, home tutor in Ahmadabad, home Tuition For Ahmadabad, home tuition in Ahmadabad, home tuition for Ahmadabad, tuition in Ahmadabad, Ahmadabad home tuition, Online tuition in Ahmadabad, tuition online in Ahmadabad, Ahmadabad home tuition, Ahmadabad online tuition, online home tuition in Ahmadabad, home online tuition in Ahmadabad, online home tuition for Ahmadabad"})
  
   var url=  location.pathname;
   this.meta_service.createCanonicalURL(url);
   this.meta_service.removeStructuredData();
   this.meta_service.insertSchema(this.websiteSchema)
   this.slides = this.chunk(this.cards, 3);

  }
  generateSubjectLink(sub:string){
    

    var subject=sub.toString().toLowerCase()
    
  
    if(subject.includes("andaman")){
      return '/home-tuition/online-home-tuition-in-andaman-nicobar'
     }
else if(subject.includes("arunanchal")){
      return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
     }

     else {return '/home-tuition/online-home-tuition-in-'+subject }
      
  }
}
