import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-biology',
  templateUrl: './biology.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class BiologyComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What are the benefits of taking Online biology home tuition?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "There are several benefits you get from Ziyyara’s online bio tutor. Here you can choose the right tutor according to your choice who will teach in the most effective manner possible. They will help you get good marks and improve the skill overall."
    }
  },{
    "@type": "Question",
    "name": "Can I request a bio tutor?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Sure, if you have prior assistance with any of our tutor, you can ask for that particular biology tutor. You just have to know about the information of the tutor you are looking for and we will assign the tutor for your help."
    }
  },{
    "@type": "Question",
    "name": "How can I trust Ziyyara’s bio tutor?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We render quality with our multiple factors such as profile completeness, tutors background, experience, track records, parent reviews, and more. Through this process, we can guarantee that parents & students are invariably paired with the best of the tutors."
    }
  },{
    "@type": "Question",
    "name": "How can biology home tuition help me?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "At Ziyyara, we have a team of dedicated online biology tutors to whom you can ask your problems whenever you want. It’s a two-way communication program. Our tutors can help you with studying, exam preparation, and homework help in Science. Ziyyara coaching sessions are the same as personalized tutoring programs."
    }
  },{
    "@type": "Question",
    "name": "Does online tutoring work?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes! Ziyyara's online home tuition for Biology is filled with trained tutors who help their students efficiently through personalized classes. Students can easily evaluate their lessons and parents can be updated about their child’s improvement."
    }
  }]
}

cards = [
  {
    title: 'Economics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Economics.jpg'
  },
  {
    title: 'Maths',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Mathematics.jpg'
  },
  {
    title: 'English',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/English.jpg'
  },
  {
    title: 'Physics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Physics.jpg'
  },
  {
    title: 'History',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/History.jpg'
  },
  {
    title: 'Hindi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Hindi.jpg'
  },
  {
    title: 'Geography',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Geography.jpg'
  },
  {
    title: 'Business Studies',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Business-Studies.jpg'
  },
  // {
  //   title: 'Biology',
  //  // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
  //   buttonText: 'Read',
  //   img: '/assets/images/Biology.jpg'
  // },
  {
    title: 'Political-Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Political-Science.jpg'
  },
  {
    title: 'Accountancy',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Accountancy.jpg'
  },
  {
    title: 'Chemistry',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Chemistry.jpg'
  },

  {
    title: 'Computer Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Computer-Science.jpg'
  },
  {
    title: 'Vedic Mathematics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/vedic-math.webp'
  },
];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }
  ngOnInit() {
    //slider
 this.slides = this.chunk(this.cards, 3);
  this.titleService.setTitle("Best Online Tuition for Biology | Biology Online Tuition Classes ");
 var desc=" Get the best online tuition for Biology. Ziyyara provides biology online tuition classes at the comfort of your home. For a free demo call us on +91 9654271931."
  this.meta.updateTag({ name: 'description', content: desc })
  var keywords="biology tuition, biology home tuition, biology tuition near me, biology home tuition, home tuition for Biology, online biology tutor, biology tutor wanted, biology home tutor, home tutor for biology, biology home tuition, online biology tutor, want biology tuition, Online Biology tuition, Online biology home tuition, Biology online tuition";
  this.meta.updateTag({ name: 'keywords', content: keywords });
  var url=  location.pathname;

  var ogtitle="Best Online Tuition for Biology | Biology Online Tuition Classes";
  this.meta.updateTag({ property: 'og:title', content: ogtitle });
 var ogdesc="Get the best online tuition for Biology. Ziyyara provides biology online tuition classes at the comfort of your home. For a free demo call us on +91 9654271931.";
 this.meta.updateTag({ property:'og:description', content: ogdesc });

 var twittertitle="Best Online Tuition for Biology | Biology Online Tuition Classes";
 this.meta.updateTag({ name:"twitter:title", content: twittertitle });
var twitterdesc="Get the best online tuition for Biology. Ziyyara provides biology online tuition classes at the comfort of your home. For a free demo call us on +91 9654271931.";
this.meta.updateTag({ name:'twitter:description', content: twitterdesc }); 
  this.meta_service.createCanonicalURL(url)
  this.meta_service.removeStructuredData();
  this.meta_service.insertSchema(this.websiteSchema);
}
 generateSubjectLink(sub:string){
  var subject=sub.toString().toLowerCase()
  if(subject.includes("vedic"))
  {
    return "/vedic-math-workshop"
  } 
    else if (subject.includes("computer")){
      return '/home-tuition/online-home-tuition-for-computer-science'
    }
    else if (subject.includes("business")){
      return '/home-tuition/online-home-tuition-for-business-studies'
    }
  else {
    return '/home-tuition/online-home-tuition-for-'+subject
  }
}
}
