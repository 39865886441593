import { Component, OnInit, Inject ,ViewChild} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from "@angular/material";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { Regex, ERROR_MESSAGES, CONFIG } from "../../../../constants";
import { CommonUtil } from "../../../../util";
import { HttpService, AppService } from "../../../../services";

import { LoginComponent } from '../login/login.component';
import { IPROPERTY, AppState, ApiResponse } from '../../../../interfaces';
import { SuccessComponent } from '../success/success.component';
import { OtpComponent } from "../otp/otp.component";
import { MatSelect } from "@angular/material/select";
import { ReplaySubject, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  signupForm: any;
  loaderButton;
  CONFIG = CONFIG;
  FORM_ERROR = {
    fullName: {
      required: ERROR_MESSAGES.FIRSTNAME_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.NAME_LENGTH}`,
      pattern: ERROR_MESSAGES.INVALID_CSPACES,
    },

    email: {
      required: ERROR_MESSAGES.EMAIL_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_EMAIL,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.EMAIL_LENGTH}`
    },
    contactNo: {
      required: ERROR_MESSAGES.PHONE_NUMBER_REQUIRED,
      pattern: ERROR_MESSAGES.INVALID_MOBILE_NUMBER,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.MOBILE_LENGTH}`,
      minlength: `${ERROR_MESSAGES.MIN_LENGTH}${this.CONFIG.MOBILE_MIN_LENGTH}`
    },
    countryCode: {
      required: ERROR_MESSAGES.COUNTRYCODE_REQUIRED_MAIN,
    },
    password: {
      required: ERROR_MESSAGES.PASSWORD_REQUIRED,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
      pattern: ERROR_MESSAGES.PASSWORD_REGEX,

    },
    confirmPassword: {
      required: ERROR_MESSAGES.CONFIRM_PASSWORD,
      maxlength: `${ERROR_MESSAGES.MAX_LENGTH}${this.CONFIG.PASSWORD_LENGTH}`,
      pattern: ERROR_MESSAGES.PASSWORD_REGEX, matchedPassword: ERROR_MESSAGES.PASSWORD_CONFIRMPASSWORD_MISMATCH
    },
    type: {
      required: ERROR_MESSAGES.USER_TYPES,
    },
    
  };

  disabled=false;
  selectCountryCode;
  bank = [];
  banks = [];
  countryCodes = [];

  noEntriesFoundLabel = "No Such Country";
  placeholderLabel = "Search";
     /** list of banks */

  /** control for the selected bank */
  // public countryCode: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredBanks = new ReplaySubject(1);

  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  cntry: any;
 

  constructor(public _matDialogRef: MatDialogRef<LoginComponent>,
    public dialogRef: MatDialogRef<LoginComponent>,
    private _appSer: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private _fb: FormBuilder, private _router: Router, private _api: HttpService,
    private _route: ActivatedRoute, public _util: CommonUtil) { }

  ngOnInit() {
    this.countryCodes=this._appSer.returnCountryList();
    this.createForm();
    this.bank = this.countryCodes;
    this.banks = this.bank;
      // load the initial bank list
      this.filteredBanks.next(this.banks.slice());
      // console.log(this.filteredBanks);
  
      // listen for search field value changes
      this.bankFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterBanks();
        });
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {
      right: `0px`,
      top: `0px`
    };
 
    this._matDialogRef.updatePosition(matDialogConfig.position);


    let stateObj = { id: "100" }; 
            window.history.replaceState(stateObj, 
                        "index", "/register");
    


       this.countryCodes=this._appSer.returnCountryList();

  }

  no() {
    this.dialogRef.close(false);
  }

  login(): void {
    this.dialogRef.close(false);
    const dialogRef = this.dialog.open(LoginComponent, {
      width: "425px",
      height: "auto"
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  createForm() {
    this.signupForm = this._fb.group({
      fullName: ['', [Validators.required, Validators.maxLength(200), Validators.pattern(Regex.spacecharacter)]],
      email: ['', [Validators.required, Validators.pattern(Regex.email)]],
      countryCode: ['', [Validators.required]],
      country: ['', [Validators.required]],
      contactNo: ['', [Validators.required, Validators.minLength(CONFIG.MOBILE_MIN_LENGTH), Validators.pattern(Regex.phoneNumbers)]],
      password: ['', [Validators.required, Validators.pattern(Regex.password), Validators.pattern(Regex.spaces)]],
      confirmPassword: ['', [Validators.required, Validators.maxLength(25), Validators.pattern(Regex.password), Validators.pattern(Regex.spaces)]],
      type: ['', [Validators.required]]
    },
      { validator: this.checkPasswords });
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    if (group.controls.confirmPassword.value !== "") {
      let pass = group.controls.password.value;
      let confirmPass = group.controls.confirmPassword.value;

      return pass === confirmPass ? null : { notSame: true }
    }
  }
  finalCountryCode;

  submit() {
  
    


    
    this.finalCountryCode=this.signupForm.get("countryCode").value
    if (this.signupForm.get("countryCode").value) {
      //here we separte country name  from the countryCode object
      this.signupForm
        .get("country")
        .patchValue(this.signupForm.get("countryCode").value.name);

    }

  
    if (this.signupForm.valid) {

      if (this.signupForm.get("countryCode").value) {
        //here we separate countryCode   from the countryCode object
        this.signupForm
          .get("countryCode")
          .patchValue(this.signupForm.get("countryCode").value.code);
      
      }

   
      this.disabled = true;
     
      this.signupForm.value.origin_page = "https://ziyyara.in"+location.pathname;
      this.signupForm.value.name = this.signupForm.value.fullName.toString().trim();
        this.signupForm.value.u_type = this.signupForm.value.type;

          // edit by nazimahmed
          this.cntry = this.signupForm.value.country.replace(/[^a-z]/gi, ' ');
          // console.log(this.cntry);
          this.signupForm.value.country=this.cntry.trim();
//alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.signupForm.value, null, 4));
      this._api.postReqUnauth("users", this.signupForm.value).subscribe(
        (res) => this.success(res),
        (err) => this.error(err.message),
        () => ((this.loaderButton = false), (this.disabled = false))
      );
    } else {
      this._util.markError(this.signupForm);
    }
  }
  get fullName(): FormControl {
    return this.signupForm.get("fullName") as FormControl;
  }
  get email(): FormControl {
    return this.signupForm.get("email") as FormControl;
  }
  get password(): FormControl {
    return this.signupForm.get("password") as FormControl;
  }
  get confirmPassword(): FormControl {
    return this.signupForm.get("confirmPassword") as FormControl;
  }
  get type(): FormControl {
    return this.signupForm.get("type") as FormControl;
  }

  get contactNo(): FormControl {
    return this.signupForm.get("contactNo") as FormControl;
  }
  
  get countryCode(): FormControl {
    return this.signupForm.get("countryCode") as FormControl;
  }

  get country(): FormControl {
    return this.signupForm.get("country") as FormControl;
  }
  success(res: ApiResponse) {

    ////////console.log(res)
    const message = res.message;
    this.dialogRef.close(true);
    if (res.status == true) {
      //this.login();
      this._api
      .postReqUnauth("users_send_verification_code", {
        email: this.signupForm.value.email,
        
      })
      .subscribe(
        res => this.successsResend(res),
        err => this.error1(err.message),
      
      );
      this.popupmessage(res.result);
   


    }
    else{
      this._appSer.addSnackBar({ message });
    }
  

   

  //  const value = "Sign Up";
  //  const content =message;
  //  const dialogRef = this.dialog.open(SuccessComponent, {
   
  //    width: "620px",
  //    height: "auto",
  //    data: {
  //      content: { title: 'Please Login to continue', heading: content },
      
  //      action: "active"
  //    }
  //  });

  //  setTimeout(() => {
  //     dialogRef.close(false);
     
  //  }, 5000);

  
   
  }


  successsResend(res){
    // console.log(res)
    this.signupForm.reset();
  }
  popupmessage(data) {

    const value = "Sign Up";
    const content =
        "Please enter the verification code which is sent to your email.";
    const dialogRef = this.dialog.open(OtpComponent, {
        // maxWidth: "500px",
        width: "500px",
        height: "auto",
        data: {
            content: { title: content, heading: value, message1: 1, ok:data.email },
            action: "active"
        },
        disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
        this.dialogRef.close(false);
    });

}
  error(message) {
    this._appSer.addSnackBar({ message });
  }

  error1(message) {
    this._appSer.addSnackBar({ message });
  }
  

  ngAfterViewInit() {
    
    var firstElem= document.getElementsByClassName("mat-form-field-appearance-fill");
  
    var secondElem=document.getElementsByClassName("mat-form-field-flex");
 
    var thirdElem= document.getElementsByClassName("mat-form-field-infix");
    
    
     // .mat-form-field-appearance-fill .mat-form-field-infix
    
     for (var i = 0; i < firstElem.length; i++ ) {
       firstElem[i].classList.add("custom-flex");
     }
     for (var i = 0; i < secondElem.length; i++ ) {
       secondElem[i].classList.add("custom-flex");
     }
 
 
     for (var i = 0; i < firstElem.length; i++ ) {
       thirdElem[i].classList.add("custom-infix");
     }
 
 
 
   }





   onChangeCode(event){

    var temp=[]=  this.countryCodes.filter(ele => ele.code==event.value.code);

      if(temp)
      this.signupForm.get('country').patchValue(temp[0].name)
      this.signupForm.get('countryCode').patchValue(temp[0].code)
    }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a, b) => a && b && a.name === b.name;
      });
  }

  protected filterBanks() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks,
    this.filteredBanks.next(
      this.banks.filter((bank) => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }


}
