     

import { Injectable ,Inject} from '@angular/core';

// Rxjs Operators
import { Subject } from 'rxjs';
import { SnackMessage, AppState } from '../interfaces';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as AppActions from '../store/actions/app.actions';

import { DOCUMENT } from '@angular/common';
@Injectable()
export class AppService {
  private _snackMessage: Subject<SnackMessage> = new Subject<SnackMessage>();
  private loader: Subject<boolean> = new Subject();
  blocked: Subject<boolean> = new Subject();
  load = this.loader.asObservable();
  constructor(  private router: Router, private store: Store<AppState>,@Inject(DOCUMENT) private document: any) {
   // translate.setDefaultLang('en');
  }




  get message() {
    return this._snackMessage.asObservable();
  }

  addSnackBar(message: SnackMessage) {
    if (!message.action) {
      message.action = 'Close';
    }
    this._snackMessage.next(message);
  }


  showLoader() {
   console.log('show loader called')
    this.loader.next(true);
  }

  hideLoader() {
    this.loader.next(false);
  }

  redirectToPackage() {
    this.router.navigate(['/profile']);
  }

  logout() {
     localStorage.removeItem("ziyyara_token");
     localStorage.removeItem("token1");
    this.store.dispatch(new AppActions.PropertyLogout());
    
   //this.router.navigate(['/']);
   this.router.navigate(['/index']);
  }

  doblocked() {
    this.blocked.next(true);
  }
  logout1() {
    //this.addSnackBar({message:'Your session has been closed.Please login to continue.'});
     localStorage.removeItem("ziyyara_token");
     localStorage.removeItem("token1");
    this.store.dispatch(new AppActions.PropertyLogout());
    // this.document.location.href = 'https://ziyyara.in';
  
   this.router.navigate(['/index']);
  //this.document.location.href = 'http://13.233.79.57/';
  }
  returnCountryList(){
    let countryCodes=[
      {
        "code": "+7 840",
        "name": "+7 840 Abkhazia"
      },
      {
        "code": "+93",
        "name": "+93  Afghanistan"
      },
      {
        "code": "+355",
        "name": "+355  Albania"
      },
      {
        "code": "+213",
        "name": "+213  Algeria"
      },
      {
        "code": "+1 684",
        "name": "+1 684  American Samoa"
      },
      {
        "code": "+376",
        "name": "+376  Andorra"
      },
      {
        "code": "+244",
        "name": "+244  Angola"
      },
      {
        "code": "+1 264",
        "name": "+1 264  Anguilla"
      },
      {
        "code": "+1 268",
        "name": "+1 268  Antigua and Barbuda"
      },
      {
        "code": "+54",
        "name": "+54  Argentina"
      },
      {
        "code": "+374",
        "name": "+374  Armenia"
      },
      {
        "code": "+297",
        "name": "+297  Aruba"
      },
      {
        "code": "+247",
        "name": "+247  Ascension"
      },
      {
        "code": "+61",
        "name": "+61  Australia"
      },
      {
        "code": "+672",
        "name": "+672  Australian External Territories"
      },
      {
        "code": "+43",
        "name": "+43  Austria"
      },
      {
        "code": "+994",
        "name": "+994  Azerbaijan"
      },
      {
        "code": "+1 242",
        "name": "+1 242  Bahamas"
      },
      {
        "code": "+973",
        "name": "+973  Bahrain"
      },
      {
        "code": "+880",
        "name": "+880  Bangladesh"
      },
      {
        "code": "+1 246",
        "name": "+1 246  Barbados"
      },
      {
        "code": "+1 268",
        "name": "+1 268  Barbuda"
      },
      {
        "code": "+375",
        "name": "+375  Belarus"
      },
      {
        "code": "+32",
        "name": "+32  Belgium"
      },
      {
        "code": "+501",
        "name": "+501  Belize"
      },
      {
        "code": "+229",
        "name": "+229  Benin"
      },
      {
        "code": "+1 441",
        "name": "+1 441  Bermuda"
      },
      {
        "code": "+975",
        "name": "+975  Bhutan"
      },
      {
        "code": "+591",
        "name": "+591  Bolivia"
      },
      {
        "code": "+387",
        "name": "+387  Bosnia and Herzegovina"
      },
      {
        "code": "+267",
        "name": "+267  Botswana"
      },
      {
        "code": "+55",
        "name": "+55  Brazil"
      },
      {
        "code": "+246",
        "name": "+246  British Indian Ocean Territory"
      },
      {
        "code": "+1 284",
        "name": "+1 284  British Virgin Islands"
      },
      {
        "code": "+673",
        "name": "+673  Brunei"
      },
      {
        "code": "+359",
        "name": "+359  Bulgaria"
      },
      {
        "code": "+226",
        "name": "+226  Burkina Faso"
      },
      {
        "code": "+257",
        "name": "+257  Burundi"
      },
      {
        "code": "+855",
        "name": "+855  Cambodia"
      },
      {
        "code": "+237",
        "name": "+237  Cameroon"
      },
      {
        "code": "+1",
        "name": "+1  Canada"
      },
      {
        "code": "+238",
        "name": "+238  Cape Verde"
      },
      {
        "code": "+ 345",
        "name": "+ 345  Cayman Islands"
      },
      {
        "code": "+236",
        "name": "+236  Central African Republic"
      },
      {
        "code": "+235",
        "name": "+235  Chad"
      },
      {
        "code": "+56",
        "name": "+56  Chile"
      },
      {
        "code": "+86",
        "name": "+86  China"
      },
      {
        "code": "+61",
        "name": "+61  Christmas Island"
      },
      {
        "code": "+61",
        "name": "+61  Cocos-Keeling Islands"
      },
      {
        "code": "+57",
        "name": "+57  Colombia"
      },
      {
        "code": "+269",
        "name": "+269  Comoros"
      },
      {
        "code": "+242",
        "name": "+242  Congo"
      },
      {
        "code": "+243",
        "name": "+243  Congo, Dem. Rep. of (Zaire)"
      },
      {
        "code": "+682",
        "name": "+682  Cook Islands"
      },
      {
        "code": "+506",
        "name": "+506  Costa Rica"
      },
      {
        "code": "+385",
        "name": "+385  Croatia"
      },
      {
        "code": "+53",
        "name": "+53  Cuba"
      },
      {
        "code": "+599",
        "name": "+599  Curacao"
      },
      {
        "code": "+537",
        "name": "+537  Cyprus"
      },
      {
        "code": "+420",
        "name": "+420  Czech Republic"
      },
      {
        "code": "+45",
        "name": "+45  Denmark"
      },
      {
        "code": "+246",
        "name": "+246  Diego Garcia"
      },
      {
        "code": "+253",
        "name": "+253  Djibouti"
      },
      {
        "code": "+1 767",
        "name": "+1 767  Dominica"
      },
      {
        "code": "+1 809",
        "name": "+1 809  Dominican Republic"
      },
      {
        "code": "+670",
        "name": "+670  East Timor"
      },
      {
        "code": "+56",
        "name": "+56  Easter Island"
      },
      {
        "code": "+593",
        "name": "+593  Ecuador"
      },
      {
        "code": "+20",
        "name": "+20  Egypt"
      },
      {
        "code": "+503",
        "name": "+503  El Salvador"
      },
      {
        "code": "+240",
        "name": "+240  Equatorial Guinea"
      },
      {
        "code": "+291",
        "name": "+291  Eritrea"
      },
      {
        "code": "+372",
        "name": "+372  Estonia"
      },
      {
        "code": "+251",
        "name": "+251  Ethiopia"
      },
      {
        "code": "+500",
        "name": "+500  Falkland Islands"
      },
      {
        "code": "+298",
        "name": "+298  Faroe Islands"
      },
      {
        "code": "+679",
        "name": "+679  Fiji"
      },
      {
        "code": "+358",
        "name": "+358  Finland"
      },
      {
        "code": "+33",
        "name": "+33  France"
      },
      {
        "code": "+596",
        "name": "+596  French Antilles"
      },
      {
        "code": "+594",
        "name": "+594  French Guiana"
      },
      {
        "code": "+689",
        "name": "+689  French Polynesia"
      },
      {
        "code": "+241",
        "name": "+241  Gabon"
      },
      {
        "code": "+220",
        "name": "+220  Gambia"
      },
      {
        "code": "+995",
        "name": "+995  Georgia"
      },
      {
        "code": "+49",
        "name": "+49  Germany"
      },
      {
        "code": "+233",
        "name": "+233  Ghana"
      },
      {
        "code": "+350",
        "name": "+350  Gibraltar"
      },
      {
        "code": "+30",
        "name": "+30  Greece"
      },
      {
        "code": "+299",
        "name": "+299  Greenland"
      },
      {
        "code": "+1 473",
        "name": "+1 473  Grenada"
      },
      {
        "code": "+590",
        "name": "+590  Guadeloupe"
      },
      {
        "code": "+1 671",
        "name": "+1 671  Guam"
      },
      {
        "code": "+502",
        "name": "+502  Guatemala"
      },
      {
        "code": "+224",
        "name": "+224  Guinea"
      },
      {
        "code": "+245",
        "name": "+245  Guinea-Bissau"
      },
      {
        "code": "+595",
        "name": "+595  Guyana"
      },
      {
        "code": "+509",
        "name": "+509  Haiti"
      },
      {
        "code": "+504",
        "name": "+504  Honduras"
      },
      {
        "code": "+852",
        "name": "+852  Hong Kong SAR China"
      },
      {
        "code": "+36",
        "name": "+36 Hungary"
      },
      {
        "code": "+354",
        "name": "+354  Iceland"
      },
      {
        "code": "+91",
        "name": "+91 India",
      },
      {
        "code": "+62",
        "name": "+62  Indonesia"
      },
      {
        "code": "+98",
        "name": "+98  Iran"
      },
      {
        "code": "+964",
        "name": "+964  Iraq"
      },
      {
        "code": "+353",
        "name": "+353  Ireland"
      },
      {
        "code": "+972",
        "name": "+972  Israel"
      },
      {
        "code": "+39",
        "name": "v  Italy"
      },
      {
        "code": "+225",
        "name": "+225  Ivory Coast"
      },
      {
        "code": "+1 876",
        "name": "+1 876  Jamaica"
      },
      {
        "code": "+81",
        "name": " +81 Japan"
      },
      {
        "code": "+962",
        "name": "+962  Jordan"
      },
      {
        "code": "+7 7",
        "name": "+7 7  Kazakhstan"
      },
      {
        "code": "+254",
        "name": "+254  Kenya"
      },
      {
        "code": "+686",
        "name": "+686  Kiribati"
      },
      {
        "code": "+965",
        "name": "+965  Kuwait"
      },
      {
        "code": "+996",
        "name": "+996  Kyrgyzstan"
      },
      {
        "code": "+856",
        "name": "+856  Laos"
      },
      {
        "code": "+371",
        "name": "v  Latvia"
      },
      {
        "code": "+961",
        "name": "+961  Lebanon"
      },
      {
        "code": "+266",
        "name": "+266  Lesotho"
      },
      {
        "code": "+231",
        "name": "+231  Liberia"
      },
      {
        "code": "+218",
        "name": "+218  Libya"
      },
      {
        "code": "+423",
        "name": "+423  Liechtenstein"
      },
      {
        "code": "+370",
        "name": "+370  Lithuania"
      },
      {
        "code": "+352",
        "name": "+352  Luxembourg"
      },
      {
        "code": "+853",
        "name": "+853  Macau SAR China"
      },
      {
        "code": "+389",
        "name": "+389  Macedonia"
      },
      {
        "code": "+261",
        "name": "+261  Madagascar"
      },
      {
        "code": "+265",
        "name": "+265  Malawi"
      },
      {
        "code": "+60",
        "name": "+60  Malaysia"
      },
      {
        "code": "+960",
        "name": "+960  Maldives"
      },
      {
        "code": "+223",
        "name": "+223  Mali"
      },
      {
        "code": "+356",
        "name": "+356  Malta"
      },
      {
        "code": "+692",
        "name": "+692  Marshall Islands"
      },
      {
        "code": "+596",
        "name": "+596  Martinique"
      },
      {
        "code": "+222",
        "name": "+222  Mauritania"
      },
      {
        "code": "+230",
        "name": "+230  Mauritius"
      },
      {
        "code": "+262",
        "name": "+262  Mayotte"
      },
      {
        "code": "+52",
        "name": "+52  Mexico"
      },
      {
        "code": "+691",
        "name": "+691  Micronesia"
      },
      {
        "code": "+1 808",
        "name": "+1 808  Midway Island"
      },
      {
        "code": "+373",
        "name": "+373  Moldova"
      },
      {
        "code": "+377",
        "name": "+377  Monaco"
      },
      {
        "code": "+976",
        "name": "+976  Mongolia"
      },
      {
        "code": "+382",
        "name": "+382  Montenegro"
      },
      {
        "code": "+1664",
        "name": "+1664  Montserrat"
      },
      {
        "code": "+212",
        "name": "+212  Morocco"
      },
      {
        "code": "+95",
        "name": "+95  Myanmar"
      },
      {
        "code": "+264",
        "name": "+264  Namibia"
      },
      {
        "code": "+674",
        "name": "+674  Nauru"
      },
      {
        "code": "+977",
        "name": "+977  Nepal"
      },
      {
        "code": "+31",
        "name": "+31  Netherlands"
      },
      {
        "code": "+599",
        "name": "+599  Netherlands Antilles"
      },
      {
        "code": "+1 869",
        "name": "+1 869  Nevis"
      },
      {
        "code": "+687",
        "name": "+687  New Caledonia"
      },
      {
        "code": "+64",
        "name": "+64  New Zealand"
      },
      {
        "code": "+505",
        "name": "+505  Nicaragua"
      },
      {
        "code": "+227",
        "name": "+227  Niger"
      },
      {
        "code": "+234",
        "name": "+234  Nigeria"
      },
      {
        "code": "+683",
        "name": "+683  Niue"
      },
      {
        "code": "+672",
        "name": "+672  Norfolk Island"
      },
      {
        "code": "+850",
        "name": "+850  North Korea"
      },
      {
        "code": "+1 670",
        "name": "+1 670  Northern Mariana Islands"
      },
      {
        "code": "+47",
        "name": "+47  Norway"
      },
      {
        "code": "+968",
        "name": "+968  Oman"
      },
      {
        "code": "+92",
        "name": "+92  Pakistan"
      },
      {
        "code": "+680",
        "name": "+680  Palau"
      },
      {
        "code": "+970",
        "name": "+970  Palestinian Territory"
      },
      {
        "code": "+507",
        "name": "+507  Panama"
      },
      {
        "code": "+675",
        "name": "+675  Papua New Guinea"
      },
      {
        "code": "+595",
        "name": "+595  Paraguay"
      },
      {
        "code": "+51",
        "name": "+51  Peru"
      },
      {
        "code": "+63",
        "name": "+63  Philippines"
      },
      {
        "code": "+48",
        "name": "+48  Poland"
      },
      {
        "code": "+351",
        "name": "+351  Portugal"
      },
      {
        "code": "+1 787",
        "name": "+1 787  Puerto Rico"
      },
      {
        "code": "+974",
        "name": "+974  Qatar"
      },
      {
        "code": "+262",
        "name": "+262  Reunion"
      },
      {
        "code": "+40",
        "name": "+40  Romania"
      },
      {
        "code": "+7",
        "name": "+7  Russia"
      },
      {
        "code": "+250",
        "name": "+250  Rwanda"
      },
      {
        "code": "+685",
        "name": "+685  Samoa"
      },
      {
        "code": "+378",
        "name": "+378  San Marino"
      },
      {
        "code": "+966",
        "name": "+966  Saudi Arabia"
      },
      {
        "code": "+221",
        "name": "+221  Senegal"
      },
      {
        "code": "+381",
        "name": "+381  Serbia"
      },
      {
        "code": "+248",
        "name": "+248  Seychelles"
      },
      {
        "code": "+232",
        "name": "+232  Sierra Leone"
      },
      {
        "code": "+65",
        "name": "+65  Singapore"
      },
      {
        "code": "+421",
        "name": "+421  Slovakia"
      },
      {
        "code": "+386",
        "name": "+386  Slovenia"
      },
      {
        "code": "+677",
        "name": "+677  Solomon Islands"
      },
      {
        "code": "+27",
        "name": "+27  South Africa"
      },
      {
        "code": "+500",
        "name": "+500  South Georgia and the South Sandwich Islands"
      },
      {
        "code": "+82",
        "name": "+82  South Korea"
      },
      {
        "code": "+34",
        "name": "+34  Spain"
      },
      {
        "code": "+94",
        "name": "+94  Sri Lanka"
      },
      {
        "code": "+249",
        "name": "+249  Sudan"
      },
      {
        "code": "+597",
        "name": "+597  Suriname"
      },
      {
        "code": "+268",
        "name": "+268  Swaziland"
      },
      {
        "code": "+46",
        "name": "+46  Sweden"
      },
      {
        "code": "+41",
        "name": "+41  Switzerland"
      },
      {
        "code": "+963",
        "name": "+963  Syria"
      },
      {
        "code": "+886",
        "name": "+886  Taiwan"
      },
      {
        "code": "+992",
        "name": "+992  Tajikistan"
      },
      {
        "code": "+255",
        "name": "+255  Tanzania"
      },
      {
        "code": "+66",
        "name": "+66  Thailand"
      },
      {
        "code": "+670",
        "name": "+670  Timor Leste"
      },
      {
        "code": "+228",
        "name": "+228  Togo"
      },
      {
        "code": "+690",
        "name": "+690  Tokelau"
      },
      {
        "code": "+676",
        "name": "+676  Tonga"
      },
      {
        "code": "+1 868",
        "name": "+1 868  Trinidad and Tobago"
      },
      {
        "code": "+216",
        "name": "+216  Tunisia"
      },
      {
        "code": "+90",
        "name": "+90  Turkey"
      },
      {
        "code": "+993",
        "name": "+993  Turkmenistan"
      },
      {
        "code": "+1 649",
        "name": "+1 649  Turks and Caicos Islands"
      },
      {
        "code": "+688",
        "name": "+688  Tuvalu"
      },
      {
        "code": "+1 340",
        "name": "+1 340  U.S. Virgin Islands"
      },
      {
        "code": "+256",
        "name": "+256  Uganda"
      },
      {
        "code": "+380",
        "name": "+380  Ukraine"
      },
      {
        "code": "+971",
        "name": "+971  United Arab Emirates"
      },
      {
        "code": "+44",
        "name": "+44  United Kingdom"
      },
      {
        "code": "+1",
        "name": "+1  United States"
      },
      {
        "code": "+598",
        "name": "+598  Uruguay"
      },
      {
        "code": "+998",
        "name": "+998  Uzbekistan"
      },
      {
        "code": "+678",
        "name": "+678  Vanuatu"
      },
      {
        "code": "+58",
        "name": "+58  Venezuela"
      },
      {
        "code": "+84",
        "name": "+84  Vietnam"
      },
      {
        "code": "+1 808",
        "name": "+1 808  Wake Island"
      },
      {
        "code": "+681",
        "name": "+681  Wallis and Futuna"
      },
      {
        "code": "+967",
        "name": "+967  Yemen"
      },
      {
        "code": "+260",
        "name": "+260  Zambia"
      },
      {
        "code": "+255",
        "name": "+255  Zanzibar"
      },
      {
        "code": "+263",
        "name": "+263  Zimbabwe"
      }
    ]

  return countryCodes
}

}
