import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmationBoxComponent } from '../../../../learning/shared/components/confirmation-box/confirmation-box.component';
import { Store, select } from '@ngrx/store';
import { ApiResponse, AppState, IPROPERTY } from '../../../../interfaces';
import { Subscription } from 'rxjs';
import { AppService, HttpService } from '../../../../services';

import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SignupComponent } from '../../../../learning/shared/components/signup/signup.component';
@Component({
  selector: 'app-footer-front',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterFrontComponent {


currentYear;

  constructor(public dialog: MatDialog, private store: Store<AppState>,
    private appSer: AppService, private _api: HttpService, private _router: Router,
    private _route: ActivatedRoute) {

  }
  logout() {
    const content = "Are you sure you want to logout?";
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      width: '31%',
      data: {
        content: { title: 'Logout', message: content },
        action: 'active'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.response) {
          this.appSer.logout();
        }
      }
    });
  }
 
  sendToRegister() {

    
    this.signup();
  }

  /****************open login page in ziyara ******************/

  signup(): void {
    const dialogRef = this.dialog.open(SignupComponent, {
      width: "425px",
      height: "auto"
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  ngOnInit() {
   this.currentYear= new Date().getFullYear()
  }
}
