     
import { Injectable ,Inject} from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, retry, map, } from 'rxjs/operators';

// Modules
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppService } from '../services/app.service';
import { ApiResponse, AppState } from '../interfaces';
import { AppStateService } from '../services/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private_key;
  baseUrl: string = environment.baseUrl;
  constructor(  private http: HttpClient, private router: Router,
    private appSer: AppService, public appStateSer: AppStateService) {
  }


  // ------------------------------------------------------//
  get httpOptionsAuth() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + localStorage.getItem("ziyyara_token"),
        'accessToken': localStorage.getItem("ziyyara_token")
      })
    };
  }

  get httpOptionsUnauths() {
    return {
      headers: new HttpHeaders({
        // "Access-Control-Allow-Origin": "*",
        // 'Content-Type': 'null',
        // 'Accept':"multipart/form-data"
      })
    };
  }


  // ------------------------------------------------------//
  get httpOptionsUnauth() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
  }

  httpOptionsAuth1() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' +  localStorage.getItem('accessToken'),
        'accessToken':  localStorage.getItem('accessToken')
      })
    };
  }
  postReqUnauths(url: string, body: any) {
    const reqUrl = this.baseUrl + url;
    let param = new HttpParams();


    return this.http.post(reqUrl, body, this.httpOptionsUnauths)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
    // return this.http.post(reqUrl, body, this.httpOptionsUnauths)
    //   .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }


  postReqAuths(url: string, body: any): Observable<any> {
    const reqUrl = this.baseUrl + url;

    return this.http.post(reqUrl, body, this.httpOptionsAuth1())
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }
  // ------------------------------------------------------//
  /* POST METHODS*/
  postReqUnauth(url: string, body: any) {
    const reqUrl = this.baseUrl + url;
    ////console.log(reqUrl);
    return this.http.post(reqUrl, body, this.httpOptionsUnauth)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  // GEO LOCATION GET Api
  getLocation(){
    return this.http.get('https://ipapi.co/json');
  }
  // END GEO//


  // ------------------------------------------------------//
  // Auth
  postReqAuth(url: string, body: any): Observable<any> {
    const reqUrl = this.baseUrl + url;
    ////console.log(reqUrl);
    return this.http.post(reqUrl, body, this.httpOptionsAuth)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }


  // ------------------------------------------------------//
  // Get request
  getReqAuth(url: string) {
    this.appSer.showLoader();
    const reqUrl = this.baseUrl + url;
    return this.http.get(reqUrl, this.httpOptionsAuth)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  getReqAuthDate(url: string) {
    //get request to get class dates and to avoid too much loader
  //  this.appSer.showLoader();
    const reqUrl = this.baseUrl + url;
    return this.http.get(reqUrl, this.httpOptionsAuth)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  getReqUnauth(url: string) {
    const reqUrl = this.baseUrl + url;
    return this.http.get(reqUrl, this.httpOptionsUnauth)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }
  // ------------------------------------------------------//
  getReqAuthValidation(url: string) {
    const reqUrl = this.baseUrl + url;
    return this.http.get(reqUrl, this.httpOptionsAuth)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }


  getReqWalletBalance(url: string) {
    ////console.log(url)
    const reqUrl = this.baseUrl + url;
    ////console.log(reqUrl);
    return this.http.get(reqUrl, this.httpOptionsAuth)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }
  // ------------------------------------------------------//

  /**
 * @description Patch request for authenticated api
 * @param url Url that needs to be passed to the application
 * @param body Body of the request
 */
  patchReqAuth(url: string, body: any) {
    const reqUrl = this.baseUrl + url;
    return this.http.patch(reqUrl, body, this.httpOptionsAuth)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }


  // ------------------------------------------------------//
  /**
   * @description Patch request for unauthenticated api
   * @param url Url that needs to be passed to the application
   * @param body Body of the request
   */
  patchReqUnauth(url: string, body: any) {
    const reqUrl = this.baseUrl + url;
    return this.http.patch(reqUrl, body, this.httpOptionsUnauth)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }


  deleteReqAuth(url: string) {
    const reqUrl = this.baseUrl + url;
    return this.http.delete(reqUrl, this.httpOptionsAuth)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }


  // ------------------------------------------------------//
  private handleError(err: HttpErrorResponse) {
    //   alert();
    // ////console.log(err)
    // return an ErrorObservable with a user-facing error message
    // this.appSer.hideLoader();
    /*if (err.error['errorCode'] === 411) {
      this.appSer.doblocked();
    }  else if(err.error['errorCode']===499){
      this.appSer.logout();
    }*/
    return of(err.error);
  }

  getDownloadFile(url: string) {
    // ////console.log("file is  downloading..")
    const reqUrl = this.baseUrl + url;
    // const options = {
    //   responseType: 'arraybuffer' as 'json'
    // }


    return this.http.get<any>(reqUrl);
  }

  getReqAuthIP(url: string) {
    this.appSer.showLoader();
    const reqUrl = url;
    return this.http.get(reqUrl, this.httpOptionsUnauth)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }
  getCities(url: string) {
    ////console.log("get city called")
    const reqUrl = this.baseUrl + url;
    // ////console.log("url->", reqUrl)
    return this.http.get(reqUrl)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }
  getBooking(id: string) {
    // ////console.log("get city called")
    const reqUrl = this.baseUrl + "booking/" + id;
    // ////console.log("url->", reqUrl)
    return this.http.get(reqUrl)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }
  contactUs(url: string, body) {
    const reqUrl = this.baseUrl + url;
    return this.http.post(reqUrl, body, this.httpOptionsUnauths)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  } 

  adcontact(url: string, body) {
    const reqUrl = this.baseUrl + url;
    return this.http.post(reqUrl, body, this.httpOptionsUnauths)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

//get geolocation address

getCurrentAddress(lat,lang){
  var map_key='AIzaSyD93451EQN8nug9ov1owAe3cYrXEKGe1eo';
  const reqUrl =  'https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+lang+'&key='+map_key
  //////console.log(reqUrl)
  return this.http.get(reqUrl) .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
 
  
  }
  getRealTimeCurrencyValues(){
    
  const reqUrl ='http://data.fixer.io/api/latest?access_key=5762186c4dfed00d5a8f02a61410f318&format=1';
  

  return this.http.get(reqUrl) .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

convertCurrency(from,to,amount){

const reqUrl='https://data.fixer.io/api/convert?access_key=5762186c4dfed00d5a8f02a61410f318&from='+from+'&to='+to+'&amount='+amount
////console.log(reqUrl)
return this.http.get(reqUrl) .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
}

 
}
