import { Action } from '@ngrx/store';
import { AppState, IPROPERTY} from '../../interfaces';

export const APP_LOADER_TRUE = '[App] SetLoader';
export const APP_LOADER_FALSE = '[App] UnsetLoader';
export const APP_PROPERTY_SIGNUP = '[App] PropertySignup';
export const APP_PROPERTY_LOGIN = '[App] PropertyLogin';
export const APP_PROPERTY_LOGOUT = '[App] PropertyLogout';
export const APP_FORGOT_TOKEN = '[App] ForgotToken';



export class AppSetLoader implements Action {

    readonly type = APP_LOADER_TRUE;

    constructor(public payload: AppState) {

    }
}


export class AppUnsetLoader implements Action {

    readonly type = APP_LOADER_FALSE;

    constructor(public payload: AppState) {

    }
}

export class PropertySignup implements Action {

    readonly type = APP_PROPERTY_SIGNUP;

    constructor(public payload: IPROPERTY) {
    }
}


export class PropertyLogin implements Action {

    readonly type = APP_PROPERTY_LOGIN;

    constructor(public payload: IPROPERTY) {
    }
}

export class PropertyLogout implements Action {
    readonly type = APP_PROPERTY_LOGOUT;

    constructor() {

    }
}

export class ForgotToken implements Action {

    readonly type = APP_FORGOT_TOKEN;

    constructor(public payload: string) {
    }
}








export type All = AppSetLoader | AppUnsetLoader | PropertySignup
    | PropertyLogout | ForgotToken  | PropertyLogin
     ;
