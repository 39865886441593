import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-gcse',
  templateUrl: './gcse.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class GcseComponent implements OnInit {
  websiteSchema={
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Is school fees higher?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, it’s on the higher side. But we at Ziyyara have kept the charges quite low so that all parents can bear the cost. Opting for online tutoring via GCSE private tutor at Ziyyara will permit you to get additional academic assistance for your children that too at the most reduced cost."
      }
    },{
      "@type": "Question",
      "name": "How can I enroll my child at your platform for the online GCSE home classes?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You need to first fill the registration form by mentioning all the details related to your child. After receiving your inquiry, we will provide the best tutor for online GCSE home tuition."
      }
    },{
      "@type": "Question",
      "name": "What is GCSE board and it’s full form?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The General Certificate of Secondary Education (GCSE) is a theoretical prerequisite in a specific subject, which is majorly taken in European countries. Training for GCSE exams is conducted for 2-2 academic years (it is based on the subject, school, and exam board). The studies mostly start in Year 9 or Year 10 for most of the students and the exams are scheduled towards the end of the Year."
      }
    },{
      "@type": "Question",
      "name": "What is your approach towards GCSE online tuition?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We follow a completely dissimilar method as compared to what is being tutored by other tutors. We introduce subject experts who can take GCSE online classes. We strongly believe that to get a good score in these exams, students must know the skills to tackle areas of weakness, along with strengthening their stronger area. Post getting a detailed understanding of students’ learning style and abilities, we will assign a skilled online private tutor for GCSE who can help your child improve their score significantly."
      }
    },{
      "@type": "Question",
      "name": "Do my teenagers require something detailed while arranging for the GCSE Board?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Honestly, GCSE is slightly difficult as compared to other boards. Thus, we recommend all our apprentices to stay active during Online tuition for GCSE so they can understand all the concepts."
      }
    }]
  }
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    this.titleService.setTitle(" Online Tuition For GCSE Board | GCSE Tuition Online   ");
    this.slides = this.chunk(this.cards, 3);
    var desc="Ziyyara’s online GCSE tuition tutors are well versed with every subject and our online tuition for GCSE board help students to stay more focussed towards achieving good marks."
     this.meta.updateTag({ name: 'description', content: desc })
     this.meta.updateTag({ name: 'keywords', content:"GCSE board, what is ib GCSE, GCSE board syllabus, GCSE board in india, GCSE Private tutors, Tuition For GCSE Students, Online Classes For Gcse, GCSE home tuition, private tutor for GCSE, GCSE private tutor, GCSE home tutor, Gcse Tutor. GCSE home classes, GCSE home tuition classes"})
     var url=  location.pathname;
 
     
     var ogtitle="Online Tuition For GCSE Board | GCSE Tuition Online     ";
     this.meta.updateTag({ property: 'og:title', content: ogtitle });
    var ogdesc=" Ziyyara’s online GCSE tuition tutors are well versed with every subject and our online tuition for GCSE board help students to stay more focussed towards achieving good marks.   ";
    this.meta.updateTag({ property:'og:description', content: ogdesc });
  
    var twittertitle="Online Tuition For GCSE Board | GCSE Tuition Online     ";
    this.meta.updateTag({ name:"twitter:title", content: twittertitle });
   var twitterdesc=" Ziyyara’s online GCSE tuition tutors are well versed with every subject and our online tuition for GCSE board help students to stay more focussed towards achieving good marks.  ";
   this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
     this.meta_service.createCanonicalURL(url);
     this.meta_service.removeStructuredData();
     this.meta_service.insertSchema(this.websiteSchema)
  
   }
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
 
  cards = [
    {
      title: 'IB Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-ib-board.webp'
    },
    {
      title: 'ICSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-icse-board.webp'
    },
    {
      title: 'CBSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cbse-board.webp'
    },
    {
      title: 'ISC Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-isc-board.webp'
    },
    {
      title: 'IGCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-igcse-board.webp'
    },
   
    {
      title: 'CISCE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cisce-board.webp'
    },
    {
      title: 'GCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-gcse-board.webp'
    },
    {
      title: 'Cambridge International-Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cambridge-international-board.webp'
    },
   
  
  
  ];
  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase().replace(" ","-");
    return '/home-tuition/online-home-tuition-for-'+subject;
  }
}
