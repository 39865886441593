import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-computer-science',
  templateUrl: './computer-science.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class ComputerScienceComponent implements OnInit {
  cards = [
    {
      title: 'Economics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Economics.jpg'
    },
    {
      title: 'Maths',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Mathematics.jpg'
    },
    {
      title: 'English',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/English.jpg'
    },
    {
      title: 'Physics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Physics.jpg'
    },
    {
      title: 'History',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/History.jpg'
    },
    {
      title: 'Hindi',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Hindi.jpg'
    },
    {
      title: 'Geography',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Geography.jpg'
    },
    {
      title: 'Business Studies',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Business-Studies.jpg'
    },
    {
      title: 'Biology',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Biology.jpg'
    },
    {
      title: 'Political-Science',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Political-Science.jpg'
    },
    {
      title: 'Accountancy',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Accountancy.jpg'
    },
    {
      title: 'Chemistry',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Chemistry.jpg'
    },

    // {
    //   title: 'Computer Science',
    //  // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    //   buttonText: 'Read',
    //   img: '/assets/images/Computer-Science.jpg'
    // },
    {
      title: 'Vedic Mathematics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/vedic-math.webp'
    },
  ];
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What are the conditions to attend Computer Science home tuition classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A stable internet connection for Laptop/Desktop/Computer/Tablet/Mobile."
    }
  },{
    "@type": "Question",
    "name": "Can a student ask his/her problem immediately?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, we have online peculiarities in our Computer Science home tuition classes that render real-time live voice communication during the online class. You can also write & submit your problem anytime and your online tutor for Computer Science will provide you an answer in your next class. Ziyyara's teacher can provide your study material and recording of the class. You just have to enquire about it from your Computer Science tuition tutor."
    }
  },{
    "@type": "Question",
    "name": "Q.3: Does Ziyyara provide counseling or PTM sessions?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We conduct PTM meetings often to let parents know about the progress of their kids. Our computer science tuition asks parents to know about their point of view and their expectations. These meetings are led between the Computer Science tutor and parents."
    }
  },{
    "@type": "Question",
    "name": "How can I become a Computer Science private tutor?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If you meet our requirements and you want to become a tutor for computer science, you should talk to Ziyyara's hiring department! We will guide you further!"
    }
  },{
    "@type": "Question",
    "name": "Where does Ziyyara organize the classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara tuition of Computer Science will let you attend the classes online. We have our in-built software with audio/video call and virtual whiteboard. You just need to have a stable internet connection with a working PC/Laptop, and you are done!"
    }
  }]
}
ngOnInit() {
  //slider
this.slides = this.chunk(this.cards, 3);
this.titleService.setTitle("Online Tuition For Computer Science | Computer Science Tuition Classes ");
var desc="Ziyyara’s online tuition for Computer Science always involves unique methods to impart technical knowledge to the students of all boards. For a free demo call us on +91 9654271931."
this.meta.updateTag({ name: 'description', content: desc })
var keywords="Online tuition for Computer Science, Computer Science online tuition, tuition for Computer Science, Computer Science tuition classes, tuition classes of Computer Science, Computer Science home tuition, home tuition of Computer Science, tuition of Computer Science, Computer Science home classes, Computer Science home tuition classes, Computer Science tutor, Computer Science home tutor, tutor for Computer Science, Computer Science home tutor";
this.meta.updateTag({ name: 'keywords', content: keywords });
var url=  location.pathname;

var ogtitle="Online Tuition For Computer Science | Computer Science Tuition Classes";
this.meta.updateTag({ property: 'og:title', content: ogtitle });
var ogdesc="  Ziyyara’s online tuition for Computer Science always involves unique methods to impart technical knowledge to the students of all boards. For a free demo call us on +91 9654271931.";
this.meta.updateTag({ property:'og:description', content: ogdesc });

var twittertitle="Online Tuition For Computer Science | Computer Science Tuition Classes";
this.meta.updateTag({ name:"twitter:title", content: twittertitle });
var twitterdesc="   Ziyyara’s online tuition for Computer Science always involves unique methods to impart technical knowledge to the students of all boards. For a free demo call us on +91 9654271931.";
this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
this.meta_service.createCanonicalURL(url)

this.meta_service.removeStructuredData();
this.meta_service.insertSchema(this.websiteSchema);

}
 generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  if(subject.includes("vedic"))
  
  {
    return "/vedic-math-workshop"
  
  } 
    else if (subject.includes("computer")){
      return '/home-tuition/online-home-tuition-for-computer-science'
    }
    else if (subject.includes("business")){
      return '/home-tuition/online-home-tuition-for-business-studies'
    }
  else {

    return '/home-tuition/online-home-tuition-for-'+subject
  }

}
}
