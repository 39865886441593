import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


import { AppService } from '../../services';
import { AppStateService } from '../../services/app-state.service';
import { CommonUtil } from '../../util';
import { CONFIG } from '../../constants';
import { PopupMessage } from '../../interfaces';



@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.css']
})
export class LogoutModalComponent implements OnInit {

  CONFIG = CONFIG;

  constructor(public dialogRef: MatDialogRef<LogoutModalComponent>,
    public util: CommonUtil, private appState: AppStateService, private appSer: AppService,
    @Inject(MAT_DIALOG_DATA) public data: PopupMessage) {
  }

  ngOnInit() {
  }


  no() {
    this.dialogRef.close(false);
  }
}
