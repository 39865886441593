import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-icse',
  templateUrl: './icse.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css','./icse.component.css']
})
export class IcseComponent implements OnInit {
  tutorData=[
    {
    name:"  Ishant Chhabra",
    exp:"10 years",
    grade:"4th to 12th "
  
  },
  {
    name:"Richa khanna",
    exp:"5 years",
    grade:"8th to 12th "
  
  },
  {
    name:" Roohi Saxena",
    exp:"10 years",
    grade:"1st to 8th "
  
  },
  {
    name:"Lekshmi",
    exp:"6 years",
    grade:"9th to 12th "
  
  }

]
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How can I study this Board?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "To get admitted in class X or higher level, it’s mandatory for the applicants to get permission from the Principal of their school, where they are presently studying. Students planning to take ICSE school admission in another school have to adhere to the same method. To know more about the same, you can get in touch with our coordinating team."
    }
  },{
    "@type": "Question",
    "name": "Explain the detailed process to start registration at Ziyyara for online tuition for ICSE?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our coordinating team has made the entire process quite easy and time saving so that within a few minutes, you can successfully register at our platform. More details related to the online tuition for ICSE are presented on the official website."
    }
  },{
    "@type": "Question",
    "name": "What is ICSE and ICSE board full form?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "It’s Indian Certificate of Secondary Education."
    }
  },{
    "@type": "Question",
    "name": "Why should Ziyyara be my top-most priority in regard to the online ICSE home tuition?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We at Ziyyara offer online tuition classes of ICSE that are scheduled based on the learning capabilities of the students. In totaling to providing ICSE tutoring services, our tutors are highly experienced in providing coaching as per different boards like CBSE, IB, among many others. Online live classes via white boards is something which is not available at other platforms offering ICSE tuition classes."
    }
  },{
    "@type": "Question",
    "name": "Whether ICSE board is challenging in contrast to others?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "ICSE Syllabus is difficult if we bring it in comparison to other boards like CBSE because of the vast subjects of ICSE Board. But students are encouraged to get all the concepts clear by adopting a personal approach. Moreover, completing studies from this board is beneficial in case you plan to appear for the TOEFL Exam."
    }
  },{
    "@type": "Question",
    "name": "What all things are desired to make online classes more successful?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "To start online class, schoolchildren must have a PC with the latest Windows or a smartphone. A headset with a microphone is needed to communicate with an online ICSE private tutor. Most importantly, a high-speed internet connection is needed so that undisturbed online classes can be carried. We at Ziyyara recommend Google Chrome as it has all the latest features needed while taking online classes."
    }
  }]
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle("Online Tuition For ICSE Board | Online ICSE Tuition");
   var desc="Get online tuition for ICSE Board at the click of a button. Ziyyara offers online ICSE tuition that is scheduled based on the learning capabilities of the students.    "
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ name: 'keywords', content:"home tuition for icse classes, icse board, icse board full form, icse board in india, icse board syllabus, icse classes tuition classes, icse home tuition, icse home tuition tutor, icse home tutor, icse online home tuition, icse online tuition, icse online tutiton, icse schools in india, icse tutor, online icse tuition, online tuition for icse, tuition for icse classes"})
    var url=  location.pathname;

    var ogtitle="Online Tuition For ICSE Board | Online ICSE Tuition";
    this.meta.updateTag({ property: 'og:title', content: ogtitle });
   var ogdesc="  Get online tuition for ICSE Board at the click of a button. Ziyyara offers online ICSE tuition that is scheduled based on the learning capabilities of the students.  ";
   this.meta.updateTag({ property:'og:description', content: ogdesc });
 
   var twittertitle="Online Tuition For ICSE Board | Online ICSE Tuition";
   this.meta.updateTag({ name:"twitter:title", content: twittertitle });
  var twitterdesc="  Get online tuition for ICSE Board at the click of a button. Ziyyara offers online ICSE tuition that is scheduled based on the learning capabilities of the students.  ";
  this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema)
  }
 

  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  cards = [
    {
      title: 'IB Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-ib-board.webp'
    },
    {
      title: 'ICSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-icse-board.webp'
    },
    {
      title: 'CBSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cbse-board.webp'
    },
    {
      title: 'ISC Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-isc-board.webp'
    },
    {
      title: 'IGCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-igcse-board.webp'
    },
   
    {
      title: 'CISCE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cisce-board.webp'
    },
    {
      title: 'GCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-gcse-board.webp'
    },
    {
      title: 'Cambridge International-Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cambridge-international-board.webp'
    },
   
  
  
  ];
  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase().replace(" ","-");
    return '/home-tuition/online-home-tuition-for-'+subject;
  }
}
