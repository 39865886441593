import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PropertyGuard } from './route-guards';
const appRoutes: Routes = [

    { path: '', loadChildren: './learning/front-home/home.module#HomeModule' },
    { path: 'index', loadChildren: './learning/front-home/home.module#HomeModule' },

    { path: 'register', loadChildren: './learning/front-home/home.module#HomeModule' },
    { path: 'get-started', loadChildren: './learning/register/register.module#RegisterModule'},
    { path: 'studentSignup', loadChildren: './learning/register/register.module#RegisterModule'},
    { path: 'teacherSignup', loadChildren: './learning/register/teacher-register.module#TeacherRegisterModule'},
    { path: 'login', loadChildren: './learning/register/login-new.module#LogInNewModule'},
    { path: 'profile', canLoad: [PropertyGuard], loadChildren: './learning/profile/profile.module#ProfileModule' },
    { path: 'search', canLoad: [PropertyGuard], loadChildren: './learning/search/search.module#SearchModule' },
    { path: 'wallet', canLoad: [PropertyGuard], loadChildren: './learning/wallet/wallet.module#WalletModule' },
    { path: 'request', canLoad: [PropertyGuard], loadChildren: './learning/request/request.module#RequestModule' },
    { path: 'booking', canLoad: [PropertyGuard], loadChildren: './learning/booking/booking.module#BookingModule' },
     {path:'dashboard' ,canLoad: [PropertyGuard],loadChildren:'./learning/dashboard/dashboard.module#DashboardModule'},
     {path:'alerts' ,canLoad: [PropertyGuard],loadChildren:'./learning/notification/notification.module#NotificationModule'},
    { path: 'privacy-policy', loadChildren: './learning/privacy/privacy.module#PrivacyModule' },
    { path: 'group-study', canLoad: [PropertyGuard], loadChildren: './learning/group-study/group-study.module#GroupStudyModule' },
    { path: 'about-us', loadChildren: './learning/about/about.module#AboutModule' },
    { path: 'about', loadChildren: './learning/about/about.module#AboutModule' },
    { path: 'faq', loadChildren: './learning/faq/faq.module#FaqModule' },
    { path: 'terms-conditions', loadChildren: './learning/terms/terms.module#TermsModule' },
    { path: 'career', loadChildren: './learning/career/career.module#CareerModule' },
    { path: 'meet-tutors', loadChildren: './learning/meetmytutors/meet-tutors.module#MeetTutorsModule' },
    { path: 'cancellation-and-refund', loadChildren: './learning/refund-and-cancellation/refund-and-cancellation.module#RefundAndCancellationModule' },
    { path: 'leadership-team', loadChildren: './learning/management-team/management-team.module#ManagementTeamModule' },
    { path: 'contact-us', loadChildren: './learning/contact/contact.module#ContactModule' },
    { path: 'explanation-of-Plant-Cell-and-Animal-Cell', loadChildren: './learning/blog/video-gallery.module#VideoGalleryModule' },
    { path: 'how-it-works', loadChildren: './learning/how-its-work/how-its-work.module#HowItsWorkModule' },
    { path: 'help', loadChildren: './learning/help/help.module#HelpModule' },
    { path: 'packages', loadChildren: './learning/pricing/pricing.module#PricingModule' },
    {path:'vedic-math-workshop' ,loadChildren:'./learning/vedic-maths-workshop/vedic-math-workshop.module#VedicMathWorkShopModule'},
    {path:'vedic-math-competition' ,loadChildren:'./learning/vedic-math-competition/vedic-math-competition.module#VedicMathCompetitionModule'},
    { path: 'ad-contact', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-economics', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-business-studies', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-accountancy', loadChildren: './learning/advtpage/AddModule#AddModule' },
    
    { path: 'pricing-silver', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'pricing-gold', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'pricing-platinum', loadChildren: './learning/advtpage/AddModule#AddModule' },
     
    { path: 'ad-contact-pol.science', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-english', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-chemistry', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-biology', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-physics', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-math', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-b.st', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-geography', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-history', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-hindi', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-computer-science', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'ad-contact-political-science', loadChildren: './learning/advtpage/AddModule#AddModule' },
//cities
{ path: 'ad-contact-delhi', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-andaman-nicobar', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-ranchi', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-ahmedabad', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-pune', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-kolkata', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-chandigarh', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-assam', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-bangalore', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-gurugram', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-karnataka', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-lucknow', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-gujrat', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-arunachal-pardesh', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-hyderabad', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-kerala', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-chennai', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-jaipur', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-coimbatore', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-mumbai', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-noida', loadChildren: './learning/advtpage/AddModule#AddModule' },
{ path: 'ad-contact-near-me', loadChildren: './learning/advtpage/AddModule#AddModule' },    
//boards
{path:'ad-contact-ib-board',loadChildren:'./learning/advtpage/AddModule#AddModule'},
{path:'ad-contact-icse',loadChildren:'./learning/advtpage/AddModule#AddModule'},
{path:'ad-contact-cbse',loadChildren:'./learning/advtpage/AddModule#AddModule'},
{path:'ad-contact-isc',loadChildren:'./learning/advtpage/AddModule#AddModule'},
{path:'ad-contact-igcse',loadChildren:'./learning/advtpage/AddModule#AddModule'},
{path:'ad-contact-cisce',loadChildren:'./learning/advtpage/AddModule#AddModule'},
{path:'ad-contact-gcse',loadChildren:'./learning/advtpage/AddModule#AddModule'},
{path:'ad-contact-cambridge',loadChildren:'./learning/advtpage/AddModule#AddModule'},

    { path: 'free-registration', loadChildren: './learning/advtpage/AddModule#AddModule' },
    { path: 'find-tutors', loadChildren: './learning/findtutors/find-tutors.module#FindTutorsModule' },
    { path: 'not-found', loadChildren: './learning/not-found/not-found.module#NotFoundModule' },
    { path: 'why-students', loadChildren: './learning/WhyStudents/WhyStudents.module#WhyStudentsModule' },
    { path: 'get-Online-Tuition', loadChildren: './learning/Get-Online-Tuition/GetOnlineTuition.module#GetOnlineTuitionModule' },
    { path: 'online-Tuition-Anytime', loadChildren: './learning/Online-Tuition-Anytime/OnlineTuitionAnytime.module#OnlineTuitionAnytimeModule' },
    { path: 'best-Online-Home-Tuition', loadChildren: './learning/Best-Online-Home-Tuitions/BestOnlineHomeTuitions.module#BestOnlineHomeTuitionModule' },
    { path: 'online-Home-Tuitions-For-All-Subjects', loadChildren: './learning/Online Home-Tuitions-for-All-Subjects/OnlineHomeTuitionsforAllSubjects.module#OnlineHomeTuitionsForAllSubjectsModule' },
    { path: 'how-online-tutoring-platforms-are-performing-better-than-schools', loadChildren: './learning/online-tuition-platform/online-tuition-platform/online-tuition-platform.module#OnlineTuitionPlatformModule' },
    // { path: '11-secrets-of-time-management-for-students', loadChildren: './learning/time-management/time-management.module#TimeManagementModule' },
    { path: 'previous-year-question-paper-for-cbse-class-10', loadChildren: './learning/previous-year-question-paper-for-cbse-class-10/previous-year-question-paper-for-cbse-class-10.module#PreviousYearQuestionPaperForCbseClass10Module' },
    { path: 'previous-year-question-paper-for-cbse-class-12', loadChildren: './learning/previous-year-question-paper-for-cbse-class-12/previous-year-question-paper-for-cbse-class-12.module#PreviousYearQuestionPaperForCbseClass12Module' },
    { path: 'icse-previous-year-question-paper-for-class-10', loadChildren: './learning/icse-previous-year-question-paper-for-class-10/icse-previous-year-question-paper-for-class-10.module#IcsePreviousYearQuestionPaperForClass10Module' },
    { path: 'icse-previous-year-question-paper-for-class-12', loadChildren: './learning/icse-previous-year-question-paper-for-class-12/icse-previous-year-question-paper-for-class-12.module#IcsePreviousYearQuestionPaperForClass12Module' },
    { path: 'home-tuition', loadChildren: './learning/home-tuition/home-tuition.module#HomeTuitionModule' },
    { path: '**', loadChildren: './learning/not-found/not-found.module#NotFoundModule'},
    // { path: 'find-tutors', loadChildren: './learning/findtutors/find-tutors.module#FindTutorsModule' }
    
    // WhyStudentsModule,
    // GetOnlineTuitionModule,
    // OnlineTuitionAnytimeModule,
    // BestOnlineHomeTuitionModule,
    // OnlineHomeTuitionsForAllSubjectsModule,


];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes, { useHash: false }
        )
    ],
    exports: [
        RouterModule
    ],
    providers: [
        PropertyGuard

    ]

})
export class AppRoutingModule {

}
