<div class="col-md-12">

  <div class="popup-form">

    <div class="popup-form-inner">

        <div class="popup-form-heading">

            <h2>Reset Password</h2>
  
            <span>Please check your mail account for otp.</span>
            <span>Please enter a new password for your account</span>
  
          </div>
       
      <form id="Login" [formGroup]="resetForm" (ngSubmit)="submit()">
          <div class="form-group">
            <div class="mat-app-background basic-container">
              <mat-form-field appearance="fill" class="mat-form-custom text-fiels-popup">
              <input matInput type="text" [formControl]="otp" [maxLength]="CONFIG.MAXOTP_LENGTH" placeholder="****" [minLength]="CONFIG.MINOTP_LENGTH"
              appOnlyNumber>
  
            </mat-form-field>
            <mat-error>{{_util.errorMessage(otp,FORM_ERROR?.otp)}}</mat-error>
            </div>
          </div>
        <div class="form-group">
          <div class="mat-app-background basic-container">
            <mat-form-field appearance="fill" class="mat-form-custom text-fiels-popup">
            <input matInput name="password" placeholder="New Password" type="password" [matTooltip]="FORM_ERROR?.password?.pattern"
              [formControl]="password" [maxlength]="CONFIG?.PASSWORD_LENGTH">

          </mat-form-field>

          <mat-error>{{_util.errorMessage(password,FORM_ERROR?.password)}}</mat-error>
        </div>
        </div>
        <div class="form-group">
          <div class="mat-app-background basic-container">
            <mat-form-field appearance="fill" class="mat-form-custom text-fiels-popup">
            <input matInput placeholder="Confirm Password" name="confirmPassword" type="password"
              [matTooltip]="FORM_ERROR?.password?.pattern" [formControl]="confirmPassword" [maxlength]="CONFIG?.PASSWORD_LENGTH">
            
          </mat-form-field>

          <mat-error *ngIf="!resetForm.hasError('notSame')">{{_util.errorMessage(confirmPassword,FORM_ERROR?.confirmPassword)}}</mat-error>
          <mat-error class="mat-error ng-star-inserted" *ngIf="resetForm.hasError('notSame')">
            Password and confirm password does not match
          </mat-error>
        </div>
        </div>
       

        <button type="submit" [disabled]="disabled" class="btn btn-reola">Submit</button>
       
       


      
      </form>
      <div class="reola-color back-modal-text">
        <div class="row">
            <div class="col-md-12">

                <a (click)="login()" class="hrefclass befault-color">Back To Sign In</a>

            </div>

        </div>

    </div>
    </div>

  </div>
</div>