import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-delhi',
  templateUrl: './delhi.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class DelhiComponent implements OnInit {
   websiteSchema =
    
     {
		'@context' : 'https://schema.org' ,
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What all things do I need while taking online home tuition in Delhi?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You need to have the following things: a) A laptop, computer, or a smartphone b) An internet connection c) A microphone and speakers (they are in-built features), otherwise you can also use a headsetd) Writing pad"
    }
  },{
    "@type": "Question",
    "name": "How often does my child need to take the classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The thorough requirement of classes depends upon schoolchildren and the course. But we suggest taking at least 5-6 hours online tuition in Delhi per week. Taking regular classes helps a lot in clearing all the doubts as compared to taking classes on an occasional basis. On average, most of the students of Ziyyara take 2-3 hours classes per week per subject, which can increase when the exams approach."
    }
  },{
    "@type": "Question",
    "name": "Are taking online home tuition in Delhi effective compared to taking offline classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, in fact, online tuition in Delhi is much more effective as in-person lessons as it helps save a lot of communicating time. Most of the students who have taken both in-person and online home tuition in Delhi prefer the online lessons the most as it allows them to focus on their subjects and let them know their stronger and weaker sections."
    }
  },{
    "@type": "Question",
    "name": "What is the benefit of taking lessons from Ziyyara’s online tutor for Delhi?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The experts offering online lessons to the students of Ziyyara are highly experienced in their subjects. Online lessons are more flexible as compared to other forms of tutoring in Delhi. At present a lot of students have enrolled at our platform who are taking regular classes from us."
    }
  },{
    "@type": "Question",
    "name": "How does online tuition work?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Online tuition works almost exactly the same as an ordinary tuition lesson; the only difference is that you need an internet connection and a laptop or a smartphone. We provide audio-video classes, which makes the concepts more engaging and certainly more productive. All our home tutors in Delhi use whiteboard tools to aid students understand lessons easily and accurately."
    }
  }]
}
	
  
cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
    this.titleService.setTitle( "Online Home Tuition in Delhi | Tutors in Delhi | Tuition in Delhi");
   var desc=" Seeking a best online home tuition in Delhi? Try Ziyyara, we offer one-to-one live online tuition with the help of the best home tutors in Delhi to teach online."
    this.meta.updateTag({ name: 'description', content: desc })
   this.meta.updateTag({ name:"keywords",content:"delhi home tuition, delhi home tutors, home tuition for delhi, home tuition in delhi, home tutor in delhi, home tutors in delhi, online tuition in delhi, tuition in delhi, tuition online in delhi, tutor in delhi, tutors in delhi"})
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
  }
 

}
