<app-header-front></app-header-front>

<div class="padd_top"></div>

<div class="main-section">
    <div class="flex">
        <div class=" first-part">
            <div class="hero-content">
                <img class="img-bg" src="/assets/images/images/signup.png">

            </div>
            <!-- <div class="img-bg"></div> -->
        </div>
        <div class="second-part">
            <div class="reg-part">
                <div class="popup-form-inner">
                    <div class="popup-form-role">
                        <a routerLink="/studentSignup" class="active"> Student </a>
                        <a routerLink="/teacherSignup" class="inactive"> Teacher </a>
                    </div>
                    <div class="popup-form-heading">
                        <div class="d-flex align-items-center justify-content-between">
                            <h5>{{"signup.register" | mytranslate}} As Student</h5>
                            <!-- <span (click)="no()">
              <i class="fa fa-close color-primary cursor-pointer"></i>
            </span>  -->
                        </div>
                        <!-- <span>Please  to your Ziyyara Account</span> -->
                    </div>


                    <!-- <form ngNoForm class="reg-form" action="https://postmail.invotes.com/send" method="post" id="email_form"> -->
                    <form id="Login" class="reg-form" [formGroup]="signupForm" (ngSubmit)="submit()">


                        <!-- <div class="hidden" style="display:none;">
                        <input type="text" name="subject" placeholder="Subject" value="New Lead from Ziyyara.in" />
                        <input name="text" placeholder="Message" value="Hi Admin"/>
                      </div> -->

                        <div class="form-group">
                            <div class="mat-app-background basic-container">
                                <!-- <mat-form-field appearance="fill" class="mat-form-custom padding-10px">
                                        <mat-label>{{"signup.ful_name" | mytranslate}}</mat-label>
                                   
                                        <input matInput type="text" placeholder="" name="extra_name" required>
                                    </mat-form-field>
                                    <mat-error>{{_util.errorMessage(fullName,FORM_ERROR?.fullName)}}</mat-error> -->

                                <mat-form-field appearance="fill" class="mat-form-custom padding-10px">
                                    <mat-label>{{"signup.ful_name" | mytranslate}}</mat-label>
                                    <!-- <input matInput nfNoSpaces type="text" placeholder=""` [formControl]="fullName"
                      [maxlength]="CONFIG?.NAME_LENGTH"> -->
                                    <input matInput type="text" placeholder="" [formControl]="fullName" [maxlength]="CONFIG?.NAME_LENGTH">
                                </mat-form-field>
                                <mat-error>{{_util.errorMessage(fullName,FORM_ERROR?.fullName)}}</mat-error>
                            </div>
                        </div>


                        <div class="form-group">
                            <div class="mat-app-background basic-container">
                                <!-- <mat-form-field appearance="fill" class="mat-form-custom padding-10px">
                                        <mat-label>{{"signup.email" | mytranslate}}</mat-label>
                                        <input matInput type="email" name="extra_email">
                                    </mat-form-field>
                                    <mat-error>{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error> -->

                                <mat-form-field appearance="fill" class="mat-form-custom padding-10px">
                                    <mat-label>{{"signup.email" | mytranslate}}</mat-label>
                                    <input matInput type="email" [formControl]="email" placeholder="" [maxlength]="CONFIG?.EMAIL_LENGTH">
                                </mat-form-field>
                                <mat-error>{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="mat-app-background basic-container">
                                <!-- <mat-form-field appearance="fill" class="mat-form-custom padding-10px">
                                        <mat-label>{{"signup.countryCode" | mytranslate}}</mat-label>
                                        <input matInput type="text" name="extra_country"  placeholder="" value="{{countryCode}} {{country}}" readonly>
                                    </mat-form-field> -->

                                <!-- <mat-form-field appearance="fill" autocomplete='off' class="mat-form-custom padding-10px">
                                        <mat-label>Country Code </mat-label>
                                        <mat-select placeholder="search country" [formControl]="countryCode" #singleSelect>
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="bankFilterCtrl" [placeholderLabel]="placeholderLabel" [noEntriesFoundLabel]="noEntriesFoundLabel"></ngx-mat-select-search>
                                            </mat-option>
    
                                            <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank">
                                                {{bank.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field> -->
                                <input type="text" value="{{countryCode}} {{country}}" class="form-control repo-input" readonly/>




                                <!-- <mat-error> {{_util.errorMessage(countryCode,FORM_ERROR?.countryCode)}}</mat-error> -->
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="mat-app-background basic-container">
                                <!-- <mat-form-field appearance="fill" autocomplete='disabled' class="mat-form-custom padding-10px">
                                        <mat-label>Mobile no</mat-label>
                                        <input matInput appOnlyNumber name="extra_phone" required>
                                    </mat-form-field>
                                    <mat-error> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error> -->
                                <mat-form-field appearance="fill" autocomplete='disabled' class="mat-form-custom padding-10px">
                                    <mat-label>Mobile no</mat-label>
                                    <input matInput appOnlyNumber [formControl]="contactNo" [maxlength]="CONFIG?.MOBILE_LENGTH">
                                </mat-form-field>
                                <mat-error> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error>
                            </div>
                        </div>

                        <!-- <input type="hidden" name="access_token" value="f0jlhsajx91ujqi3nz2mbzng" /> -->
                        <!-- return urls can be fully qualified -OR-
                         start with / for root relative -OR-
                         start with . for url relative -->
                        <!-- <input type="hidden" name="success_url" value=".?message=Email+Successfully+Sent%21&isError=0" /> -->
                        <!-- <input type="hidden" name="error_url" value=".?message=Email+could+not+be+sent.&isError=1" /> -->

                        <div class="mb-2">
                            <!-- <input type="submit"  class="btn m-0 btn-ziyyara" id="submit_form"  value="Register" > -->
                            <button type="submit" [disabled]="disabled" class="btn m-0 btn-ziyyara">{{"signup.register" | mytranslate}}</button>



                        </div>
                    </form>

                    <p class="text-center pd-22"><span class="reola-tc-size-bold">{{"signup.already_registered" | mytranslate}} </span>&nbsp; <a class="hrefclass befault-color" routerLink="/login">{{"signup.login_title" | mytranslate}}
          </a></p>








                </div>
            </div>
        </div>
    </div>


</div>



<app-footer-front></app-footer-front>