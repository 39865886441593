import { Component, OnInit, Inject } from '@angular/core';
     

@Component({
  selector: 'app-why-students',
  templateUrl: './why-students.component.html',
  styleUrls: ['./why-students.component.css']
})
export class WhyStudentsComponent implements OnInit {

  constructor( ) { }

  ngOnInit() {
       
  }

}
