import { Component, OnInit, Inject } from '@angular/core';
import { AppService } from 'src/app/services';
import { AppStateService } from 'src/app/services/app-state.service';
import { CommonUtil } from 'src/app/util';
import { CONFIG } from 'src/app/constants';
import { PopupMessage } from '../../../interfaces';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { } from '@angular/forms';
import { Router } from "@angular/router";

import { HttpService } from '../../../services';


import { Title } from '@angular/platform-browser';
import { SelectService } from '../../../util/select.service';
import { Country } from '../../../util/country';
import { State } from '../../../util/state';
@Component({
  selector: 'app-group-class-modal',
  templateUrl: './group-class-modal.component.html',
  styleUrls: ['./group-class-modal.component.css'],
  providers:[SelectService]
})
export class GroupClassModalComponent implements OnInit {

 
  CONFIG = CONFIG;
  disabled=false;
  constructor(public dialogRef: MatDialogRef<GroupClassModalComponent>,
    public util: CommonUtil, private appState: AppStateService, private appSer: AppService,
    @Inject(MAT_DIALOG_DATA) public data: PopupMessage,private form: FormBuilder,
    private httpService: HttpService,
    private router: Router,
   
   
    private selectService: SelectService,
    public _util: CommonUtil) {
      this.initializeForm()
  }
ngOnInit(){
 ////console.log(this.data) 
}
 
  selectedCountry: Country = new Country(81, 'India');
  countries: Country[];
  states: State[];
  stateName:any;
  countryName:any;

 
 

  contactForm: FormGroup;
  // initiazing the setting up the contact form.
  initializeForm() {
    
    this.contactForm = this.form.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      country: ['', [Validators.required]],
      gender: ['', null],
      // query: ['', [Validators.required]],
      profession: ['', [Validators.required]],
      state: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      platform:['com']
    })
  }

  
  submitContactForm() {
    this.contactForm.controls['platform'].patchValue('com');
    if (this.contactForm.valid) {
      this.disabled=true;
      const formData = this.contactForm.value;
      formData.country=this.countryName;
      formData.state=this.stateName;
      ////////console.log("contact form values :--- " , formData)

      this.httpService.contactUs('contact', formData)
        .subscribe(
          res => {
            this.appSer.addSnackBar({ message: "Thank You, We will contact you soon." });
            this.router.navigate(['/']);
            
          },
          err => { this.appSer.addSnackBar({ message: "Unable to send feedback." }) },
            ()=>{this.disabled=false}
          
          )

    }
    else {
      this.appSer.addSnackBar({ message: "Please Complete The Form" });
    }

  }

  onSelect(countryid) {
   
   var temp=  this.countries.filter((item)=> item.id==countryid)
   
  this.countryName=temp[0].name
   
    this.states = this.selectService.getStates().filter((item) => item.countryid == countryid);
  }

  onSelectState(statename){
    if(this.contactForm.get('state').value>0){
      var selctedStates = this.selectService.getStates().filter((item) => item.id == this.contactForm.get('state').value && item.countryid==this.contactForm.get('country').value);
      ////////console.log(selctedStates);
      ////////console.log(selctedStates[0].name);


      this.stateName=selctedStates[0].name;

    }
 }

  no() {
    this.dialogRef.close(false);
  }
}

 








  

