

export const Regex = {
    phoneNumbers: new RegExp('^[1-9][0-9]*$'),
    description: new RegExp(/^(.|\s)*[a-zA-Z]+(.|\s)*$/, 'i'),
    coins: new RegExp('(\\d+)(\\.)?(\\d+)?'),
    // tslint:disable-next-line:max-line-length
    email: new RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/),
    webUrl: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,

    // password: new RegExp(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/),
  //  accept any kind of password
    password:  new RegExp(/^.{8,}$/),
    spaces: new RegExp(/^\S+$/),
    // spacecharacter: new RegExp(/^[a-zA-Z_ ]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/),

//     Special Characters & digits Are Not Allowed.
// Spaces are only allowed between two words.
// Only one space is allowed between two words.
// Spaces at the start or at the end are consider to be invalid.
// Single word name is also valid : ^[a-zA-z]+([\s][a-zA-Z]+)*$
// Single word name and double name is also valid : /^[a-zA-Z][a-zA-Z ]*$/        // we are using this for full name
// Single word name is in-valid : ^[a-zA-z]+([\s][a-zA-Z]+)+$
//this spacecharacter1 is used for only reset password and regenerate the password 
spacecharacter1 :new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/),
//end spacecharacter1 //

    spacecharacter :new RegExp(/^[a-zA-Z][a-zA-Z ]*$/),

    avoidOnlySpaceCharaceter: new RegExp('[a-zA-Z0-9]+[a-zA-Z0-9 ]+')
    
};


