<app-header-front>
</app-header-front>

<div class="how-it-works">

    <section id="title" class="light" style="padding: 10px;">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="pagetitle">
                        <h2> What is Ziyyara?</h2>


                    </div>

                    <div class="_par">

                        <p>Ziyyara is an online tutoring platform where students and teachers connect regardless of time and location. We provide one-to-one live classes that make the most of a need-based approach allowing parents and students to plan their
                            schedule as per their needs and convenience. We also ensure to conduct periodic assessments of all our students and at the same time provide in-depth feedback to their respective parents or guardians.</p><br>
                        <p class="_npar"> <i>At Ziyyara, we offer state-of the-art learning and teaching tools and techniques, including but not limited to webcast, podcast, videos, audio recordings, and whiteboards. So, students do not have to install any costly application software at their end for attending online classes. Our USP is that we network with exceptionally qualified and experienced tutors from all over the world who offer instructions customized around the individual requirements and preferences of every student.</i>                            </p>
                        <p> Following are some of the salient features of our web-based or online classes: -</p><br>
                        <ul>
                            <li> We provide one-to-one live classes where every single student has the undivided attention of a specific tutor</li>
                            <li> Pay as you go </li>
                            <li> The option of booking classes from anywhere in the world at any time of the day or night</li>
                            <li> Registering for a live class is as easy and simple as booking a cinema ticket</li>
                            <li> Built-in audio-visual tools together with whiteboards</li>
                            <li> One can start attending classes directly after logging in with his or her distinct e-mail id and password: No need to download any software, app or link for registering presence in the classroom</li>
                            <li> We provide recordings of classes attended by our registered students </li>

                        </ul>

                    </div>

                </div>
            </div>
        </div>

    </section>




</div>




<app-footer-front></app-footer-front>