import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-political',
  templateUrl: './political.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class PoliticalComponent implements OnInit {
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How do you assure I get personalized guidance?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We give you one to one online classes that are personalized according to your requirements and availability. Also, we will give you time versatility in our Political Science home tuition, so that you can take up your online classes according to your time preference. We will produce study materials as per your needs to eradicate your weak points in a subject."
    }
  },{
    "@type": "Question",
    "name": "Will I get a demo class before starting home tuition for Political Science?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, once we have assigned a Political Science tutor for your kid, we will provide a one-hour free demo class at your preferred time. If you like the demo classes and if you want to proceed with the same teacher then you just have to recharge your wallet to start the class."
    }
  },{
    "@type": "Question",
    "name": "Do I need to pay anything to register as a student?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "No. There is no fee at Ziyyara for registering and getting listed as a student."
    }
  }]
}

ngOnInit() {
  //slider
this.slides = this.chunk(this.cards, 3);
this.titleService.setTitle("Get Online Tuition for Political Science | Pol. Science Tuition Online");
var desc=" Ziyyara offers one-on-one live online tuition for political science. We offer pol. science online tuition classes with the help of experienced tutors. For a free demo call us on +91 9654271931 ";
this.meta.updateTag({ name: 'description', content: desc })
var keywords="political science tutor, home tuition for political, online poltical tuition, , Tutor for Political Science, Political tutor, Political Science online tutor, Political home tuition, Political online tuition, Political Science home tuition, Political Science online tuition, tuition for Political, Home tuition for Political Science, online tuition Political Science";
this.meta.updateTag({ name: 'keywords', content: keywords });
var url=  location.pathname;


var ogtitle=" Get Online Tuition for Political Science | Pol. Science Tuition Online";
this.meta.updateTag({ property: 'og:title', content: ogtitle });
var ogdesc="Ziyyara offers one-on-one live online tuition for political science. We offer pol. science online tuition classes with the help of experienced tutors. For a free demo call us on +91 9654271931 ";
this.meta.updateTag({ property:'og:description', content: ogdesc });

var twittertitle="Get Online Tuition for Political Science | Pol. Science Tuition Online";
this.meta.updateTag({ name:"twitter:title", content: twittertitle });
var twitterdesc="Ziyyara offers one-on-one live online tuition for political science. We offer pol. science online tuition classes with the help of experienced tutors. For a free demo call us on +91 9654271931 ";
this.meta.updateTag({ name:'twitter:description', content: twitterdesc });    
this.meta_service.createCanonicalURL(url);
this.meta_service.removeStructuredData();
this.meta_service.insertSchema(this.websiteSchema);

}

 cards = [
  {
    title: 'Economics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Economics.jpg'
  },
  {
    title: 'Maths',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Mathematics.jpg'
  },
  {
    title: 'English',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/English.jpg'
  },
  {
    title: 'Physics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Physics.jpg'
  },
  {
    title: 'History',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/History.jpg'
  },
  {
    title: 'Hindi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Hindi.jpg'
  },
  {
    title: 'Geography',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Geography.jpg'
  },
  {
    title: 'Business Studies',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Business-Studies.jpg'
  },
  {
    title: 'Biology',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Biology.jpg'
  },
  // {
  //   title: 'Political-Science',
  //  // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
  //   buttonText: 'Read',
  //   img: '/assets/images/Political-Science.jpg'
  // },
  {
    title: 'Accountancy',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Accountancy.jpg'
  },
  {
    title: 'Chemistry',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Chemistry.jpg'
  },

  {
    title: 'Computer Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Computer-Science.jpg'
  },
  {
    title: 'Vedic Mathematics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/vedic-math.webp'
  },
];





slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}

generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  if(subject.includes("vedic"))
  
  {
    return "/vedic-math-workshop"
  
  } 
    else if (subject.includes("computer")){
      return '/home-tuition/online-home-tuition-for-computer-science'
    }
    else if (subject.includes("business")){
      return '/home-tuition/online-home-tuition-for-business-studies'
    }
  else {

    return '/home-tuition/online-home-tuition-for-'+subject
  }

}
}
