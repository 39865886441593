<!-- header  -->
<div class="ontop">
    <div class="numImg-1">
        <a href="https://wa.me/9654271931">
            <img src="assets/newziyyara/india.png" alt="indian flag">
            <p>+91-9654271931</p>
        </a>
    </div>
    <!-- <div class="numImg-2">
        <a href="tel:+96871912179">
            <img src="assets/newziyyara/oman.png" alt="">
            <p>+968-71912179</p>
        </a>
    </div> -->
    <div class="free-demo">
        <a href="/free-registration">REQUEST A FREE DEMO</a>
    </div>
</div>
<nav id="mynav" class="navbar navbar-expand-lg navbar-light my-nav-col">
    <a class="navbar-brand" href="/"><img class="main-logo" src="assets/newziyyara/ziyyaralogo.webp" alt="ziyyara logo"></a>
    <!-- <a style="margin:-15px;" class="navbar-brand" href="/"><img class="main-logo" src="assets/newziyyara/newchristmaslogorleftcap.png" alt=""></a> -->
    <button (click)="toggleNavbarCollapse()" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>



    <!-- diwali starts  -->

    <!-- <div class="diya">
      <img src="diya.png" alt="">
      <div class="diya-circle"></div>
  </div>
  <div class="diya" style="left: 245px;">
    <img src="diya.png" alt="">
    <div class="diya-circle"></div>
  </div>
  <div class="diya" style="left: 290px;">
    <img src="diya.png" alt="">
    <div class="diya-circle"></div>
  </div>
  <div class="diya" style="left: 335px;">
    <img src="diya.png" alt="">
    <div class="diya-circle"></div>
  </div>
  <div class="diya" style="left: 380px;">
    <img src="diya.png" alt="">
    <div class="diya-circle"></div>
  </div> -->

    <!-- diwali ends  -->


    <div [ngClass]="{ collapse: navbarCollapsed }" class="collapse navbar-collapse justifyend" id="navbarSupportedContent">
        <ul class="navbar-nav for-acnhor">

            <li class="nav-item">
                <a class="nav-link" href="/home-tuition"><i class="fas fa-book"></i>Online Home Tuition</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/packages"><i class="fas fa-home"></i>Packages <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/how-it-works"><i class="fas fa-clipboard-list"></i>How it Works</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/about-us"><i class="fas fa-address-card"></i>About Us</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/teacherSignup"><i class="fas fa-chalkboard-teacher"></i>Teacher Signup</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/studentSignup"><i class="fas fa-user-graduate"></i>Student Signup</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/login"><i class="fas fa-user-circle"></i>Login</a>
            </li>
            <!-- <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li> -->
            <!-- <li class="nav-item">
        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
      </li> -->
        </ul>
        <!-- <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form> -->
    </div>
</nav>








<!-- <nav class="navbar navbar-expand-lg navbar-dark shadow-sm p-3 mb-5 rounded navbar-fixed-top" style="padding: 0.5rem !important;
margin-bottom: .5rem !important; border-radius: 4px;
 padding: 05px;">

    <button (click)="toggleNavbarCollapse()" class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon">🍔</span>
      <span class="icon-bar"></span> 
    </button>
    <div [ngClass]="{ collapse: navbarCollapsed }" class="navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <a class="nav-links" style="font-size: 16px;" routerLink="">Special Discount <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-links" style="font-size: 16px;" routerLink="Products">Products on Discount</a>
            </li>
        </ul>
    </div>
</nav> -->