import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-chemistry',
  templateUrl: './chemistry.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class ChemistryComponent implements OnInit {

  websiteSchema={
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Is chemistry a difficult subject?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "It is not. You just have to build an interest in the subject. With proper guidance from an organic chemistry tutor and hard work, this subject can be really easy."
      }
    },{
      "@type": "Question",
      "name": "Can I ask several questions to our chemistry online tutor at once?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Definitely, we urge students to ask as many problems as they prefer to expand their knowledge. All problems can be posted at once for convenient solutions from the chemistry tutor."
      }
    },{
      "@type": "Question",
      "name": "How soon can I get a resolution of doubts?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our chemistry tutors appreciate the significance of submitting answers as soon as possible. All our online chemistry home tutors are very careful about up-to-date submissions. Therefore, we ensure the submission of solutions the next day."
      }
    },{
      "@type": "Question",
      "name": "How do you assure best results in studies?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Ziyyara is all about big intentions, focusing on passion and hard work into academics so that our students get the best they deserve! All of our tutors for chemistry are knowledgeable, friendly, and are personally examined by our team to assure they are well motivated and strong communicators."
      }
    },{
      "@type": "Question",
      "name": "How can I follow the improvement of my son/daughter?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Parents will be notified about their kid's progress through assignments, tests, and scorecards. Monthly online PTA meetings will be programmed with the parents to update them on their child's improvement. The parents can ask to have a meeting with the concerned chemistry online tutor."
      }
    }]
  }

  cards = [
    {
      title: 'Economics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Economics.jpg'
    },
    {
      title: 'Maths',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Mathematics.jpg'
    },
    {
      title: 'English',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/English.jpg'
    },
    {
      title: 'Physics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Physics.jpg'
    },
    {
      title: 'History',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/History.jpg'
    },
    {
      title: 'Hindi',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Hindi.jpg'
    },
    {
      title: 'Geography',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Geography.jpg'
    },
    {
      title: 'Business Studies',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Business-Studies.jpg'
    },
    {
      title: 'Biology',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Biology.jpg'
    },
    {
      title: 'Political-Science',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Political-Science.jpg'
    },
    {
      title: 'Accountancy',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Accountancy.jpg'
    },
    // {
    //   title: 'Chemistry',
    //  // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    //   buttonText: 'Read',
    //   img: '/assets/images/Chemistry.jpg'
    // },

    {
      title: 'Computer Science',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/Computer-Science.jpg'
    },
    {
      title: 'Vedic Mathematics',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/vedic-math.webp'
    },
  ];
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
    constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }
    ngOnInit() {
      //slider
 this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle("Best Online Tuition for Chemistry | Chemistry Tuition Classes");
   var desc="et the best online tuition for Chemistry. Ziyyara provides online Chemistry tuition classes at the comfort of your home. For a free demo call us on +91 9654271931"
    this.meta.updateTag({ name: 'description', content: desc })
    var keywords="chemistry at home, chemistry home tuition, chemistry home tutor, chemistry online tutor, chemistry tuition, chemistry tuition near me, chemistry tutor, home tuition for chemistry, online chemistry tutor, physics and maths tutor chemistry, tutor for chemistry";
    this.meta.updateTag({ name: 'keywords', content: keywords });
    var url=  location.pathname;

    var ogtitle="Best Online Tuition for Chemistry | Chemistry Tuition Classes";
    this.meta.updateTag({ property: 'og:title', content: ogtitle });
   var ogdesc="et the best online tuition for Chemistry. Ziyyara provides online Chemistry tuition classes at the comfort of your home. For a free demo call us on +91 9654271931";
   this.meta.updateTag({ property:'og:description', content: ogdesc });
 
   var twittertitle="Best Online Tuition for Chemistry | Chemistry Tuition Classes";
   this.meta.updateTag({ name:"twitter:title", content: twittertitle });
  var twitterdesc="Get the best online tuition for English. Ziyyara provides online English tuition classes at the comfort of your home. For a free demo call us on +91 9654271931 ";
  this.meta.updateTag({ name:'twitter:description', content: twitterdesc }); 
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
   }
     generateSubjectLink(sub:string){
      var subject=sub.toString().toLowerCase()
      if(subject.includes("vedic"))
      {
        return "/vedic-math-workshop"
      } 
        else if (subject.includes("computer")){
          return '/home-tuition/online-home-tuition-for-computer-science'
        }
        else if (subject.includes("business")){
          return '/home-tuition/online-home-tuition-for-business-studies'
        }
      else {
  
        return '/home-tuition/online-home-tuition-for-'+subject
      }
    
    }
}
