import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-andaman-nicobar',
  templateUrl: './andaman-nicobar.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class AndamanNicobarComponent implements OnInit {
  websiteSchema ={
    
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Who are your tutors in Andaman Nicobar?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The team of home tutors in Andaman Nicobar we have comes from a variety of backgrounds. Some of them are former and present teachers, professionals, etc. having specialization in their own disciplines, and others who may have specific expertise in the subject area. No matter what educational qualifications our teachers have, all of them are experts in their respective academic subjects and all are dedicated when its about helping students in getting a good score in their academics."
      }
    },{
      "@type": "Question",
      "name": "What are the charges of online tuition classes?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The prices of Andaman Nicobar home tutoring vary based on the age of student, subject chosen, and any special requirements which may be requested by the family. There are several discounted packages offered to accommodate the needs of the family. To learn more about the same, you can get in touch with the experts of Ziyyara."
      }
    },{
      "@type": "Question",
      "name": "As a student, what can my child expect from your online tutoring sessions?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Tutors in Andaman Nicobar offering online classes will help you in better understanding of the subject where you need assistance. Our tutors will create a customized schedule that works best to fit your needs and learning style."
      }
    },{
      "@type": "Question",
      "name": "Can I reschedule my tutoring session?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "At Ziyyara, we completely understand your priority, thus allowing you to reschedule your sessions, sometimes. For the same, you are supposed to inform us. Though we don't allow direct communication between a parent & tutor, however a student can inform the teacher while taking the class about rescheduling. Both parents & tutors have a responsibility to inform our coordinating team, so that we can manage the classes on our platform."
      }
    }]
  }

  cards = [
    {
      title: 'Ahmedabad',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
    },
  
    {
      title: ' Andaman Nicobar',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
    },
    {
      title: 'Arunanchal Pradesh',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
    },
    {
      title: 'Assam',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-assam.webp'
    },
    {
      title: 'Banglore',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-banglore.webp'
    },
    {
       title: 'Chandigarh',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
    },
    {
      title: 'Chennai',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-chennai.webp'
    },
    {
      title: 'Coimbatore',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
    },
    {
      title: 'Delhi',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-delhi.webp'
    },
    {
      title: 'Gujrat',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-gujrat.webp'
    },
    {
      title: 'Gurugram',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-gurugram.webp'
    },
    {
      title: 'Hyderabad',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
    },
    {
      title: 'Jaipur',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-jaipur.webp'
    },
    {
      title: 'Karnataka',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-karnataka.webp'
    },
    {
      title: 'Kerala',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-kerala.webp'
    },
    {
      title: 'Kolkata',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-kolkata.webp'
    },
    {
      title: 'Lucknow',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-lucknow.webp'
    },
    {
      title: 'Mumbai',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-mumbai.webp'
    },
    {
      title: 'Noida',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-noida.webp'
    },
    {
      title: 'Pune',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-pune.webp'
    },
    {
      title: 'Ranchi',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-ranchi.webp'
    },
  
  ];
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
   
  generateSubjectLink(sub:string){
  
    var subject=sub.toString().toLowerCase()
     if(subject.includes("andaman")){
      return '/home-tuition/online-home-tuition-in-andaman-nicobar'
     }
else if(subject.includes("arunanchal")){
      return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
     }

     else {return '/home-tuition/online-home-tuition-in-'+subject }
   
      
      
  }
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
    this.titleService.setTitle( "Online Home Tuition In Andaman Nicobar | Tutors in Andman Nicobar");
     var desc=" Ziyyara has the best online home tutors in Andaman Nicobar and provides one-on-one live online home tuition in Andaman Nicobar for the students of class 5-12 of all boards."
    this.meta.updateTag({ name: 'description', content: desc })
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta.updateTag({ name: 'keywords', content: "home tuition in Andman Nicobar, Andman Nicobar home tuition, home tutors in Andman Nicobar, home tutor in Andman Nicobar, home tuition in Andman Nicobar, home tuition for Andman Nicobar, tuition in Andman Nicobar, Andman Nicobar home tuition, Online tuition in Andman Nicobar, tuition online in Andman Nicobar, Andman Nicobar home tuition, Andman Nicobar online tuition, online home tuition in Andman Nicobar, home online tuition in Andman Nicobar, online home tuition for Andman Nicobar"})
    this.meta_service.insertSchema(this.websiteSchema)
 
  }
}
