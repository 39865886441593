import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-hindi',
  templateUrl: './hindi.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class HindiComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How many students can attend an online Hindi tuition session?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara's tutoring service allows one-to-one sessions which means only one student at a time to attend the session."
    }
  },{
    "@type": "Question",
    "name": "Is online home tuition for Hindi different from face-to-face classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Unlike face-to-face training, you need not to move away from the convenience of your home. Online tutoring is arranged on the internet and provides full attention. It is a one-on-one session with a skilled Hindi online tutor for the student."
    }
  },{
    "@type": "Question",
    "name": "Can I take Hindi tuition online from wherever I reside?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, you can attend online tutoring classes from anywhere you want as long as you have a good Internet connection and a PC."
    }
  }]
}
cards = [
  {
    title: 'Economics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Economics.jpg'
  },
  {
    title: 'Maths',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Mathematics.jpg'
  },
  {
    title: 'English',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/English.jpg'
  },
  {
    title: 'Physics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Physics.jpg'
  },
  {
    title: 'History',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/History.jpg'
  },
  {
    title: 'Hindi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Hindi.jpg'
  },
  {
    title: 'Geography',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Geography.jpg'
  },
  {
    title: 'Business Studies',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Business-Studies.jpg'
  },
  {
    title: 'Biology',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Biology.jpg'
  },
  {
    title: 'Political-Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Political-Science.jpg'
  },
  {
    title: 'Accountancy',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Accountancy.jpg'
  },
  {
    title: 'Chemistry',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Chemistry.jpg'
  },

  {
    title: 'Computer Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Computer-Science.jpg'
  },
  {
    title: 'Vedic Mathematics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/vedic-math.webp'
  },
];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    //slider
 this.slides = this.chunk(this.cards, 3);
  this.titleService.setTitle(" Online Tuition for Hindi | Online Hindi Tuition Classes");
 var desc=" Looking for online hindi tuition classes? Try Ziyyara’s one-on-one live online tuition for hindi from experienced tutors. For a free demo call us on +91 9654271931. "
  this.meta.updateTag({ name: 'description', content: desc })
  var keywords="hindi tuition, tuition in hindi, hindi tutor, tutor in hindi, online hindi tutor, hindi tutor online, hindi tuition online, learn hindi online, Tutor for hindi, tutors for hindi, hindi tutors, hindi online tutor, , Online tuition for hindi, online hindi tuition, hindi home tuition, hindi online tuition, hindi tuition at home    ";
  this.meta.updateTag({ name: 'keywords', content: keywords });
  var url=  location.pathname;

  
  var ogtitle="Online Tuition for Hindi | Online Hindi Tuition Classes";
  this.meta.updateTag({ property: 'og:title', content: ogtitle });
 var ogdesc="  Looking for online hindi tuition classes? Try Ziyyara’s one-on-one live online tuition for hindi from experienced tutors. For a free demo call us on +91 9654271931.";
 this.meta.updateTag({ property:'og:description', content: ogdesc });

 var twittertitle="Online Tuition for Hindi | Online Hindi Tuition Classes";
 this.meta.updateTag({ name:"twitter:title", content: twittertitle });
var twitterdesc="   Looking for online hindi tuition classes? Try Ziyyara’s one-on-one live online tuition for hindi from experienced tutors. For a free demo call us on +91 9654271931. ";
this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
  this.meta_service.createCanonicalURL(url);
  this.meta_service.removeStructuredData();
  this.meta_service.insertSchema(this.websiteSchema);

}
 generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  if(subject.includes("vedic"))
  
  {
    return "/vedic-math-workshop"
  
  } 
    else if (subject.includes("computer")){
      return '/home-tuition/online-home-tuition-for-computer-science'
    }
    else if (subject.includes("business")){
      return '/home-tuition/online-home-tuition-for-business-studies'
    }
  else {

    return '/home-tuition/online-home-tuition-for-'+subject
  }

}
}
