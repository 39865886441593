<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">&nbsp;</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="assets/images/Green-Round-Trick.png" alt="">
            <h1>You are almost set!</h1>
            <p>Thanks for submitting the form, please Signup to enjoy the benefits of 1 free tuition class</p>
            <!-- <h3 class="cupon-pop">Your Id: <span>12345</span></h3> -->

        </div>
        <button type="button" class="btnRegister2" (click)="signup(template)">Sign Up</button>
    </div>

</ng-template>

<!-- <section>

    <div class="subject_banner subject_article">
        <div class="register-section">
            <h2> Get Online 1-on-1 LIVE Tuition {{tailUrl}} </h2>


            <h6>Class 1-12 IB | ICSE | CBSE | IGCSE | GCSE | PHYSICS, MATHS, CHEMISTRY, ENGLISH etc </h6>

            <div class="row">


                <div class="col-md-6 sec sep">
                    <div class="sect-div">
                        <img src="assets/images/call-icon.webp" alt="" width="81" height="81">
                        <span class="greentext">Call our </span>
                        <span> Expert  </span>
                        <a href="tel:9654271931" style="color:#fff;">+91-9654271931</a>


                    </div>

                    <div class="sect-div">
                        <img src="assets/images/whatsapp-icon.webp" alt="" width="81" height="81">
                        <span class="greentext">Whatsapp Us </span>
                        <a class="btnRegisterBtn" href="https://api.whatsapp.com/send?phone=919654271931">Send Message</a>
                    </div>

                </div>

                <div class="col-md-6  sec sep nobr">
                    <h2> Book a Free Demo Now!</h2>
                    <form [formGroup]="signupForm" class="reg-form" autocomplete="off" (ngSubmit)="submitUser()">

                        <div class="row register-form">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Student Name" [formControl]="name" [maxlength]="CONFIG?.NAME_LENGTH">
                                    <mat-error>{{_util.errorMessage(name,FORM_ERROR?.name)}}</mat-error>
                                </div>
                                <div class="form-group">

                                    <mat-select placeholder="Search Country Code" class="form-control" (selectionChange)="onChangeCode($event)" [formControl]="countryCode" #singleSelect>
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl" [placeholderLabel]="placeholderLabel" [noEntriesFoundLabel]="noEntriesFoundLabel"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank">
                                            {{bank.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error> {{_util.errorMessage(countryCode,FORM_ERROR?.countryCode)}}</mat-error>
                                </div>










                            </div>



                            <div class="col-md-6">


                                <div class="form-group">
                                    <input type="text" formControlName="email" placeholder="Enter Your Email Id*" class="form-control" />
                                    <mat-error>{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>

                                </div>



                                <div class="form-group">

                                    <input appOnlyNumber [formControl]="contactNo" placeholder="Enter Mobile no" [maxlength]="CONFIG?.MOBILE_LENGTH" class="form-control">
                                    <mat-error> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error>
                                </div>



                            </div>
                            <input type="submit" [disabled]="disabled" class="btnRegisterBtn" [value]="submit">
                        </div>

                    </form>
                </div>



            </div>

        </div>
    </div>

</section> -->




<!-- 


<div class="banner-form">
    <form [formGroup]="signupForm" class="reg-form" autocomplete="off" (ngSubmit)="submitUser()">

        <div class="banner-form-head">
            <p class="bfh-p1">Book Your Free Demo</p>
            <p class="bfh-p2">Find Your Online Tutor</p>
        </div>



        <div class="">
            <input type="text" class="form-control repo-input" id="name" placeholder="Enter Student Name" [formControl]="name" [maxlength]="CONFIG?.NAME_LENGTH">
            <mat-error>{{_util.errorMessage(name,FORM_ERROR?.name)}}</mat-error>
        </div>



        <div class="">
            <input type="text" formControlName="email" placeholder="Enter Your Email Id*" class="form-control repo-input" />
            <mat-error>{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>

        </div>


        <div class="">

            <input type="text" value="{{countryCode}} {{country}}" class="form-control repo-input" readonly/>

        </div>



        

        <div class="">
            
            <input appOnlyNumber [formControl]="contactNo" placeholder="Enter Mobile no" [maxlength]="CONFIG?.MOBILE_LENGTH" class="form-control repo-input">
            <mat-error> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error>
        </div>


        <div class="banner-form-btn">
            
            <input type="submit" [disabled]="disabled" class="btnRegisterBtn" [value]="submit">

        </div>
    </form>
</div> -->




<div class="z-banner">
    <div class="z-banner-img">
        <div class="zbi-img">
            <img src="assets/newziyyara/banner-girl.png" alt="">
        </div>
    </div>
    <div class="z-banner-content">
        <div class="zbc-left">
            <h2>Online 1-on-1 Live Tution</h2>
            <p><span style="margin-right:0.5rem;">Class 1-12</span><span>IB | ICSE | CBSE | IGCSE | GCSE</span></p>
            <p>Find your live 1-on-1 online tution tutor for school</p>
            <p>subject's as per you school curriculum</p>
        </div>
        <div class="zbc-right">
            <form  [formGroup]="signupForm" class="reg-form" autocomplete="off" (ngSubmit)="submitUser()">
                <div class="z-form-head">
                    <h5>Book Your Free Demo</h5>
                    <p>Find Your Online Tutor</p>
                </div>

                <!-- <div class="hidden" style="display:none;">
                    <input type="text" name="subject" placeholder="Subject" value="New Lead from Ziyyara.in" />
                    <input name="text" placeholder="Message" value="Hi Admin"/>
                  </div> -->

                <div class="z-form-input">
                    <!-- <input type="text" class="form-control repo-input" name="extra_name" id="name" placeholder="Enter Student Name" required/> -->
                    <input type="text" class="form-control repo-input" id="name" placeholder="Enter Student Name" [formControl]="name" [maxlength]="CONFIG?.NAME_LENGTH">
                    <mat-error>{{_util.errorMessage(name,FORM_ERROR?.name)}}</mat-error>
                </div>
                <div class="z-form-input">
                    <!-- <input type="email" name="extra_email" placeholder="Enter Your Email Id*" class="form-control repo-input" required/> -->
                    <input type="text" formControlName="email" placeholder="Enter Your Email Id*" class="form-control repo-input" />
                    <mat-error>{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>
                </div>
                <div class="z-form-input">
                    <!-- <input type="text" name="extra_country" value="{{countryCode}} {{country}}" class="form-control repo-input" readonly/> -->
                    <input type="text" value="{{countryCode}} {{country}}" class="form-control repo-input" readonly/>
                </div>
                <div class="z-form-input">
                    <!-- <input name="extra_phone" placeholder="Enter Mobile no" class="form-control repo-input" required/> -->
                    <input appOnlyNumber [formControl]="contactNo" placeholder="Enter Mobile no" [maxlength]="CONFIG?.MOBILE_LENGTH" class="form-control repo-input">
                    <mat-error> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error>
                </div>

                <!-- <input type="hidden" name="access_token" value="f0jlhsajx91ujqi3nz2mbzng" /> -->
                <!-- return urls can be fully qualified -OR-
                   start with / for root relative -OR-
                   start with . for url relative --> 
              <!-- <input type="hidden" name="success_url" value=".?message=Email+Successfully+Sent%21&isError=0" /> -->
              <!-- <input type="hidden" name="error_url" value=".?message=Email+could+not+be+sent.&isError=1" /> -->


                <div class="z-form-btn">
                    <!-- <input type="submit"  class="btnRegisterBtn" id="submit_form"  value="Send" > -->
                    <input type="submit" [disabled]="disabled" class="btnRegisterBtn" [value]="submit">
                </div>
            </form>
        </div>
    </div>
</div>






