import { isPlatformBrowser , DOCUMENT} from '@angular/common';
import { environment } from '../environments/environment';    
import { Component, OnInit, AfterViewChecked, AfterViewInit, ViewEncapsulation , Inject, PLATFORM_ID} from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AppService } from '../app/services';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { LogoutModalComponent } from './components/logout-modal/logout-modal.component';
//import { TranslateService } from '@ngx-translate/core';
import { MetaService } from './services/meta.service';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  
})
export class AppComponent implements OnInit {


  loader = true;
  loaddd = false; config: any;
  loaderMain = true;
  constructor(@Inject(PLATFORM_ID) private platformId: any, @Inject(DOCUMENT) private document: any,  
    public snackbar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private _appSer: AppService,
    
  //  private translate: TranslateService,
    private metaService: MetaService
  ) {
   // translate.setDefaultLang('en');
  }

  useLanguage(language: string) {
  
    //this.translate.use(language);
  }
 

  ngOnInit() {
    var host= location.host+""+location.pathname;
 
    // this.metaService.createCanonicalURL(host);
    this.subcribeToSnack();
    this.loaderSub();
    this.subscribeToBlocked();
  
    if (!isPlatformBrowser(this.platformId)) {
        let bases = this.document.getElementsByTagName('base');

        if (bases.length > 0) {
            bases[0].setAttribute('href', environment.baseHref);
        }
    }


}
  subcribeToSnack() {
    this._appSer.message.subscribe(message => this.openSnackbar(message));
  }

 
  openSnackbar(snackMessage: any) {
    if(snackMessage.message){
      this.snackbar.open(snackMessage.message, snackMessage.action, { duration: 5000 });
    } else {
      this.snackbar.open("Internal Server Error", snackMessage.action, { duration: 5000 });
    }
    
  }
  closeSnackbar() {
    if (this.snackbar) {
      this.snackbar.dismiss();
    }
  }
  subscribeToBlocked() {
    this._appSer.blocked.subscribe((block) => {
      if (block) {
        this.openLogoutPopUp();
      }
    })
  }
  openLogoutPopUp() {
    const content = '';

    const dialogRef = this.dialog.open(LogoutModalComponent, {
      width: '500px',
      maxHeight: 'Auto',
      data: {
        content: { title: content },
        action: 'active'
      }, disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      // alert();
      this._appSer.logout();
    });
  }

 


  ngAfterViewInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loaderMain = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.loaderMain = false;
        }
      });
  }

  loaderSub() {
    this._appSer.load.subscribe((item) => {
      this.loader = item ? false : item;
    });
  }
  scroltop(){
      window.scrollTo(0, 0);
       
  }

}
