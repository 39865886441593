<div class="modal-wrapper text-center">
  <div class="header-popup"> {{data?.content?.title}}
    &nbsp;
    <a (click)="no()" class="cross-modal"><i class="fa fa-times"></i></a>
  </div>
  <div class="mt-2">
    <h2 class="modal-heading">

    </h2>
  </div>
  <div class="mb-2">
    <p class="modal-para p-2">
      <strong>Your session has been expired</strong>
    </p>
  </div>
 

  <div class="button-wraper modal-btn-wrapper ">
    <div class="clearfix mt-3">
     
      
        <button class="btn btn-ziyyara-alt" mat-raised-button (click)="no()" type="button" mat-button>No</button>
      
    </div>
  </div>
</div>