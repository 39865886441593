import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from "@angular/material";
import * as AOS from 'aos';
import { Title ,Meta} from '@angular/platform-browser'
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any; 
@Component({
  selector: 'app-get-online-tuition',
  templateUrl: './get-online-tuition.component.html',
  styleUrls: ['./get-online-tuition.component.css'],
 
})
export class GetOnlineTuitionComponent implements OnInit {

  constructor(
    private titleService: Title, 
    private meta: Meta,
    private meta_service : MetaService
  ) { }

  ngOnInit() {
       
      this.createSEOService();
    AOS.init({
      duration: 1200,
      delay: 200,
      once: true
    });
  }
  createSEOService() {
    this.titleService.setTitle("Why opt for Online Tuitions at Ziyyara | Experienced Tutor");
     var desc="Opt for online tuitions at Ziyyara which is renowned in providing best 1-on-1 live tutoring from experienced tutor for all subjects to all grade students."
      this.meta.updateTag({ name: 'description', content: desc })
      var keywords= "Opt for online tuitions at Ziyyara which is renowned in providing best 1-on-1 live tutoring from experienced tutor for all subjects to all grade students."
      this.meta.updateTag({ name: 'keywords', content: keywords });
      var url=  location.pathname;
      this.meta_service.createCanonicalURL(url);
      this.meta_service.removeStructuredData();
     
   
  }
}
