import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-chandigarh',
  templateUrl: './chandigarh.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class ChandigarhComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How do we shortlist a tutor for online Chandigarh home tutoring?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara helps in making a qualified and knowledgeable tutor accessible to you. As we have a pool of talented online teachers that meets the needs of growing students, so we can easily get you the best teacher who can offer his/her extended help. All that is essential is to provide detailed info about your requirements and we will assign for the best teacher."
    }
  },{
    "@type": "Question",
    "name": "Am I allowed to cancel online tuition in Chandigarh?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Since all the online tuition in Chandigarh is scheduled within our Student Portal, you can cancel the same if you have some important work. But you are supposed to inform us at least a few hours before so that we can plan our time accordingly. Else while taking the regular classes, you must get prepared and ready at least 5-10 minutes before the scheduled time. In case there is a need to reschedule a lesson in an emergency, then you inform Chandigarh home tutors as early as possible."
    }
  },{
    "@type": "Question",
    "name": "What will I need to take online tuitions?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "To take online tuition in Chandigarh, you need: 1) A computer or a smartphone with an average or above average processing speed so that you can get continuous classes without any technical error. We suggest you take classes regularly so that you can know all the thoughts clearly. 2) A computer with a working webcam and microphone will be great. You can click on the 'enable' the microphone and camera within the browser before first taking Chandigarh home tuition. 3) If you take lectures on computer, please ensure that you have the updated Google Chrome version as older versions of Chrome sometimes don’t support the full video conferencing functionality 4) Most importantly a relatively fast and reliable internet connection so that you can take online tuition classes without any hassle."
    }
  }]
}

cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}

  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {

   this.titleService.setTitle("  Online Home Tuition in Chandigarh | Tutors in Chandigarh | Tuition in Chandigarh");
   var desc=" Now complete your syllabus with Ziyyara’s home tuition in Chandigarh. Our online home tutor in Chandigarh offers the best online help to the students of Class 5 to 12. "
    this.meta.updateTag({ name: 'description', content: desc })
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
    this.slides = this.chunk(this.cards, 3);
    this.meta.updateTag({name: 'keywords', content:"home tuition in chandigarh, tuition in chandigarh, home tutors in chandigarh, home tutor in chandigarh, home tuition in chandigarh, home tuition for chandigarh, tuition in chandigarh, chandigarh home tuition, Online tuition in chandigarh, tuition online in chandigarh, chandigarh home tuition, chandigarh online tuition, online home tuition in chandigarh, home online tuition in chandigarh, online home tuition for chandigarh"})

  }

  

}
