import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { MetaService } from "src/app/services/meta.service";
declare var Swal: any;
@Component({
  selector: "app-business-studies",
  templateUrl: "./business-studies.component.html",
  styleUrls: ["../home-tuition/home-tuition.component.css"],
})
export class BusinessStudiesComponent implements OnInit {
  cards = [
    {
      title: "Economics",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/Economics.jpg",
    },
    {
      title: "Maths",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/Mathematics.jpg",
    },
    {
      title: "English",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/English.jpg",
    },
    {
      title: "Physics",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/Physics.jpg",
    },
    {
      title: "History",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/History.jpg",
    },
    {
      title: "Hindi",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/Hindi.jpg",
    },
    {
      title: "Geography",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/Geography.jpg",
    },
    // {
    //   title: 'Business Studies',
    //  // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    //   buttonText: 'Read',
    //   img: '/assets/images/Business-Studies.jpg'
    // },
    {
      title: "Biology",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/Biology.jpg",
    },
    {
      title: "Political-Science",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/Political-Science.jpg",
    },
    {
      title: "Accountancy",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/Accountancy.jpg",
    },
    {
      title: "Chemistry",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/Chemistry.jpg",
    },

    {
      title: "Computer Science",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/Computer-Science.jpg",
    },
    {
      title: "Vedic Mathematics",
      // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: "Read",
      img: "/assets/images/vaidic-maths.webp",
    },
  ];
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  websiteSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Benefits of taking business studies tuition classes at Ziyyara?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "A student can be benefited in numerous ways by enrolling in tuition classes of business studies which are as follows: (a). Personalized one-on-one attention. (b). Holistic teaching methods. (c). Regular weekly or monthly assessments (d). Option of Doubt class instead of regular classes.",
        },
      },
      {
        "@type": "Question",
        name: "Do students of class 11 & class 12 need a tutor for business studies?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Every student aims to achieve good marks and it takes a lot of dedication and effort to excel in exams, therefore they look for expert tutors who can explain the concepts & terminologies in an easy manner as school teachers cannot pay attention to every student. Therefore, tuition of business studies is needed by every student of class 11 & 12.",
        },
      },
      {
        "@type": "Question",
        name: "Why tuition for Business studies?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Though business studies is a theory based subject yet comprise complicated terms which a student can’t understand without assistance. Hence Ziyyara’s business studies home tuition classes come handy as the tutors use a holistic approach and clears the concept via one-on-one live online classes.",
        },
      },
      {
        "@type": "Question",
        name: "Does Ziyyara provide business studies tutor at home?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Since Ziyyara offers a personalised one-on-one online tuition, it's quite similar to getting a business studies home tutor as students can see and chat with the tutor via our virtual whiteboard which is enabled with a live audio & video call & chat option.",
        },
      },
    ],
  };
  constructor(
    private titleService: Title,
    private meta: Meta,
    private meta_service: MetaService
  ) {}

  ngOnInit() {
    //slider
    this.slides = this.chunk(this.cards, 3);
    this.titleService.setTitle("Best Online Tuition for Business Studies | Business Studies Online Tuition");
    var desc ="Get the best online tuition for Business Studies. Ziyyara provides business studies tuition at the comfort of your home. For free demo call us on +91 9654271931";
    this.meta.updateTag({ name: "description", content: desc });
    var keywords ="Online tuition for Business studies, Business studies online tuition, tuition for Business studies, Business studies tuition classes, tuition classes of Business studies, Business studies home tuition, home tuition of Business studies, tuition of Business studies, Business studies home classes, Business studies home tuition classes, Business studies tutor, Business studies home tutor, tutor for Business studies";
    this.meta.updateTag({ name: "keywords", content: keywords });
    var url = location.pathname;

    var ogtitle =" Best Online Tuition for Business Studies | Business Studies Online Tuition";
    this.meta.updateTag({ property: "og:title", content: ogtitle });
    var ogdesc ="Get the best online tuition for Business Studies. Ziyyara provides business studies tuition at the comfort of your home. For free demo call us on +91 9654271931";
    this.meta.updateTag({ property: "og:description", content: ogdesc });

    var twittertitle =" Best Online Tuition for Business Studies | Business Studies Online Tuition";
    this.meta.updateTag({ name: "twitter:title", content: twittertitle });
    var twitterdesc ="  Get the best online tuition for Business Studies. Ziyyara provides business studies tuition at the comfort of your home. For free demo call us on +91 9654271931";
    this.meta.updateTag({ name: "twitter:description", content: twitterdesc });
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
  }

  
  generateSubjectLink(sub: string) {
    var subject = sub.toString().toLowerCase();
    if (subject.includes("vedic")) {
      return "/vedic-math-workshop";
    } else if (subject.includes("computer")) {
      return "/home-tuition/online-home-tuition-for-computer-science";
    } else if (subject.includes("business")) {
      return "/home-tuition/online-home-tuition-for-business-studies";
    } else {
      return "/home-tuition/online-home-tuition-for-" + subject;
    }
  }
}
