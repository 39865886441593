<app-header-front>
</app-header-front>
<div class="how-it-works">
    <app-registration-form></app-registration-form>
    <section id="title" class="light" style="padding: 10px;">
        <div class="container">
            <h1> Online Home Tuition in India </h1>
            <h3>What's Ziyyara</h3>
            <p> Ziyyara is a renowned global education platform that offers one to one online home tuition classes to students of classes 5-12th. Ziyyara online home tutor helps students in getting familiarity about the perceptions that are imparted to them
                in schools. We believe that motivation is important for success, and thus all our tutors motivate students so they can stay on track and can fulfil their goals in life. No matter how old your children are, they still can get customized
                and tailored classes. Our unique strategies, customized online tuition classes, lowest tuition free, free demo classes, regular doubt sessions, are something which help us set benchmarks in the entire online education world.</p>
            <h3> All the online tutoring classes offered by us include:</h3>

            <p> 1. Timely progress reporting sessions with parents </p>
            <p> 2. Organized training at our online tuition centre</p>
            <p> 3. Fun sessions</p>
            <p> 4. Contests</p>
            <p> 5. Customized online tuition classes</p>
            <p> 6. Doubt sessions</p>
            <p> 7. Ziyyara’s online tuition teacher provides classes using whiteboards, etc.</p>

            <h3> How private tuition tutor help in Academic subjects </h3>
            <p> Taking online coaching classes tutoring by our online tutors can help a lot in improving subject comprehension, promoting confidence, and clearing all the concepts. Taking online home tuition for the most common subjects like Maths, Physics,
                Accounts, etc. helps school going children in getting individualized attention so that they can get all of their doubts clear without any kind of hassles. </p>
            <p> Taking customized classes at Ziyyara will also help parents to track their kids’ performance on a timely basis. Once customized online programs at tuition centres for different academic subjects are created considering the needs of each and
                every student. All the teachers work harder to ensure whichever academic subject they are teaching aid students in improving their performance.</p>
            <p> At Ziyyara, students hunting for online private tuition can search for a private tutor by subject. We have veteran and trained tutors for all subjects that are a part of course curriculum in global schools. From Science to Accounts to English,
                you can get subject wise experts. In case you are unable to get the subject wise tutor you are looking for at our platform, you can call us and talk to our coordinating team.</p>
            <blockquote>
                <p><i>According to <a href="https://en.wikipedia.org/wiki/Online_tutoring">Source </a></i>, The main advantage of private one-to-one tutoring was described by Benjamin Bloom.</p>
            </blockquote>
            <h3 class="center"> Online Home Tuition for Academic Subjects </h3>
            <div class="row pagetitle tab_sec">
                <!-- <h3 class="center"> Online Home Tuition Subject-Wise </h3> -->
                <div class="col-md-6">
                    <a routerLink="/home-tuition/online-home-tuition-for-economics"><strong>Home Tuition For Economics </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-business-studies"><strong>Home Tuition For Business Studies </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-accountancy"><strong>Home Tuition For Accountancy </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-political-science"><strong>Home Tuition For Political Science</strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-english"><strong>Home Tuition For English</strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-chemistry"><strong>Home Tuition For Chemistry</strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-biology"><strong>Home Tuition For Biology</strong> </a>
                </div>

                <div class="col-md-6">



                    <a routerLink="/home-tuition/online-home-tuition-for-physics"><strong>Home Tuition For Physics </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-maths"><strong>Home Tuition For Mathematics </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-geography"><strong>Home Tuition For Geography </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-history"><strong>Home Tuition For History </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-hindi"><strong>Home Tuition For Hindi </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-computer-science"><strong>Home Tuition For Computer Science </strong> </a>
                    <a routerLink="/vedic-math-workshop">Vedic Math</a>

                </div>
            </div>


            <p> We are amongst the best online home tutor site </p>
            <p> Today due to several reasons’ parents prefer our online tuition at home for their children. You can take online tuition for all subjects, we have expert tutors who have years of experience in the teaching sector. Whether you look for a tutor
                for English, Maths, Science, SST, or for any other academic subject, you can bank upon us for offering the top-notch tuition services.</p>
            <p> Now all those parents who find it difficult to help their children in completing school assignments on time or in making a concept clear, can get in touch with us for the best academic assistance. All our online home tuition programs aimed
                at helping your children develop their creative learning skills so that they can achieve success.</p>

            <h3 class="center"> Our Online Coaching Classes Available in India </h3>
            <div class="row tab_sec">
                <!-- <h3 class="center">Online Home Tuition Pan India </h3> -->

                <div class="col-md-6">
                    <a routerLink="/home-tuition/online-home-tuition-in-delhi"><strong>Home Tuition In Delhi </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-andaman-nicobar"><strong>Home Tuition in Andaman Nicobar </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-ranchi"><strong>Home Tuition in Ranchi </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-ahmedabad"><strong>Home Tuition in Ahmedabad </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-pune"><strong>Home Tuition in Pune </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-kolkata"><strong>Home Tuition in Kolkata </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-chandigarh"><strong>Home Tuition in Chandigarh </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-assam"><strong>Home Tuition in Assam </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-bangalore"><strong>Home Tuition in Bangalore </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-gurugram"><strong>Home Tuition in Gurugram </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-karnataka"><strong>Home Tuition in Karnataka </strong> </a>
                    <!-- <a   routerLink="/home-tuition/home-tuition-tutoring-in-india"><strong>Online Home Tuition Tutoring in India </strong> </a> -->

                </div>
                <div class="col-md-6">
                    <a routerLink="/home-tuition/online-home-tuition-in-lucknow"><strong>Home Tuition In Lucknow </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-gujarat"><strong>Home Tuition in Gujarat </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-arunachal-pradesh"><strong>Home Tuition in Arunachal Pradesh </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-hyderabad"><strong>Home Tuition in Hyderabad </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-kerala"><strong>Home Tuition in Kerala </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-chennai"><strong>Home Tuition in Chennai </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-jaipur"><strong>Home Tuition in Jaipur </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-coimbatore"><strong>Home Tuition in Coimbatore </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-mumbai"><strong>Home Tuition in Mumbai </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-in-noida"><strong>Home Tuition in Noida </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-tutoring-near-me"><strong>Online Tuition – Near You </strong> </a>

                </div>

            </div>
            <p> Online home tuition is an incredible way for all the school going students of all ages. All the private tutors who are linked with us are highly trained in delivering online classes and can teach as expertly to all the students living anywhere
                in India. At Ziyyara, we appoint those online home tutors for online classes who adhere to the highest standard and can share their knowledge and experience with students living all over India. We have students of Delhi, Chennai, Bangalore,
                Kerala, Noida, Mumbai, among other Indian cities who have trusted us for our non-compromising online home tuition near me.</p>
            <p> Getting the best online tutor anywhere is quite easy. All you need is to get in touch with us, the entire process is straightforward so that students can easily sign up for our online program. No matter where you are located, you need a laptop,
                internet connection and a few other things to enroll for online home tutoring. We take additional precautions around connectivity issues and ensure that everything is smoother from our end, so that un-disturbed classes by Ziyyara’s online
                tuition teacher can be delivered.</p>

            <h3 class="center"> Ziyyara Provides Tuition for All Boards </h3>
            <div class="row tab_sec">
                <!-- <h3 class="center">Online Home Tuition For All Boards </h3> -->

                <div class="col-md-6">
                    <a routerLink="/home-tuition/online-home-tuition-for-ib-board"><strong>Home Tuition For IB Board </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-icse-board"><strong>Home Tuition For ICSE Board </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-cbse-board"><strong>Home Tuition For CBSE Board </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-isc-board"><strong>Home Tuition For ISC Board </strong> </a>
                </div>
                <div class="col-md-6">
                    <a routerLink="/home-tuition/online-home-tuition-for-igcse-board"><strong>Home Tuition For IGCSE Board </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-cisce-board"><strong>Home Tuition For CISCE Board </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-gcse-board"><strong>Home Tuition For GCSE Board </strong> </a>
                    <a routerLink="/home-tuition/online-home-tuition-for-cambridge-international-board"><strong>Home Tuition For Cambridge International Board </strong> </a>
                </div>

            </div>

            <p> Ziyyara offers online tuition by different online home tutors near me for all different boards including CBSE, IB, among other boards to all the students of 5th to 12th. Different subjects like physics, chemistry, English, biology, mathematics,
                etc. are taught as per the syllabus given by the board. Our professional online tuition teachers use innovative methods aiming to assist students academically. </p>

            <p> 1) Online tuition for CBSE Board </p>
            <p> 2) Online tuition for GCSE Board </p>
            <p> 3) Online tuition centers for IB Board </p>
            <p> 4) Online tuition classes for CISCE Board </p>
            <p> 5) Online tuition for ICSE Board</p>
            <p> 6) Online tuition for Cambridge Board </p>
            <p> 7) Online tuition for IGCSE Board </p>
            <p> 8) Online tuition for ISC Board </p>

            <h3> Is taking online tuition at home beneficial? </h3>

            <p> Online tuition classes are beneficial. At Ziyyara, we provide the best educational support so that children’ learning capabilities and skills can improve. To offer excellent tutoring to all the school going students, we have made everything
                quite simple. Our effective and flexible timings allow students to take online classes from anywhere. In today’s hi-tech world, internet and distance learning play an important role in promoting digital education. </p>
            <p> With the help of online education, we can offer better flexibility and convenience to all of the learners. Aim of Ziyyara, the best online tutoring sites, is to provide online teaching and help promote student’s knowledge in the simplest ways.
                Being a student or a learner, taking online tutions from home will be beneficial as it permits all the school going children to take classes from their comfort zone.</p>

            <h3> How to Sign Up At Ziyyara? </h3>
            <p> <strong>a)	Signup: </strong> To get the finest online home tuition you need to sign up first.</p>
            <p> <strong>  b)	Contact: </strong> Schoolchildren looking for an online home tutor have to submit all the details at our platform.</p>
            <p> <strong> c)	Confirm: </strong> Upon confirmation connected to classes, the coordinating team at Ziyyara will assign you a tutor for Online tuition. </p>
            <h3>Are online tuition classes helpful for the students? </h3>
            <p> Yes, online tutoring, if delivered by the trained teachers will surely help students a lot in boosting their academic scores. We at Ziyyara have the finest group of passionate souls who work on a mission to boost the learning habits of all
                the students. From the parents' perspective, we completely appreciate that it is hard to keep your child inspired with their learning, but you don’t need to worry at all now. In case you look for the best assistance, then you have landed
                at the right place. We at our online tuition website create an individual timetable aiming to support your child with their learning. </p>
            <p> All our dedicated tutors for Online home tuition will reduce your child’s worries and boost their self-confidence at the most affordable prices. We have years of experience in developing an online teaching and support service for children
                studying in classes 5-12. Our online home tutors are selected smartly, monitored and trained so that best teaching services can be delivered. </p>
            <h3> Difference between online private tuition and government tuition teacher? </h3>
            <p> Some of the key differences between private online home tutoring and government tuition teachers are mentioned below:</p>
            <p> 1. Easy to access – Taking private online tuition classes are just a click away, however you can’t access government classes easily.</p>
            <p> 2. Dedicated tutors for your child can be accessed easily.</p>
            <p> 3. One to one online private tuition classes is possible but in the government tuitions, classes are provided in groups.</p>
            <p> 4. Programs offered by our private tuition tutors are tailored as per the child’s age and needs, but this is something which is missing in government tuitions. </p>
            <h3> How will you get home tuition nearby you? </h3>
            <p> We are passionate in what we do and we accelerate every child’s learning progress so that they can get the best assistance. We offer the best online home tuition nearby so that children can boost their self-confidence level. Our inspiring
                team of tutors providing online tuition classes use interactive learning techniques and skills that offer the maximum satisfaction to all the learners, we teach individuals in one to one online tuition centers so that a fun and engaging
                learning environment can be promoted. </p>
            <h3> 3 Gains of Online Home Tuition Tutoring </h3>
            <p> Though there are several aids of taking online tuitions, but out of them the most common benefits are presented below: </p>

            <p> <strong>  a)	Individual learning involvement: </strong> Taking online classes from a tuition teacher at Ziyyara will allow your child to get an individualized learning experience which can help them feel more confident. Individual attention
                is something which is not possible in a classroom, so taking customize classes for understanding lessons and chapters will help all the students. </p>
            <p> <strong>  b)	One-on-one live classes: </strong> Our tutors via online coaching classes understand individual learning style and adapt their teaching style accordingly. So opting for Ziyyara’ tuitions will allow your child to have their own
                private teacher at the most affordable rates.</p>
            <p> <strong>  c)	Progresses academic performance: </strong> As a parent, we all look for the ways by which our child’ academic performance can improve. But many times don’t get the access to the right platform that can academically assist our
                children. Luckily, Ziyyara, an online home tuition provider, is here to offer timeless assistance to all the children. Taking online tutoring will prepare all the learners for all school exams, and will cater to their specific problems
                as well. Children’s school grades and overall scores can improve much post taking help from Ziyyara’s online private tutor. </p>

            <h3> Where are our Online Tuition Centres </h3>
            <p> All global students can easily be a part of our online classes, we help all the students with their academics. No matter where you are located, you can easily start taking our online classes. We have our online students taking online home
                tuition scattered in different parts of the world including India, the United Kingdom, United States, Australia, and many Gulf countries as well. There are numerous benefits of taking online home tutoring from Ziyyara’s online home tutor,
                which include gaining a better understanding of a field of study. </p>
            <p> With Ziyyara’ customized online tutoring programs, all the students living in different countries can get the best classes. We have expert tutors for every region and country who are well aware about the education system followed in that country,
                teaching style, and moreover use the same accent preferred in that country. Taking online classes will help students of all grades in developing their critical thinking and learning skills, which is required at every step in life. </p>
            <h3> How to select best online tutoring site </h3>

            <p> Once you have decided that online home tuition is perfect for your child, the next immediate step is to sign up for a free demo class. By taking one unpaid demo class, your child can understand more about our teaching style and how these classes
                can benefit him/her. </p>
            <p> Enrolling at our platform is quite easy. Once you have registered at Ziyyara, we will allot the best online tutors who can offer classes to your children. So basically the steps to register at Ziyyara include: </p>
            <ul>
                <p> 1. Registration at Ziyyara </p>
                <p> 2. Our coordinating team will contact you regarding providing the best online home tutor.</p>
                <p> 3. One free class by online tuition teacher will be provided</p>
            </ul>

            <p> Complete the entire enrolment process, so that you child can start achieving his or her goals right now. </p>
            <h3>Related Links</h3>

            <div class="card-grid four-col">
                <div>
                    <h4>Online Tuition Sites </h4>
                    <a href="https://ziyyara.in/blog/what-are-best-online-tuition-websites-online-tuition-site-list.html"><img src="https://ziyyara.in/blog/assets/images/best-online-tuition-websites-list-by-ziyyara.webp"></a>
                </div>
                <div>
                    <h4>Parents Choosing Homeschooling </h4>
                    <a href="https://ziyyara.in/blog/home-schooling-in-india.html"><img src="https://ziyyara.in/blog/assets/images/why-are-parents-choosing.webp"></a>
                </div>
                <div>
                    <h4>Home Tuition Advantages </h4>
                    <a href="https://ziyyara.in/blog/what-is-online-home-tuition-and-advantages.html"><img src="https://ziyyara.in/blog/assets/images/what-is-online.webp"></a>
                </div>
                <div>
                    <h4> Special subjects Tuition </h4>
                    <a href="https://ziyyara.in/blog/online-home-tuition.html"><img src="https://ziyyara.in/blog/assets/images/online-home-tuitions.webp"></a>
                </div>
            </div>




            <p> Online classes are certainly convenient, flexible, and help all age group students to understand all the concepts easily and more effectively. With a surge in online education, more students have started opting for global home tuition classes.
                But not all online classes are equal and nor do they provide excellent quality of education which students look forward to.</p>
            <p> So before hunting for the best online classes, there are numerous factors that need to be looked upon so that students’ educational needs and goals can be accomplished. </p>
            <p> Today we discuss some of the important factors that need to be considered by all students and their parents looking for online tuition at home.</p>

            <p> <strong>    1.	Credibility: </strong> No matter whichever board or in school you are studying, for additional assistance always look for an accredited online education. Search for platforms like Ziyyara that offer the best learning experience
                through private tuition near me needed to perform well in the school.</p>

            <p> <strong>    2.	Tech assistance: </strong>Imagine you sitting at your computer with no access to teachers. Despite having a fast internet connection, if you are receiving some error then you need to get it sorted out. An ideal online tuition
                platform like Ziyyara has a tech support team who always do a quick tech run and troubleshoot the problem, if any. Students taking online private home tuition near me at Ziyyara get extended tech support as well in addition to quality
                education.
            </p>


            <p> <strong>       3.	Tuition charges: </strong>Similar to the charges taken by schools, the private home tuition costs vary from one platform to another. Look for the private tuition near me that is within your budget so that you can easily pay
                the charges for a longer period of time.</p>

            <p> <strong>    4.	Check the reviews: </strong>In the digital era, everything is just a click away from us. While booking online classes always look for the reviews and feedback as it helps make a form decision. Look for an online platform that
                has a high feedback rate shared by the students.</p>


            <p> <strong>   5.	Class timings: </strong> Always check the class timings whether you are getting classes as per your convenience or not. Ziyyara is one of the best online learning platforms offering home tuition online classes at the most convenient
                timings. Students can easily choose from weekday or weekend classes as per their comfort to get hassle free online learning as per their suitability.</p>

            <p> <strong>    6.	Option to get customized courses: </strong>Another thing that all students must consider while taking online courses is the option of getting customized classes. Since each individual has his/her own learning speed, getting
                customized classes lets individuals learn at their own pace. Although any online platform might offer nearby home tuition courses, only a few like Ziyyara can provide customized home tuition near me.</p>

            <p> <strong>   7.	Teachers: </strong> Make sure that the teachers you have selected for online tutoring at home are certified and licensed teachers. Also they must be tech friendly and know how to make online classes interesting for the students
                studying from home.</p>
            <h3> Welcome to the world of Ziyyara </h3>
            <p> Ziyyara is a one-to-one online tutoring platform offering online private home tuition near me to all age groups of students. At Ziyyara we have registered thousands of qualified, and verified experts teaching different courses to all age group
                students. </p>
            <p> Our main motive is to deliver excellence learning and make online home tuition for all subjects accessible to all. We work harder in providing a hassle free learning experience to all age group students who otherwise are deprived from quality
                education. At Ziyyara, our topmost priority is to help all students get the best online education from the comfort of their home. For us, a student's location, educational background, etc. don’t matter as our subject wise home tuition
                tutor is here to deliver nothing but the best.</p>
            <p> Whether you are a school student studying CBSE, ICSE, or any state board, our tutors are here to act as a guiding pillar throughout the sessions. We provide numerous customized courses & online home tuition for kids all over the country and
                the teachers who are helping us in achieving our goal are highly experienced and passionate towards teaching.</p>
            <h3> How we are different: </h3>
            <ul>
                <p> a) We give an option to students to choose from different tutors based on their budget, timings while getting the tuition in home.</p>
                <p> b) Our team of expert home tuition tutor can help students in getting good scores in the exam.</p>
                <p> c) Students can choose the class timings as per their preference while contacting our home tuition services.</p>
                <p> d) We provide a Free Demo online tuition at home to the students.</p>
            </ul>
            <h3> Homeschooling at Ziyyara </h3>
            <p> Ziyyara provides home schooling to support students’ studies from Primary and Secondary age right through different boards. We pride ourselves in making the best quality education accessible to all students and provide complete assistance
                related to submission of assignments as well.</p>
            <p> At Ziyyara we look forward to welcoming all children taking home schooling in India and want to get the best education without compromising on anything. Students can easily select the subject they are looking forward to taking home tutoring
                online.
            </p>
            <h3> Benefits of our online classes </h3>
            <ul>
                <p> a) We have expert tutors offering online home tuition for all subjects. Our expert tutor's approach goes beyond just traditional learning; rather they use a perfect combination of traditional and modern techniques to make subjects more
                    interesting. </p>
                <p> b) The charges taken by us for online tuition from home are quite affordable. We strive harder to make online learning the most affordable kind of tutoring for all.</p>
                <p> c) Students taking our home tuition services can get improved results. Our tutors are fully invested in the overall development of the students.</p>
                <p> d) Along with offering the best teaching, we do periodic assessments aiming to track students’ progress.</p>
                <p> e) In case students are not satisfied with the tutor assigned to them during the demo class, they can ask for the replacement. Our coordinating team ensures that students get the next best teacher for online tuition classes near me.</p>
                <p> f) At Ziyyara, learning never stops, and distance never comes as a hurdle in between the classes.</p>
                <p> g) Our tutors are capable of providing the best tuition classes near me to all students looking for the best coaching.</p>
                <p> h) Students can easily select the best tutors looking for Home schooling in India from anywhere.</p>
                <p> i) How to enroll to get the best tuition near me at Ziyyara</p>
                <p> j) Signing up: First of all, students need to discuss their requirements with us by signing up at our portal.</p>
                <p> k) Matching: We select a home tutor for you so that students can get hassle free learning. Through our innovative matching system, we connect students with tutors who meet up with their expectations.</p>
                <p> l) Demo class: Students will then get a free demo class related to online home tuition in India. In case students are not completely satisfied with the style of teaching, they will get another tutor!</p>
            </ul>
            <h4> Book your FREE Demo Session now to get online tuition near me at the earliest. </h4>
            <p> Now students looking for online tuition classes near me can easily get connected with the best tutors to get the best study sessions. All the sessions delivered at Ziyyara are customized as per the learning needs of the students. Students
                can easily choose to schedule the class as per their schedule & availability while taking tuition at home at Ziyyara.</p>

            <h3> FAQ’s Tuition Tutoring in India </h3>


            <h4> Q.1 How are Ziyyara's online classes different from others? </h4>
            <p> At Ziyyara, we lay focus on all the feedback. Feedbacks shared by the learners and their parents assist us while offering flawless services. Our teachers deliver interactive and engaging online home tuition classes allowing students to know
                what is being taught in the classes. Our USP is that our online home tuitions work incredibly well for those children as well who have some learning difficulties, thanks to our online private tutor who specialize in the required area.</p>
            <h4> Q.2 How can I enroll for online coaching classes at Ziyyara? </h4>
            <p> It’s simple to enrol your child in one of Ziyyara’ online tutoring programs. You can visit the official website to know more about the same. </p>
            <h4> Q.3 Will my children like Ziyyara’s online tuition classes? </h4>
            <p> Yes, all the classes are offered in a fun filled way. We engage with our students during online learning classes. We at Ziyyara offer online home tuition in a more engaging way using an interactive white board.</p>
            <h4> Q.4 Are Ziyyara’s online tutors trained?</h4>
            <p> Yes, all the Ziyyara’ online tutors are fully trained. Most of the teachers have earned a master or PH. D degree and comes with years of experience.</p>
            <h4> Q.5 How Ziyyara’s online coaching classes help in improving my exam scores? </h4>
            <p> By taking classes from our online tuition teacher, schoolchildren can easily prepare themselves for their board exams. Also we inspire students to take mock tests so that it can help them improve their scores. Taking Ziyyara’s online tution
                at home is something you must opt for to improve your scores. </p>
            <div class=" pad ">
                <h3 class="center">Our Tutors</h3>
                <ng-container *ngFor="let tutor of tutorData">
                    <our-tutors-card [tutorData]="tutor"></our-tutors-card>
                </ng-container>
            </div>
            <h3>Related Links</h3>
            <mdb-carousel [isControls]="true" class="" [animation]="'slide'">
                <mdb-carousel-item *ngFor="let item of slides; let i = index">
                    <div class="col-md-4" *ngFor="let card of item; let cardIndex = index">
                        <a rel="nofollow" [routerLink]="generateSubjectLink(card.title)">
                            <mdb-card class="my-1">

                                <mdb-card-body>
                                    <mdb-card-title>
                                        <h6> Online Home Tuition For {{card.title}}</h6>
                                    </mdb-card-title>
                                    <!-- <p>{{card.description}}</p> -->
                                    <!-- <a href="#" mdbBtn color="success" class="waves-light" mdbWavesEffect>{{card.buttonText}}</a> -->
                                </mdb-card-body>
                                <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                            </mdb-card>
                        </a>
                    </div>
                </mdb-carousel-item>
            </mdb-carousel>















        </div>



        <div class="space-medium ">
            <div class="container">


                <div class="video-container">



                    <figure>
                        <figcaption>Competition of Learning</figcaption>
                        <iframe src="https://www.youtube.com/embed/TgcHCmQ94Do?rel=0&autoplay=0" allowfullscreen></iframe>

                    </figure>
                    <figure>
                        <figcaption> Online Home Tuition Vs Offline Tuition</figcaption>
                        <iframe src="https://www.youtube.com/embed/WXWAk_-PX_0?rel=0&autoplay=0" allowfullscreen></iframe>

                    </figure>
                    <figure>
                        <figcaption> Free Sign-up at Ziyyara Edutech</figcaption>
                        <iframe src="https://www.youtube.com/embed/fCoUQ8Pk4pA?rel=0&autoplay=0" allowfullscreen></iframe>

                    </figure>


                </div>


            </div>
        </div>
    </section>




</div>





<app-footer-front></app-footer-front>