import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeTuitionComponent } from './home-tuition/home-tuition.component'
import { EconomicsComponent } from './economics/economics.component';
import { PhysicsComponent } from './physics/physics.component';
import { AccountancyComponent } from './accountancy/accountancy.component';
import { BusinessStudiesComponent } from './business-studies/business-studies.component';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { CommonComponentModule } from '../common-component/common-component.module';
import { DelhiComponent } from './delhi/delhi.component';
import { AndamanNicobarComponent } from './andaman-nicobar/andaman-nicobar.component';
import { RanchiComponent } from './ranchi/ranchi.component';
import { AhmedabadComponent } from './ahmedabad/ahmedabad.component';
import { PuneComponent } from './pune/pune.component';
import { LucknowComponent } from './lucknow/lucknow.component';
import { GujratComponent } from './gujrat/gujrat.component';
import { HyderabadComponent } from './hyderabad/hyderabad.component';
import { ArunachalComponent } from './arunachal/arunachal.component';
import { KeralaComponent } from './kerala/kerala.component';
import { MathComponent } from './math/math.component';
import { GeographyComponent } from './geography/geography.component';
import { ChemistryComponent } from './chemistry/chemistry.component';
import { BiologyComponent } from './biology/biology.component';
import { EnglishComponent } from './english/english.component';
import { PoliticalComponent } from './political/political.component';
import { HistoryComponent } from './history/history.component';
import { ComputerScienceComponent } from './computer-science/computer-science.component';
import { HindiComponent } from './hindi/hindi.component';
import { KolkataComponent } from './kolkata/kolkata.component';
import { ChandigarhComponent } from './chandigarh/chandigarh.component';
import { AssamComponent } from './assam/assam.component';
import { BangaloreComponent } from './bangalore/bangalore.component';
import { GurugramComponent } from './gurugram/gurugram.component';
import { CoimbatoreComponent } from './coimbatore/coimbatore.component';
import { MumbaiComponent } from './mumbai/mumbai.component';
import { JaipurComponent } from './jaipur/jaipur.component';
import { ChennaiComponent } from './chennai/chennai.component';
import { NoidaComponent } from './noida/noida.component';
import { NearMeComponent } from './near-me/near-me.component';
import { IcseComponent } from './icse/icse.component';
import { CbseComponent } from './cbse/cbse.component';
import { IbComponent } from './ib/ib.component';
import { IgcseComponent } from './igcse/igcse.component';
import { CisceComponent } from './cisce/cisce.component';
import { GcseComponent } from './gcse/gcse.component';
import { IscComponent } from './isc/isc.component';
import { CambridgeInternationalComponent } from './cambridge-international/cambridge-international.component';
import { HomeTuitionIndiaComponent } from './home-tuition-india/home-tuition-india.component';
import { KarnatakaComponent } from './karnataka/karnataka.component';


const pageRoutes: Routes = [

  { path: '', component: HomeTuitionComponent },
  
  { path: 'online-home-tuition-for-economics', component: EconomicsComponent },
  { path: 'online-home-tuition-for-physics', component: PhysicsComponent },
  { path: 'online-home-tuition-for-accountancy', component: AccountancyComponent },
  { path: 'online-home-tuition-for-business-studies', component: BusinessStudiesComponent },
  { path: 'online-home-tuition-in-delhi', component: DelhiComponent },
  { path: 'online-home-tuition-in-andaman-nicobar', component: AndamanNicobarComponent },
  { path: 'online-home-tuition-in-ranchi', component: RanchiComponent },
  { path: 'online-home-tuition-in-pune', component: PuneComponent },
  { path: 'online-home-tuition-in-ahmedabad', component: AhmedabadComponent },
  { path: 'online-home-tuition-in-lucknow', component: LucknowComponent },
  { path: 'online-home-tuition-in-gujarat', component: GujratComponent },
  { path: 'online-home-tuition-in-gujrat', redirectTo: 'online-home-tuition-in-gujarat', pathMatch: 'full'},
   
  { path: 'online-home-tuition-in-hyderabad', component: HyderabadComponent },
  { path: 'online-home-tuition-in-arunachal-pradesh', component: ArunachalComponent },
  { path: 'online-home-tuition-in-kerala', component: KeralaComponent },
  { path: 'online-home-tuition-for-math', component: MathComponent },
 
  { path: 'online-home-tuition-for-history', component: HistoryComponent },
  { path: 'online-home-tuition-for-political-science', component: PoliticalComponent },
  { path: 'online-home-tuition-for-hindi', component: HindiComponent },
 
  { path: 'online-home-tuition-for-english', component: EnglishComponent },
  { path: 'online-home-tuition-for-math', component: MathComponent },
 
  { path: 'online-home-tuition-for-maths', component: MathComponent },
  { path: 'online-home-tuition-for-geography', component: GeographyComponent },
  { path: 'online-home-tuition-in-kolkata', component: KolkataComponent },
  { path: 'online-home-tuition-in-chandigarh', component: ChandigarhComponent },
  { path: 'online-home-tuition-in-assam', component: AssamComponent },
  { path: 'online-home-tuition-in-bangalore', component: BangaloreComponent },
  { path: 'online-home-tuition-in-gurugram', component: GurugramComponent },
  { path: 'online-home-tuition-in-coimbatore', component: CoimbatoreComponent },
  { path: 'online-home-tuition-in-mumbai', component: MumbaiComponent },
  { path: 'online-home-tuition-in-chennai', component: ChennaiComponent },
  { path: 'online-home-tuition-in-jaipur', component: JaipurComponent },
  { path: 'online-home-tuition-in-noida', component: NoidaComponent },
  { path: 'online-home-tuition-tutoring-near-me', component: NearMeComponent },
  { path: 'online-home-tuition-for-ib-board', component: IbComponent },
  { path: 'online-home-tuition-for-icse-board', component: IcseComponent },
  { path: 'online-home-tuition-for-cbse-board', component: CbseComponent },
  { path: 'online-home-tuition-for-igcse-board', component: IgcseComponent },
  { path: 'online-home-tuition-for-cisce-board', component: CisceComponent },
  { path: 'online-home-tuition-for-gcse-board', component: GcseComponent },
  { path: 'online-home-tuition-for-isc-board', component: IscComponent },
  // { path: 'home-tuition-tutoring-in-india', component: HomeTuitionIndiaComponent },
  { path: 'online-home-tuition-in-karnataka', component: KarnatakaComponent },
  { path: 'online-home-tuition-for-chemistry', component: ChemistryComponent },
  { path: 'online-home-tuition-for-biology', component: BiologyComponent },
  { path: 'online-home-tuition-for-computer-science', component: ComputerScienceComponent },
  { path: 'online-home-tuition-for-cambridge-international-board', component: CambridgeInternationalComponent },
 
  
];



@NgModule({
  imports: [
    AngularMaterialModule,
    SharedModule,
    CommonComponentModule,
    CommonModule,
    RouterModule.forChild(pageRoutes),
    

  ],
  declarations: [HomeTuitionComponent,EconomicsComponent,PhysicsComponent,AccountancyComponent,BusinessStudiesComponent, DelhiComponent, AndamanNicobarComponent, RanchiComponent, AhmedabadComponent, PuneComponent, LucknowComponent, GujratComponent, HyderabadComponent, ArunachalComponent, KeralaComponent, MathComponent, GeographyComponent, ChemistryComponent, BiologyComponent, EnglishComponent, PoliticalComponent, HistoryComponent, ComputerScienceComponent, HindiComponent, KolkataComponent, ChandigarhComponent, AssamComponent, BangaloreComponent, GurugramComponent, CoimbatoreComponent, MumbaiComponent, JaipurComponent, ChennaiComponent, NoidaComponent, NearMeComponent, IcseComponent, CbseComponent, IbComponent, IgcseComponent, CisceComponent, GcseComponent, IscComponent , CambridgeInternationalComponent, HomeTuitionIndiaComponent, KarnatakaComponent]
})

export class HomeTuitionModule {

ngOnInit(){





 }
}
