import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-igcse',
  templateUrl: './igcse.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class IgcseComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Is taking online IGCSE home tuition worthful?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Absolutely, yes. Majority of the students have shifted their focus towards online classes and have witnessed significant improvement in their scores. You can anytime book a free demo class at Ziyyara to know more about our online classes and how it can prove beneficial for you."
    }
  },{
    "@type": "Question",
    "name": "Please brief about the difficulty level in comparison to other boards?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Lets throw some light on the IGCSE Board in comparison to other nationalized education boards. Students getting teaching as per this board get wider exposure to newer subjects. Also, more focus is laid on other languages so that international students can get the best education in the language in which they are familiar."
    }
  },{
    "@type": "Question",
    "name": "Who should refer to IGCSE board?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "IGCSE curriculum is more about overall development and offers equal opportunity to the students who learn international course curriculum. Getting education as per this board format will help individuals to be a part of the global learning community."
    }
  },{
    "@type": "Question",
    "name": "What is the method to enroll at Ziyyara?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Initially you need to register at Ziyyara so that we can take free demo online tuition of IGCSE."
    }
  },{
    "@type": "Question",
    "name": "What is IGCSE board full form?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The International General Certificate of Secondary Education."
    }
  },{
    "@type": "Question",
    "name": "Is there something similar in GCSE and IGCSE?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The full name of IGCSE is “International General Certificate of Secondary Education'' which is almost similar to the GCSE qualification. The GCSE board is selected by the students in Year 10 – 11 in the UK and other countries following the IGCSE board so that they can prepare for higher studies."
    }
  },{
    "@type": "Question",
    "name": "Why is this board considered essential?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Since this board is internationally accepted, a lot of students prefer taking education as per this board. Pursuing education from this board acts as the gateway to do higher studies in the UK and beyond. Getting IGCSE certificate means students have completed their High School education and is designed for school children who want to get teachings as per the international board."
    }
  },{
    "@type": "Question",
    "name": "What are included in IGCSE subjects?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "IGCSE Board studies are available in different subjects like English, Maths, Economics, Geography, History, Biology, Chemistry, etc. The detailed broad subjects are included to allow all the students to access different kinds of programs in which the students are more interested.School goers taking their schooling as per this board are supposed to take at least 5- IGCSE subjects that consist of three compulsory subjects including language, Science and Maths. At Ziyyara, we are mainly focused on making newer boards more accessible for the students with the help of Online tuition classes of IGCSE."
    }
  }]
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle("Online Tuition For IGCSE Board | IGCSE Online Classes     ");
   var desc="If you are looking for online tuition for IGCSE board, then Ziyyara is here to help you out. Get our one-to-one live IGCSE online classes. Book a free demo now."
    this.meta.updateTag({ name: 'description', content: desc })
    var url=  location.pathname;
    var ogtitle="Online Tuition For IGCSE Board | IGCSE Online Classes  ";
    this.meta.updateTag({ property: 'og:title', content: ogtitle });
   var ogdesc="  If you are looking for online tuition for IGCSE board, then Ziyyara is here to help you out. Get our one-to-one live IGCSE online classes. Book a free demo now.   ";
   this.meta.updateTag({ property:'og:description', content: ogdesc });
 
   var twittertitle="Online Tuition For IGCSE Board | IGCSE Online Classes  ";
   this.meta.updateTag({ name:"twitter:title", content: twittertitle });
  var twitterdesc="  If you are looking for online tuition for IGCSE board, then Ziyyara is here to help you out. Get our one-to-one live IGCSE online classes. Book a free demo now. ";
  this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
    this.meta.updateTag({name:"keywords", content:"IGCSE board, what is IGCSE, IGCSE board syllabus, IGCSE board in india, IGCSE schools in india, IGCSE tutor, IGCSE home tutor, Home tutors for IGCSE, tutor for IGCSE, IGCSE home tutors, IGCSE tutor at home, Tuition For IGCSE, IGCSE Online Classes, Online Classes For IGCSE"})

   
   }



  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
 
  cards = [
    {
      title: 'IB Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-ib-board.webp'
    },
    {
      title: 'ICSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-icse-board.webp'
    },
    {
      title: 'CBSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cbse-board.webp'
    },
    {
      title: 'ISC Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-isc-board.webp'
    },
    {
      title: 'IGCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-igcse-board.webp'
    },
   
    {
      title: 'CISCE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cisce-board.webp'
    },
    {
      title: 'GCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-gcse-board.webp'
    },
    {
      title: 'Cambridge International-Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cambridge-international-board.webp'
    },
   
  
  
  ];
  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase().replace(" ","-");
    return '/home-tuition/online-home-tuition-for-'+subject;
  }
}
