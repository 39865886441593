import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-cisce',
  templateUrl: './cisce.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class CisceComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Explain the step by step process to register at Ziyyara for online tuition for CISCE?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The entire process made by our coordinating team is quite easy. More details related to the online CISCE tuition classes are mentioned on our official website. You can check how it works section."
    }
  },{
    "@type": "Question",
    "name": "Do you know anything about the Derozio Award?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Derozio Awards are given once in a year and are given to the top Indian educationists by the Council for the Indian School Certificate Examinations. The awards are granted in the reminiscence of Henry Louis Vivian Derozio, who was a popular poet and educator from West Bengal. This award is one of the prestigious and highest awards given by the Council for contributions in the field of education."
    }
  },{
    "@type": "Question",
    "name": "What is the CISCE board full form?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The Council for the Indian School Certificate Examinations."
    }
  },{
    "@type": "Question",
    "name": "Why should Ziyyara be my top-most priority when it's about online tuition classes of CISCE?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We at Ziyyara offer online CISCE home tuition that are planned according to the learning capabilities of the students. In totaling to providing CISCE tutoring services, all the tutors linked with us are highly experienced in providing coaching related to other boards as well. Online live classes through white boards is something which makes us special and unique from other online home tuition of CISCE."
    }
  },{
    "@type": "Question",
    "name": "Do you give importance to technology as well?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, our tutors are given specialized training so that they can move hand in hand with the modern technology and use the same rather than relying on the traditional teaching methods. Our tutors act as a guide to the child and assist them in taking decisions. Also, our tutors assist students in choosing their subjects in boards from their experience. Students can understand a lot by taking in insights from our online tutors of CISCE."
    }
  },{
    "@type": "Question",
    "name": "How will Ziyyara help my children while preparing For the Exams?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "By taking online tuition of CISCE, schoolchildren can easily prepare for their board exams. Not only students giving the board exam get the advantage of taking online tutoring classes, in fact all the students of classes 5-12 can find tuition tutors easily. Therefore, you need not worry at all as all our tutors will effectively guide you. Additionally, students can get access to mock tests as well during their home tuition classes which help them prepare for the board exams. That is why Ziyyara’s online CISCE home tuition classes have been liked by many school goers."
    }
  }]
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle("Online Tuition For CISCE Board | CISCE Tuition Online    ");
   var desc=" Ziyyara’s online tuition for CISCE board helps you in all subjects. Our professional tutors impart the knowledge with the help of audio/video enabled virtual whiteboard."
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ name: 'keywords', content:"CISCE board, what is ib CISCE, CISCE Board Syllabus, CISCE board in india, CISCE schools in india, CISCE online tutiton, CISCE Home Tuition, CISCE Home Tuition in India, Tuition For Cisce Students, CISCE Tuition Near me, Private Tutors for CISCE, Tuition Classes For CISCE Syllabus, CISCE home tutor, CISCE home classes"})
    var url=  location.pathname;

    var ogtitle="Online Tuition For CISCE Board | CISCE Tuition Online    ";
    this.meta.updateTag({ property: 'og:title', content: ogtitle });
   var ogdesc="   Ziyyara’s online tuition for CISCE board helps you in all subjects. Our professional tutors impart the knowledge with the help of audio/video enabled virtual whiteboard. ";
   this.meta.updateTag({ property:'og:description', content: ogdesc });
 
   var twittertitle="Online Tuition For CISCE Board | CISCE Tuition Online     ";
   this.meta.updateTag({ name:"twitter:title", content: twittertitle });
  var twitterdesc="   Ziyyara’s online tuition for CISCE board helps you in all subjects. Our professional tutors impart the knowledge with the help of audio/video enabled virtual whiteboard.  ";
  this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema)
  }

 
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  cards = [
    {
      title: 'IB Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-ib-board.webp'
    },
    {
      title: 'ICSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-icse-board.webp'
    },
    {
      title: 'CBSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cbse-board.webp'
    },
    {
      title: 'ISC Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-isc-board.webp'
    },
    {
      title: 'IGCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-igcse-board.webp'
    },
   
    {
      title: 'CISCE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cisce-board.webp'
    },
    {
      title: 'GCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-gcse-board.webp'
    },
    {
      title: 'Cambridge International-Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cambridge-international-board.webp'
    },
   
  
  
  ];
  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase().replace(" ","-");
    return '/home-tuition/online-home-tuition-for-'+subject;
  }
}
