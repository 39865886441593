<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">&nbsp;</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="assets/images/Green-Round-Trick.png" alt="">
            <h1>You are almost set!</h1>
            <p>Thanks for submitting the form, please Signup to enjoy the benefits of 1 free tuition class</p>
            <!-- <h3 class="cupon-pop">Your Id: <span>12345</span></h3> -->

        </div>
        <button type="button" class="btnRegister2" (click)="signup(template)">Sign Up</button>
    </div>

</ng-template>

<!-- <section>

    <div class="subject_banner subject_article">
        <div class="register-section">
            <h2> Get Online 1-on-1 LIVE Tuition {{tailUrl}} </h2>


            <h6>Class 1-12 IB | ICSE | CBSE | IGCSE | GCSE | PHYSICS, MATHS, CHEMISTRY, ENGLISH etc </h6>

            <div class="row">


                <div class="col-md-6 sec sep">
                    <div class="sect-div">
                        <img src="assets/images/call-icon.webp" alt="" width="81" height="81">
                        <span class="greentext">Call our </span>
                        <span> Expert  </span>
                        <a href="tel:9654271931" style="color:#fff;">+91-9654271931</a>


                    </div>

                    <div class="sect-div">
                        <img src="assets/images/whatsapp-icon.webp" alt="" width="81" height="81">
                        <span class="greentext">Whatsapp Us </span>
                        <a class="btnRegisterBtn" href="https://api.whatsapp.com/send?phone=919654271931">Send Message</a>
                    </div>

                </div>

                <div class="col-md-6  sec sep nobr">
                    <h2> Book a Free Demo Now!</h2>
                    <form [formGroup]="signupForm" class="reg-form" autocomplete="off" (ngSubmit)="submitUser()">

                        <div class="row register-form">
                            <div class="col-md-6">
                                <div class="form-group">
                                    
                                    <input type="text" class="form-control" placeholder="Enter Student Name" [formControl]="name" [maxlength]="CONFIG?.NAME_LENGTH">
                                    <mat-error>{{_util.errorMessage(name,FORM_ERROR?.name)}}</mat-error>
                                </div>
                                <div class="form-group">

                                    <mat-select placeholder="Search Country Code" class="form-control" (selectionChange)="onChangeCode($event)" [formControl]="countryCode" #singleSelect>
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl" [placeholderLabel]="placeholderLabel" [noEntriesFoundLabel]="noEntriesFoundLabel"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank">
                                            {{bank.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error> {{_util.errorMessage(countryCode,FORM_ERROR?.countryCode)}}</mat-error>
                                </div>










                            </div>



                            <div class="col-md-6">


                                <div class="form-group">
                                    
                                    <input type="text" formControlName="email" placeholder="Enter Your Email Id*" class="form-control" />
                                    <mat-error>{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>

                                </div>



                                <div class="form-group">
                                    

                                    <input appOnlyNumber [formControl]="contactNo" placeholder="Enter Mobile no" [maxlength]="CONFIG?.MOBILE_LENGTH" class="form-control">
                                    <mat-error> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error>
                                </div>



                            </div>
                            <input type="submit" [disabled]="disabled" class="btnRegisterBtn" [value]="submit">
                        </div>

                    </form>
                </div>



            </div>

        </div>
    </div>

</section> -->


<!-- Dheeraj banner starts -->
<div class="second-banner">
    <div class="sb-left">
        <div class="sbl-head">
            <h2 id="h2-head">Get Online 1-On-1 LIVE Tuition</h2>
            <p class="all-sub">Class 1-12 IB | ICSE | CBSE | IGCSE | GCSE | PHYSICS, MATHS, CHEMISTRY, ENGLISH </p>
        </div>
        <div class="sbl-call">
            <p class="call-p"><a href="tel:+919654271931"><span><i id="cl-icon2" class="fa fa-phone"></i></span>Call our Expert <span class="contact-number">+91-9654271931</span></a></p>
        </div>
        <div class="sbl-whatsapp">
            <p class="whatsaap-p"><span><img id="whatsapp-icon2" src="	https://muscathome.com/public/assets/img/whatsapp-logo.png" alt="logo"></span>&nbsp;&nbsp;Whatsapp Us <span class="send-message"><a href="https://api.whatsapp.com/send?phone=919654271931">Send Message</a></span></p>
        </div>
    </div>
    <span class="border-right"></span>
    <div class="sb-right">
        <div class="sbr-head">
            <h3 id="demo-class">Book A Free Demo Now!</h3>
        </div>

        <div class="sbr-form">


            <form [formGroup]="signupForm" autocomplete="off" (ngSubmit)="submitUser()">

                <!-- <div class="hidden" style="display:none;">
                <input type="text" name="subject" placeholder="Subject" value="New Lead from Ziyyara.in" />
                <input name="text" placeholder="Message" value="Hi Admin"/>
              </div> -->


                <div class="form-row">
                    <div class="col">
                        <!-- <input type="text" class="form-control" placeholder="Enter Student Name"> -->

                        <!-- <input type="text" class="form-control" name="extra_name" placeholder="Enter Student Name" required/> -->
                        <input type="text" class="form-control" placeholder="Enter Student Name" [formControl]="name" [maxlength]="CONFIG?.NAME_LENGTH">
                        <mat-error>{{_util.errorMessage(name,FORM_ERROR?.name)}}</mat-error>
                    </div>
                    <div class="col">
                        <!-- <input type="text" class="form-control" placeholder="Enter Your Email Id"> -->

                        <!-- <input type="email"  name="extra_email" placeholder="Enter Your Email Id*" class="form-control" required/> -->
                        <input type="text" formControlName="email" placeholder="Enter Your Email Id*" class="form-control" />
                        <mat-error>{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>

                    </div>
                </div>
                <div class="form-row">
                    <div class="col">

                        <input type="text" name="extra_country" value="{{countryCode}}{{country}}" placeholder="Country" class="form-control" readonly/>
                        <!-- <mat-select placeholder="Search Country Code" class="form-control" (selectionChange)="onChangeCode($event)" [formControl]="countryCode" #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" [placeholderLabel]="placeholderLabel" [noEntriesFoundLabel]="noEntriesFoundLabel"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank">
                                {{bank.name}}
                            </mat-option>
                        </mat-select> -->
                        <!-- <mat-error> {{_util.errorMessage(countryCode,FORM_ERROR?.countryCode)}}</mat-error> -->


                    </div>


                    <div class="col">
                        <!-- <input type="text" name="extra_phone" placeholder="Enter Mobile no" class="form-control" required/> -->
                        <input appOnlyNumber [formControl]="contactNo" placeholder="Enter Mobile no" [maxlength]="CONFIG?.MOBILE_LENGTH" class="form-control">
                        <mat-error> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error>

                    </div>
                </div>

                <!-- <input type="hidden" name="access_token" value="f0jlhsajx91ujqi3nz2mbzng" /> -->
                <!-- return urls can be fully qualified -OR-
                   start with / for root relative -OR-
                   start with . for url relative -->
                <!-- <input type="hidden" name="success_url" value=".?message=Email+Successfully+Sent%21&isError=0" />
              <input type="hidden" name="error_url" value=".?message=Email+could+not+be+sent.&isError=1" /> -->


                <div class="sbr-btn">
                    <!-- <button type="button" class="btn enter-butt">Submit</button> -->
                    <!-- <input type="submit" [disabled]="disabled" class="btnRegisterBtn" [value]="submit"/> -->
                    <input type="submit" [disabled]="disabled" class="btn enter-butt" [value]="submit">

                </div>
            </form>
        </div>

    </div>
</div>
<!-- Dheeraj banner  ends-->