import { Component, OnInit, Inject } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;  

@Component({
  selector: 'app-best-online-home-tuition',
  templateUrl: './best-online-home-tuition.component.html',
  styleUrls: ['./best-online-home-tuition.component.css']
})
export class BestOnlineHomeTuitionComponent implements OnInit {
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
       
  }

  createSEOService() {
    this.titleService.setTitle("What is Ziyyara | What's Online Education Platform");
     var desc="Ziyyara is an online tuition platform which gives you an opportunity of using a digital whiteboard via 1-on-1 live sessions to experience personalized learning"
      this.meta.updateTag({ name: 'description', content: desc })
      var keywords= "Ziyyara is an online tuition platform which gives you an opportunity of using a digital whiteboard via 1-on-1 live sessions to experience personalized learning"
      this.meta.updateTag({ name: 'keywords', content: keywords });
      var url=  location.pathname;
      this.meta_service.createCanonicalURL(url);
      this.meta_service.removeStructuredData();
     
   
  }

}
