
     
import { Component , Inject} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmationBoxComponent } from '../../../../learning/shared/components/confirmation-box/confirmation-box.component';
import { Store, select } from '@ngrx/store';
import { ApiResponse, AppState, IPROPERTY } from '../../../../interfaces';
import { Subscription } from 'rxjs';
import { AppService, HttpService } from '../../../../services';

import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { state } from '@angular/animations';
import { LoginComponent } from '../../../../learning/shared/components/login/login.component';
import { SignupComponent } from '../../../../learning/shared/components/signup/signup.component';
import * as AppActions from '../../../../store/actions/app.actions';
import { ModalSignUpComponent } from '../modal-sign-up/modal-sign-up.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
//import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-header-front',
  templateUrl: './header-front.component.html',
  styleUrls: ['./header-front.component.css']
})

export class HeaderFrontComponent {

  userType = 0;
  lselected;
  modalRef: BsModalRef;
  datanew: Observable<IPROPERTY>;

  constructor(  public dialog: MatDialog, private store: Store<AppState>,
    private appSer: AppService, private _api: HttpService, private _router: Router,
    private _route: ActivatedRoute,) {

      let ltype = localStorage.getItem("mylanguage");
      if (ltype == "ar") {
        this.lselected="ar"
      }else{
        this.lselected="en"
      }


  }



  ngOnInit() {
    window.scrollTo(0, 0);
    const token =  localStorage.getItem('token1');
    // this._api.getReqAuth('users/'+token).subscribe(

    //   (res: ApiResponse) => {
    //     this.store.dispatch(new AppActions.PropertySignup(res.result));
    //     localStorage.setItem('token1', res.result.auth_token);
    //  this.userType=res.result.u_type;
    //   });
    this.datanew = this.store.pipe(select<any>('applicationState'));
    this.datanew.subscribe((item) => {
      if (item && item['property']) {

        this.userType = item['property'].u_type;
      }
    });

    // if(!window.location.href.includes('contact')){
    //   if(!localStorage.getItem("ziyyara_token")){
    //     if(
    //       !(window.location.href).toString().toLocaleLowerCase().includes('login')
    //       && !(window.location.href).toString().toLocaleLowerCase().includes('signup')
    //       && !(window.location.href).toString().toLocaleLowerCase().includes(('get-started'))
    //       )
    //     setTimeout(()=> {
    //       this.openModalSignup();
    //     },5000);
    //   }
    
    // }
  }

  /****************open login page in ziyara ******************/

  login(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '425px',
      height: 'auto',

    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  signup() {
    const dialogRef = this.dialog.open(SignupComponent, {
      width: '425px',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  languageChanged($event) {
     localStorage.setItem("mylanguage", $event.value);
    location.reload();
    // ////console.log($event);
  }
   myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }
public isShown:boolean=false;
/* Set the width of the side navigation to 250px */
 openNav(x) {
  
   if(!this.isShown){
    x.classList.toggle("change");
    //console.log("menu bar clicked")
    document.getElementById("mySidenav").style.width = "250px";
    this.isShown=true;
  }
   else{
     this.closeNav(x);
 }  
}
/* Set the width of the side navigation to 0 */
 closeNav(x) {
  document.getElementById("mySidenav").style.width = "0";
  this.isShown=false;
  x.classList.toggle("change");
}

// openModalSignup(): void {
//   const dialogRef = this.dialog.open(ModalSignUpComponent, {
//     height: 'auto',
//     panelClass: 'dialog-container-custom',
    
//   });

//   dialogRef.afterClosed().subscribe(result => {
//   });

  
// }

navbarCollapsed = true;

  toggleNavbarCollapse() {
      this.navbarCollapsed = !this.navbarCollapsed;
  }
}
