import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-ranchi',
  templateUrl: './ranchi.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class RanchiComponent implements OnInit {
websiteSchema={

  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How much do I need to pay?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "It is decided after considering the age of your student, number of classes he/she wants to take, duration and occurrence of your teachings, etc. After considering all these factors, we prepare a customized package. No matter what the factors are, one thing we can the lowest tuition fees , which you can have while appointing a tutor in."
    }
  },{
    "@type": "Question",
    "name": "Can you tell the total online tutors in Ranchi available at Ziyyara?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara has a pool of Ranchi tutors for online tuition who are associated with us and offer the greatest excellence education. We all work harder to achieve our common organizational goal that is quality, quality and quality. At Ziyyara we have an uncompromising attitude when it’s about the quality."
    }
  },{
    "@type": "Question",
    "name": "Why should my children opt for the online tuition classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Taking assistance of an online tuition tutor in Ranchi aids you to master any subject more competently. All the tutors working with us share their knowledge and experience with us so that your children can achieve their academic goals. You can get in touch with us to discuss the details of your children so that we can create a customized plan and classes by home tutor in Ranchi."
    }
  },{
    "@type": "Question",
    "name": "Why should we choose your home tutors in Ranchi?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our main aim at Ziyyara is to offer the best superiority education to all the learners along with catering to the growing needs of the learners. Also, we want to help the parents who actively look for appointing the superlative online tutors in Ranchi. The classes provided related to tutoring in Ranchi by us are designed to suit the requirements of our students who want to thrive in the academic field."
    }
  }]

}
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

 ngOnInit() {
   
  this.titleService.setTitle("Online Home Tuition in Ranchi | Tutors in Ranchi | Home Tuition");
  var desc=" Get the best online home tutors in Ranchi by registering at Ziyyara. Avail 1 hour free online home tuition in Ranchi and help your children in achieving good marks."
   this.meta.updateTag({ name: 'description', content: desc })
  this.meta.updateTag({ name: 'keywords', content:"home tuition in ranchi, ranchi home tuition, home tutors in ranchi, home tutor in ranchi, home tuition in ranchi, home tuition for ranchi, tuition in ranchi, ranchi home tuition, Online tuition in ranchi, tuition online in ranchi, ranchi home tuition, ranchi online tuition, online home tuition in ranchi, home online tuition in ranchi, online home tuition for ranchi"})
   var url=  location.pathname;
   this.meta_service.createCanonicalURL(url);
   this.meta_service.removeStructuredData();
   this.slides = this.chunk(this.cards, 3);
   this.meta_service.insertSchema(this.websiteSchema);
    
  }






  cards = [
    {
      title: 'Ahmedabad',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
    },
  
    {
      title: ' Andaman Nicobar',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
    },
    {
      title: 'Arunanchal Pradesh',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
    },
    {
      title: 'Assam',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-assam.webp'
    },
    {
      title: 'Banglore',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-banglore.webp'
    },
    {
       title: 'Chandigarh',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
    },
    {
      title: 'Chennai',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-chennai.webp'
    },
    {
      title: 'Coimbatore',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
    },
    {
      title: 'Delhi',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-delhi.webp'
    },
    {
      title: 'Gujrat',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-gujrat.webp'
    },
    {
      title: 'Gurugram',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-gurugram.webp'
    },
    {
      title: 'Hyderabad',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
    },
    {
      title: 'Jaipur',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-jaipur.webp'
    },
    {
      title: 'Karnataka',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-karnataka.webp'
    },
    {
      title: 'Kerala',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-kerala.webp'
    },
    {
      title: 'Kolkata',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-kolkata.webp'
    },
    {
      title: 'Lucknow',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-lucknow.webp'
    },
    {
      title: 'Mumbai',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-mumbai.webp'
    },
    {
      title: 'Noida',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-noida.webp'
    },
    {
      title: 'Pune',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-pune.webp'
    },
    {
      title: 'Ranchi',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/cities/online-tuition-in-ranchi.webp'
    },
  
  ];
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
     
}
