import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-gujrat',
  templateUrl: './gujrat.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class GujratComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why appoint Ziyyara’s Gujarat home tutors?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Tutors are not a substitute for school, rather they help enhance your academic skills. Ziyyara’s online tutors in Gujarat are experts in their subject and most importantly are excellent communicators. We strongly believe that taking assistance of online Gujarat home tutors can make students more confident rather than trying to do things of their own. Our tutors follow a pedagogical way of teaching while providing online classes."
    }
  },{
    "@type": "Question",
    "name": "What can we expect for our child?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our coordinating team and tutors create a well-planned structure with an aim to lower the gap between our students and tutors. We look at the operations to ensure that the tutor is focussed not only on the syllabus goals, but also the student’s personal development. We believe that this structure keeps everyone working together to ensure the best outcomes by offering home online tuition in Gujarat."
    }
  },{
    "@type": "Question",
    "name": "Why is Gujarat tutoring so expensive?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The agenda behind establishing Ziyyara is to lower the cost of tuition so that no student gets deprived from getting quality education. We have years of experience that help get maximum satisfaction while delivering one to one live Gujarat online tuition."
    }
  },{
    "@type": "Question",
    "name": "Is online tutoring just for exams?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Many students and parents believe that online Gujarat home tutoring means taking extra classes at the time of exams only. However, this is not the case. We believe that as a compliment to education, online tuition classes are helpful while doing the revisions, but they actually help a lot in becoming a better learner. So rather than searching for an online tutor for Gujarat at the time when the exam is near, we should take regular Online tuition in Gujarat."
    }
  }]
}

cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }



  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
    this.titleService.setTitle("Online Home Tuition in Gujarat | Tutors in Gujrat | Home Tuition");
    this.meta.updateTag({name:'description', content:' Students who are looking for online home tuition in Gujarat for CBSE, IB and State Board? Register at Ziyyara and get tutored online from the best home tutors in Gujarat.'})
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta.updateTag({name: 'keywords', content:"home tuition in Gujrat, tuition in Gujrat, home tutor in Gujrat, home tuition in Gujrat, home tuition for Gujrat, tuition in Gujrat, Gujrat home tuition, Online tuition in Gujrat, tuition online in Gujrat, Gujrat home tuition, Gujrat online tuition, online home tuition in Gujrat, home online tuition in Gujrat, online home tuition for Gujrat, home tutor in Gujrat"})
    this.meta_service.insertSchema(this.websiteSchema);
 
  }

}
