<div class="modal-wrapper text-center">
    <div class="header-popup"> Join Group Class
      &nbsp;
      <a  class="cross-modal"><i class="fa fa-times"></i></a>
    </div>
    <div class="mt-2">
      <h2 class="modal-heading">
  
      </h2>
    </div>
    <div class="mb-2">
      <p class="modal-para p-2">
        <strong>Your session has been expired</strong>
      </p>
      <form [formGroup]="contactForm" (ngSubmit)="submitContactForm()" class="p-2">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group input-group">
              
              <input type="text" class="form-control" name="name" formControlName="name" placeholder="Enter your name"
                required>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group input-group">
             
              <input type="email" class="form-control" name="email" formControlName="email"
                placeholder="Enter your email" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group input-group">
              
              <input type="text" autocomplete="off" class="form-control" name="phone" formControlName="phone"
                placeholder="Enter your Mobile Number" required>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group input-group">
             
              <!-- <input type="text" class="form-control" name="country" formControlName="country"
                placeholder="Enter your country" required>  -->
                 <select  formControlName="country" placeholder="Country"  class="form-control" name="country" (change)="onSelect($event.target.value)">
                    <option value="0" [selected]="true">Select Country</option>
                    <option *ngFor="let country of countries" value= {{country.id}}>{{country.name}}</option>
                  </select> 

                 
            </div>
          </div>
        </div>
       
        <div class="row">
          <div class="col-md-6">
            <div class="form-group input-group">
             
              <input type="text" formControlName="profession" name="profession" class="form-control"
                placeholder="Enter your profession" required>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group input-group">
              
               <!-- <input type="text" formControlName="state" name="state" class="form-control"
                placeholder="Enter your state" required>  -->
                   <select  formControlName="state" placeholder="State" name="state" class="form-control" id='state' (change)="onSelectState($event.target)">
                    <option value="0" [selected]="true">Select State</option>
                    <option *ngFor="let state of states " value= {{state.id}} >{{state.name}}</option>
                  </select> 

                  

            </div>
          </div>
        </div>
        <div class="row">
            <div class="col-md-6">
              <div class="form-group input-group">
                
                <select formControlName="gender" placeholder="Gender" name="gender" class="form-control mtb15">
                  <option value="" disabled>Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>

                </select>

                
              </div>
            </div>
            <div class="col-md-6">
                <div class="form-group input-group">
                  
                  <input type="text" formControlName="subject" name="subject" class="form-control"
                    placeholder="Enter subject" required>
                </div>
              </div>
           
          </div>
        <!-- <div class="row" hide>
         
        </div> -->
        <div class="row">
          <div class="col-md-12">
            <div class="form-group input-group">
              
              <textarea message="msg" class="form-control" formControlName="message" name="message"
                placeholder="Write your message" cols="30" rows="4" required></textarea>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">

              <input type="submit" [disabled]="disabled" class="btn ored btn-block" value="SEND">

            </div>
            <div class="col-md-6">
              <input type="reset" class="btn btn-warning btn-block mrgin_top" value="Cancel">
            </div>
          </div>

        </div>
      </form>
    </div>
   
  
    <div class="button-wraper modal-btn-wrapper ">
      <div class="clearfix mt-3">
       
        
          <!-- <button class="btn btn-ziyyara-alt" mat-raised-button (click)="no()" type="button" mat-button>No</button> -->
        
      </div>
    </div>
  </div>