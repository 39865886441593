import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-cambridge-international',
  templateUrl: './cambridge-international.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class CambridgeInternationalComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Is taking online Cambridge International home tuition worthy?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, over the years, taking online tuition has emerged as the best way to support academic studies. Students taking online classes have witnessed significant improvement in their scores post sharing their concerns related to tutoring with online tutors offering tuition classes of Cambridge International. Online tutoring offers several benefits which include higher motivational level, better clarity of concepts, etc."
    }
  },{
    "@type": "Question",
    "name": "Who sets the exams?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Exams are prepared by the Cambridge Assessment International Education. The tests are conducted during February (in India only), May and October"
    }
  },{
    "@type": "Question",
    "name": "Who should refer to the Cambridge board?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Cambridge Board curriculum is more about intellectual development of pupils. It provides a wider opportunity to the learners to learn international course curriculum, and is best for the people who consider shifting abroad in the future. Getting education adhering to this board format is more like being a part of a global learning community."
    }
  },{
    "@type": "Question",
    "name": "What is the method to enroll at Ziyyara?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Initially you need to register at Ziyyara to book a free demo for online CBSE home tuition"
    }
  },{
    "@type": "Question",
    "name": "What is Cambridge International board full form?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Cambridge Assessment International Education is one of the huge providers of international education courses and qualifications, which has gained global acceptance. Under this board, teachings are offered to students aged 5- to 19-year-old."
    }
  },{
    "@type": "Question",
    "name": "Is Cambridge Board standardized in India?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, the board has received recognition by CBSE, CISCE and state education boards across the country. Students who have studied as per Cambridge International board syllabus will be considered fit for higher secondary education courses and programs offered by prestigious colleges and universities located in India. It is also a recognized Class X qualification, which is a prerequisite for doing class 11 and 12th studies before pursuing any undergraduate course in India."
    }
  },{
    "@type": "Question",
    "name": "My child is very weak in mathematics, will taking online Cambridge International home tuition help?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Of course, yes, students can improve their skills if they do regular practice. Practicing more will help students to solve questions at the earliest. Besides, Ziyyara’s online Cambridge International home tutor helps all the students studying in Cambridge International schools in India to solve their mathematical questions and problems quickly."
    }
  }]
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {

   this.titleService.setTitle("  Online Home Tuition For Cambridge International Board | Online Tutor");
   this.slides = this.chunk(this.cards, 3);
   var desc="Looking for online home tuition for Cambridge International Board? Try Ziyyara’s Cambridge Board tuition classes which offer personalised online live learning";
    this.meta.updateTag({ name: 'description', content: desc })
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta.updateTag({ name: 'keywords', content: "cambridge board, tutor Cambridge board, what is Cambridge International, Cambridge board tutor, cambridge board tuition, tuition for cambridge board, Cambridge board in India, Cambridge International home tutor, Cambridge International home tuition tutor, tuition for Cambridge International classes, home tuition for Cambridge International classes, Cambridge International classes tuition classe, Cambridge International tutor, Cambridge International home tuition "})
    this.meta_service.insertSchema(this.websiteSchema)
  }
  
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  
  
  

  tutorData=[  {
    name:"Pragya Saxena",
    exp:" 10 years ",
    grade:"1st to 10th "
  },{
    name:"Anmol Panvanda",
    exp:"8 years",
    grade:"9th to 12th "
 },{
    name:"Ruchika Sharma",
    exp:"6 years",
    grade:"1st to 8th "
  
  }

]
  cards = [
    {
      title: 'IB Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-ib-board.webp'
    },
    {
      title: 'ICSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-icse-board.webp'
    },
    {
      title: 'CBSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cbse-board.webp'
    },
    {
      title: 'ISC Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-isc-board.webp'
    },
    {
      title: 'IGCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-igcse-board.webp'
    },
   
    {
      title: 'CISCE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cisce-board.webp'
    },
    {
      title: 'GCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-gcse-board.webp'
    },
    {
      title: 'Cambridge International-Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cambridge-international-board.webp'
    },
  
  ];
  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase().replace(" ","-");
    return '/home-tuition/online-home-tuition-for-'+subject;
  }
 
}
