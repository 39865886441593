// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

 // baseUrl: `http://localhost:3000/`, when u do locally replace this 

export const environment = {
// baseUrl: `http://localhost:4000/`, 
baseUrl: `https://appi.ziyyara.in/`,

// baseUrl: `https://appi.ziyyara.in/`,

production: false,
 stripeKey: '',
 accessKeyId: 'AKIAJ4RA5DG2HCQGNL5Q',
 secretAccessKey: '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',
 region: 'ap-south-1',
 bucket: 'zyrb/user_display',
 hotelMaxImage: 10,
 roomMaxImage: 10,
 baseHref: '/'
}; 

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.







