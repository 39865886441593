import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-bangalore',
  templateUrl: './bangalore.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class BangaloreComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Can I take as many free demos as I want?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You are allowed to take one demo class for one subject related to online home tuition in Bangalore class. If it goes well and you are completely satisfied with the teaching style, you can enroll at Ziyyara and begin your online tuition journey by enrolling for the classes."
    }
  },{
    "@type": "Question",
    "name": "Do I need to refer to the same tutor which is assigned initially?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Not every time. You are suggested to get in touch with us in case you want a replacement. But before taking a decision related to home online tuition in Bangalore, we would advise you to get in touch with our coordinating team."
    }
  },{
    "@type": "Question",
    "name": "Do your teachers undergo verification for providing Bangalore online tuition classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "All online home tutors in Bangalore who are expert in delivering online classes undergo a detailed selection process based on which we finalize them. Still if you have any doubt regarding the credentials of the tutor, you are welcomed to get in touch with our coordinating team. We feel delighted while serving."
    }
  },{
    "@type": "Question",
    "name": "In case a few doubts are not answered, what should I do?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Not an issue. You can get in touch with us via multiple options. You can directly call our coordinating team or can drop an email writing your doubts or concerns. All of your queries will be answered at the earliest."
    }
  },{
    "@type": "Question",
    "name": "Do Ziyyara’s online Bangalore home tutor give notes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "At Ziyyara, our tutors provide education material in PDF form while delivering the classes, which you can check online. Else you can ask for a recording of a particular class to deeply understand the concepts."
    }
  }]
}

cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
   this.titleService.setTitle("  Online Home Tuition in Banglore | Tutors in Banglore | Tuition in Banglore");
  
 
   var desc="Online home tuition in Bangalore is the need of the hour as school learning is not enough, thus students are seeking online home tutors in Bangalore to clear their concepts. "
    this.meta.updateTag({ name: 'description', content: desc })
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta.updateTag({name:'keywords', content: "home tuition in bangalore, home tutors in bangalore, tuition in bangalore, bangalore home tuition, home tutor in bangalore, tutors in bangalore, , home tuition in bangalore, home tuition for bangalore, tuition in bangalore, bangalore home tuition, Online tuition in bangalore, tuition online in bangalore, bangalore home tuition, bangalore online tuition, online home tuition in bangalore"})
        this.meta_service.insertSchema(this.websiteSchema);
    this.slides = this.chunk(this.cards, 3);
  }
   generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase()
    if(subject.includes("andaman")){
      return '/home-tuition/online-home-tuition-in-andaman-nicobar'
     }
else if(subject.includes("arunanchal")){
      return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
     }

     else {return '/home-tuition/online-home-tuition-in-'+subject }
  }
}
