import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';
import { CommonComponentModule } from '../common-component/common-component.module';

import { OnlineHomeTuitionsForAllSubjectsComponent } from './online-home-tuitions-for-all-subjects/online-home-tuitions-for-all-subjects.component';


const pageRoutes: Routes = [

  { path: '', component: OnlineHomeTuitionsForAllSubjectsComponent },

];

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    SharedModule,
    CommonComponentModule,
    RouterModule.forChild(pageRoutes),

  ],
  declarations: [OnlineHomeTuitionsForAllSubjectsComponent],
  entryComponents: []
})
export class OnlineHomeTuitionsForAllSubjectsModule { }


