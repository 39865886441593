<!-- popup for image -->
<div class="wrapper">
    <style>
        .wrapper {
            position: relative;
        }
    </style>
    <div class="modal-header" style="position: absolute; right: 10px;">
        <button type="button" class="close" data-dismiss="modal" (click)="onNoClick()">&times;</button>
    </div>
    <a href="https://ziyyara.com/spin-and-win/">
        <img width="100%" src="https://ziyyara.com/assets/images/logo/popup.png" alt="spin-and-win">
    </a>
</div>
<!-- end popup for image -->




<!-- form popup model -->


<!-- <ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">&nbsp;</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="assets/images/Green-Round-Trick.png" alt="">
            <h1>You are almost set!</h1>
            <p>Thanks for submitting the form, please Signup to enjoy the benefits of 1 free tuition class</p>
    
        </div>
        <button type="button" class="btnRegister2" (click)="signup(template)">Sign Up</button>
    </div>
</ng-template>
<div class="sign-up-form">
    <div class="modal-space">
        <button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
        <div class="container-popup">
            <div class="formWrap">
                <a href="https://ziyyara.com/refer-a-friend"> <img width="100% !important" src="assets/images/HAPPY HALLOWEEN.png" alt="">
                </a>
           

                <div class="popup" style="display:none;">
                    <form [formGroup]="signupForm" class="reg-form" autocomplete="off" (ngSubmit)="submitUser()">
                        <h3>Book a Free Class Now </h3>
                
                        <div class="icon-Btn dspl-for-mobile">
                            <a href="https://www.youtube.com/channel/UCzf9w3sDmfXEG_vZt3x285Q" rel="nofollow">
                                <div class="google icon"><i class="fab fa-youtube"></i></div>
                            </a>
                            <a href="https://www.instagram.com/ziyyaraindia?r=nametag" rel="nofollow">
                                <div class="instagram icon"><i class="fab fa-instagram"></i></div>
                            </a>
                            <a href="https://twitter.com/ZiyyaraLearning" rel="nofollow">
                                <div class="twitter icon"><i class="fab fa-twitter"></i></div>
                            </a>
                            <a href="https://www.facebook.com/Ziyyara_learning-304325823599424/?modal=admin_todo_tour" rel="nofollow">
                                <div class="facebook icon">
                                    <i class="fab fa-facebook"></i></div>
                            </a>
                        </div>
                        <hr>


                        <div class="form-f">
                            <i class="fas fa-user"></i>
                            <input type="text" placeholder="Enter Student Name*" [formControl]="name" [maxlength]="CONFIG?.NAME_LENGTH">
                        </div>
                        <mat-error class="text-center">{{_util.errorMessage(name,FORM_ERROR?.name)}}</mat-error>

                        <div class="form-f">
                            <i class="fas fa-envelope"></i>
                            <input type="text" formControlName="email" placeholder="Enter Your Email Id*" />
                        </div>
                        <mat-error class="text-center">{{_util.errorMessage(email,FORM_ERROR?.email)}}</mat-error>

                        <div class="form-f">
                            <i class="fas fa-globe"></i>
                            <mat-select placeholder="Search country code" (selectionChange)="onChangeCode($event)" [formControl]="countryCode" #singleSelect>
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="bankFilterCtrl" [placeholderLabel]="placeholderLabel" [noEntriesFoundLabel]="noEntriesFoundLabel">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank">
                                    {{bank.name}}
                                </mat-option>
                            </mat-select>
                       
                        </div>
                        <mat-error class="text-center"> {{_util.errorMessage(countryCode,FORM_ERROR?.countryCode)}}</mat-error>

                        <div class="form-f">
                            <i class="fas fa-phone"></i>
                            <input appOnlyNumber [formControl]="contactNo" placeholder="Enter Mobile no*" [maxlength]="CONFIG?.MOBILE_LENGTH">
                        </div>
                        <mat-error class="text-center"> {{_util.errorMessage(contactNo,FORM_ERROR?.contactNo)}}</mat-error>

                        <div class="form-f">
                            <i class="fa-solid fa-person-simple"></i>
                        </div>
                        <div class="checkBox">
                            <input type="checkbox" name="checkbox">
                            <span class="text"> I Agree with the Term & Conditions.</span>
                        </div>

                        <button class="btn ">Book Now</button>
                    </form>
                </div>



                <div class="book" style="display:none;">

                    <button class="close" type="button" [mat-dialog-close]="true">&times;</button>

                    <div class="followup">
                        <img src="/assets/images/newimag.png" alt="">

                    </div>

                    <div class="icon-Btn">
                        <a href="https://www.youtube.com/channel/UCzf9w3sDmfXEG_vZt3x285Q" rel="nofollow">
                            <div class="google icon"><i class="fab fa-youtube"></i></div>
                        </a>
                        <a href="https://www.instagram.com/ziyyaraindia" rel="nofollow">
                            <div class="instagram icon"><i class="fab fa-instagram"></i></div>
                        </a>
                    </div>
                    <div class="icon-Btn">
                        <a href="https://twitter.com/ZiyyaraLearning" rel="nofollow">
                            <div class="twitter icon"><i class="fab fa-twitter"></i></div>
                        </a>

                        <a href="https://www.facebook.com/ZiyyaraIndia" rel="nofollow">
                            <div class="facebook icon">
                                <i class="fab fa-facebook"></i></div>
                        </a>

                    </div>

                </div>

            </div>
        </div>

    </div>
</div> -->
<!-- end form popup model -->