import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';

declare var Swal: any;
@Component({
  selector: 'app-pune',
  templateUrl: './pune.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css'],
 
})
export class PuneComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why do people prefer home tuition in Pune?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The benefits of taking Pune home tutoring are presented below: a) To assist students in understanding the concepts easily and accurately. b) To enhance the learning capabilities of the students and help them know their strengths and weaknesses. c) Appointing Ziyyara’s Pune tutor for online tuition is quite economical and doesn't put a pressure on the students. d) Students can take classes from their home. We use whiteboards in all the lectures."
    }
  },{
    "@type": "Question",
    "name": "Which is better, live interactive Pune home tuition or taking classes in small groups?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Majority of the time, while talking about Pune home tutoring it's better to take one to one tuition classes as it helps clear the concepts in a more detailed way. At Ziyyara, we offer live one-on-one online classes to all the learners who are looking for tuition online in Pune. We don’t offer group classes as it makes it difficult for our students to understand the concepts, neither our Pune online home tutors are able to offer attention to all the students. We strongly believe that in case you are investing in online home tuition in Pune one to one tuition is the way to go. The advantages of Pune home tutoring allow our students to take personalized classes, moving at their own pace can’t be overstated."
    }
  },{
    "@type": "Question",
    "name": "Which are the popular subjects among the students taking classes from home tutors in Pune?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We offer Pune online tuition to all children studying in classes from 5 to 12. Based on their strengths and weaknesses, children can choose subject-wise classes from the experts."
    }
  }]
}








cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 



  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

 ngOnInit() {
  this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle( " Online Home Tuition in Pune | Home Tutor in Pune | Tuition in Pune");
  var desc="  At Ziyyara, get an experienced online home tutor in Pune for your child.  We offer online home tuition in Pune with a complete solution to the students of Class 5 to 12."
  
   this.meta.updateTag({ name: 'description', content: desc })

   this.meta.updateTag({name:"keywords", content: "home tuition in pune, tuition in pune, home tutors in pune, home tutor in pune, tutors in pune, home tuition in pune, home tuition for pune, tuition in pune, pune home tuition, Online tuition in pune, tuition online in pune, pune home tuition, pune online tuition, online home tuition in pune, home online tuition in pune"})
   var url=  location.pathname;
   this.meta_service.createCanonicalURL(url)
   this.meta_service.removeStructuredData();
   this.meta_service.insertSchema(this.websiteSchema)
    
  
  }


  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase()
    if(subject.includes("andaman")){
      return '/home-tuition/online-home-tuition-in-andaman-nicobar'
     }
else if(subject.includes("arunanchal")){
      return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
     }

     else {return '/home-tuition/online-home-tuition-in-'+subject }
  }

}
