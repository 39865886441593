import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-ib',
  templateUrl: './ib.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css','../icse/icse.component.css']
})
export class IbComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "For whom MYP Program is planned and are your professors qualified enough?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara’s online IB classes tuition is provided by a proficient IB tutor for MYP curriculum. Every IB MYP tutor comes with years of teaching experience and knows the art of making not so popular boards familiar to the students. Since the middle years program (MYP) is designed for the students of age group 11 years to 16 years, so our IB tutors work tougher to make education more fun filled. MYP is a five-year course and is prepared for the students studying in classes 6th- 10th."
    }
  },{
    "@type": "Question",
    "name": "What is IB board full form?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "IB full form is International Baccalaureate (IB) which was earlier popular as the International Baccalaureate Organization (IBO), is a non-profit foundation located in Switzerland. The board came into existence in 1968 offering different programmes to the students studying in schools that are approved by the International Baccalaureate."
    }
  },{
    "@type": "Question",
    "name": "What’s the responsibility of your coordinating team?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The coordinating team and tutors of online tuition for IB Board suggest students do self-assessment so that they can know their stronger and weaker areas. Also, the team helps pupils in analyzing different career opportunities and understand the pros linked with this IB Board. Hence, we allow our students taking online IB tuition classes to get in touch with the coordinating team so that all doubts and queries get rectified earliest."
    }
  },{
    "@type": "Question",
    "name": "What is a Diploma Course?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Diploma Programme (DP) is a division of IB. The International Baccalaureate (IB) Diploma Programme (DP) is for all the pupils who fall in the age group of 16-19. This course is supportive for the students in getting insights of all the topics and subjects linked to it."
    }
  },{
    "@type": "Question",
    "name": "What is the method to enroll at Ziyyara for online tuition for IB Board?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Initially you need to register at Ziyyara to book a free demo class from our online tutor for IB Board."
    }
  },{
    "@type": "Question",
    "name": "Is IB Board better in regard to CBSE?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "IB is more engrossed on imparting applied and application-based learning to the pupils. Even the evaluations are taken to assess the student's knowledge & intelligence, rather than considering their speed. However, there are several other boards that lay focus on testing students’ remembrance and swiftness. IB pedagogy focuses on 'how to learn' instead of what is being learned. Know more about this board by taking Ziyyara’s tuition classes of IB."
    }
  }]
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }
  
  tutorData=[  {
    name:"Aparna Thakur",
    exp:" 10 years ",
    grade:"8th to 11th "
  },{
    name:"Tajneet kaur",
    exp:"3 years",
    grade:"9th to 11th "
  },{
    name:" Tejal Gupta",
    exp:"2 years",
    grade:"6th to 12th "
  
  }
  
  ]
  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle("Online Tuition For IB Board | Tuition for IB Board Students");
   var desc=" Online tuition for IB board at Ziyyara can be accessed at any time. All IB tutors appointed by us have the latest information related to the course program."
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ name: 'keywords', content:"IB Board, what is ib board, ib board syllabus, ib board in india, ib schools in india, ib tutor, ib home tutor, Online Tutors For Ib, ib home tutors, ib tutor at home, ib tution tutor, ib private tutor, Tuition For IB students, Private Tuition For IB board"})
    var url=  location.pathname;

    var ogtitle="Online Tuition For IB Board | Tuition for IB Board Students";
    this.meta.updateTag({ property: 'og:title', content: ogtitle });
   var ogdesc="   Online tuition for IB board at Ziyyara can be accessed at any time. All IB tutors appointed by us have the latest information related to the course program.";
   this.meta.updateTag({ property:'og:description', content: ogdesc });
 
   var twittertitle="Online Tuition For IB Board | Tuition for IB Board Students";
   this.meta.updateTag({ name:"twitter:title", content: twittertitle });
  var twitterdesc="   Online tuition for IB board at Ziyyara can be accessed at any time. All IB tutors appointed by us have the latest information related to the course program.";
  this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
 
  }
  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
  
  cards = [
    {
      title: 'IB Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-ib-board.webp'
    },
    {
      title: 'ICSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-icse-board.webp'
    },
    {
      title: 'CBSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cbse-board.webp'
    },
    {
      title: 'ISC Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-isc-board.webp'
    },
    {
      title: 'IGCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-igcse-board.webp'
    },
   
    {
      title: 'CISCE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cisce-board.webp'
    },
    {
      title: 'GCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-gcse-board.webp'
    },
    {
      title: 'Cambridge International-Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cambridge-international-board.webp'
    },
   
  
  
  ];
  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase().replace(" ","-");
    return '/home-tuition/online-home-tuition-for-'+subject;
  }
}
