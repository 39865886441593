import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-near-me',
  templateUrl: './near-me.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css','./near-me.component.css']
})
export class NearMeComponent implements OnInit {
 
  
 

  tutorData=[  {
    name:" Ruchi Narang",
    exp:" 10 years ",
    grade:"8th to 12th "
  },{
    name:"Akriti dhyani",
    exp:"2 years",
    grade:"1st to 8th "
  },{
    name:" Ridhi Arora",
    exp:"2 years",
    grade:"1th to 8th "
  
  }
  
  ]
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Will taking Ziyyara’s online tuition near me classes are best for my kid?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Before you book Ziyyara’s tuition near me at our platform, it would be grateful if you provide the exact needs of your child.  For instance whether your child requires an online tutor for maths or English Literature, must be known to us. During the online classes, a student can ask tutors any questions for which they need assistance."
    }
  },{
    "@type": "Question",
    "name": "Why does Ziyyara's online tutoring play an important role?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Online tutoring at Ziyyara allows school children to learn at their own pace rather than winding up with the syllabus. Some children are too shy to ask questions during the classes, so taking one to one online classes will help them a lot. The benefit of Ziyyara’s one-to-one home tuition near me allows students to ask all the questions they want, and can understand all the topics in a hassle free way."
    }
  },{
    "@type": "Question",
    "name": "Do you think taking online tutoring is worthwhile, especially when we want to stay competitive?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Online tutoring provided by Ziyyara’ home tutors near me allows children of all cases to explore their potential without worrying about learning gaps. Whether your little ones require assistance in academics or in boosting his/her confidence level, our tutors guide them through tricky topics and help improve their self-belief."
    }
  },{
    "@type": "Question",
    "name": "How can I search for a tutor at Ziyyara’s platform?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Searching for a tutor at Ziyyara for online tuition classes near me is quite easy. Whatever your needs and requirements are, you need to mention them while enrolling at our platform. We will then look for the best teachers who can help you in achieving your academic goals."
    }
  },{
    "@type": "Question",
    "name": "Does Ziyyara use a whiteboard while providing online classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our coordinating team has created our online lessons based on the latest education trends. We use an interactive whiteboard at our online tuition centre near me allowing school children to talk to their mentors and understand even the tricky concepts easily."
    }
  }]
}


cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 ngOnInit() {
  this.slides = this.chunk(this.cards, 3);
  this.titleService.setTitle("Online Tuition  Near Me | Tuition Near Me | Tutoring Near You");
  var desc=" Ziyyara provides live online home tuition near me for all subjects of all boards. We have a pool of best online home tutors near me who provides online tuition classes near me at the comfort of your home."
   this.meta.updateTag({ name: 'description', content: desc })
   var url=  location.pathname;
  var keywords="Online tuition near me, home tuition near me, online tutoring near me, online tutor near me, home tutor near me, Home tuition near me, tuition centre near me, tuition classes near me, online tutoring sites near me, private tuition near me, private tutor near me, home tutor site near me, home tuition near me, tution at home near me, home tuitions near me, home tutors near me,tuition near me, home tuition near me, tuition classes near me, tuition center near me, tuition centres near me, tuition class near me"
   this.meta.updateTag({ name: 'keywords', content: keywords });
   
   this.meta_service.createCanonicalURL(url)


   this.meta_service.removeStructuredData();
   this.meta_service.insertSchema(this.websiteSchema);
    
  }

  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase()
    if(subject.includes("andaman")){
      return '/home-tuition/online-home-tuition-in-andaman-nicobar'
     }
else if(subject.includes("arunanchal")){
      return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
     }

     else {return '/home-tuition/online-home-tuition-in-'+subject }
  }
}
