import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-geography',
  templateUrl: './geography.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class GeographyComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "1. Why do students prefer online tuition for geography?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Since Geography is known as the field of science which explains the relationship of the Earth and planets with the study of the lands, features and it’s inhabitants, therefore getting an online Geography tutor who explains them all with a proper explanation is all what a student looks for."
    }
  },{
    "@type": "Question",
    "name": "2. Which is the best site to learn online tuition for geography?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Undoubtedly, Ziyyara is the best website for Geography online classes. The one-on-one live & interactive online learning helps the students to clear their doubts then and there without any peer pressure."
    }
  },{
    "@type": "Question",
    "name": "3. Is there any best tutor who can teach geography online?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara has the pool of experienced tutors and in case a student doesn’t understand the first geography tutor, then we can replace that tutor with another."
    }
  },{
    "@type": "Question",
    "name": "4. Can Ziyyara help me to learn geography online?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our teachers are extremely cordial and use child centric teaching methodology to impart the subject knowledge. The supportive nature of our geography tutors helps the students which motivates them to do their best throughout the learning journey"
    }
  },{
    "@type": "Question",
    "name": "5. Advantages of online Geography tuition classes at Ziyyara.",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "a)	One-to-one online learning b)	Live interactive audio/video classes c)	Use of whiteboard enable the student & tutor to write d)	Availability of doubt classes e)	Pay as you go feature"
    }
  }]
}
cards = [
  {
    title: 'Economics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Economics.jpg'
  },
  {
    title: 'Maths',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Mathematics.jpg'
  },
  {
    title: 'English',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/English.jpg'
  },
  {
    title: 'Physics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Physics.jpg'
  },
  {
    title: 'History',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/History.jpg'
  },
  {
    title: 'Hindi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Hindi.jpg'
  },
  // {
  //   title: 'Geography',
  //  // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
  //   buttonText: 'Read',
  //   img: '/assets/images/Geography.jpg'
  // },
  {
    title: 'Business Studies',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Business-Studies.jpg'
  },
  {
    title: 'Biology',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Biology.jpg'
  },
  {
    title: 'Political-Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Political-Science.jpg'
  },
  {
    title: 'Accountancy',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Accountancy.jpg'
  },
  {
    title: 'Chemistry',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Chemistry.jpg'
  },

  {
    title: 'Computer Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Computer-Science.jpg'
  },
  {
    title: 'Vedic Mathematics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/vedic-math.webp'
  },
];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }


  ngOnInit() {
    //slider
 this.slides = this.chunk(this.cards, 3);
  this.titleService.setTitle(" Online Tuition for Geography | Online Geography Tuition Classes");
  this.meta.updateTag({name:'description', content:' Looking for online geography tuition classes? Try Ziyyara’s one-on-one live online tuition for geography from experienced tutors. For a free demo call us on +91 9654271931. '})
  var keywords="geography tutor, geography online classes, online geography classes, online geography, Geography home tutor, tutor for Geography, Geography home tutor, Geography tutor at home, Geography tution tutor, Geography private tutor, private tutor for Geography, Online tuition for Geography, Geography online tuition, tuition for Geography, Geography tuition classes";
  this.meta.updateTag({ name: 'keywords', content: keywords });
  var url=  location.pathname;

  var ogtitle="Online Tuition for Geography | Online Geography Tuition Classes";
  this.meta.updateTag({ property: 'og:title', content: ogtitle });
 var ogdesc="  Looking for online geography tuition classes? Try Ziyyara’s one-on-one live online tuition for geography from experienced tutors. For a free demo call us on +91 9654271931.   ";
 this.meta.updateTag({ property:'og:description', content: ogdesc });

 var twittertitle="Online Tuition for Geography | Online Geography Tuition Classes";
 this.meta.updateTag({ name:"twitter:title", content: twittertitle });
var twitterdesc="  Looking for online geography tuition classes? Try Ziyyara’s one-on-one live online tuition for geography from experienced tutors. For a free demo call us on +91 9654271931.";
this.meta.updateTag({ name:'twitter:description', content: twitterdesc }); 
  this.meta_service.createCanonicalURL(url);
  this.meta_service.removeStructuredData();
  this.meta_service.insertSchema(this.websiteSchema);

}
  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase()
    if(subject.includes("vedic"))
    
    {
      return "/vedic-math-workshop"
    
    } 
      else if (subject.includes("computer")){
        return '/home-tuition/online-home-tuition-for-computer-science'
      }
      else if (subject.includes("business")){
        return '/home-tuition/online-home-tuition-for-business-studies'
      }
    else {

      return '/home-tuition/online-home-tuition-for-'+subject
    }
  
  }
}
