export const en = {
    video_url : "https://www.youtube.com/embed/TgcHCmQ94Do?rel=0&autoplay=1",
    login : {
        login_title:"Login",
        email_title:"Email",
        password_title:"Password",
        forgot_password:"Forgot password?",
        login_google_stu:"Login with Google as student",
        login_google_tutor:"Login with Google as Tutor",
        dont_have_account:"Don't have an account?",
        reister_title:"Register",
    },
    adcontact:{
        title:'Book Free Online Tuition Class Now',
        welcome:'Welcome',
        welcome_msg:"You are 30 seconds away from booking your child's",
        parentEmail:"Parent's Email Id",
        parentMobile:"Parent's Mobile no.",
        kidsName:"Kid's Name",
        parentName:"Parent's Name",
        kidsGrade:"Kid's Grade",
      question:"Do you have a Laptop or PC at home for your kid's class?",
      submit:"Submit"

   
      },
    signup:{
        register:"Register",
        ful_name:"Full Name",
        email:"Email",
        reg_as_student:"Register as Student",
        reg_as_tutor:"Register as Tutor",
        password:"Password",
        confirm_password:"Confirm Password",
        already_registered:"Already Registered?",
        login_title:"Login",
        password_and_confirm_not_match:"Password and confirm password does not match",
        countryCode:"CountryCode",

    },
     
    header: {
        quote: "Try Ziyyara for",
        free: "FREE",
        registernow: "REQUEST A FREE DEMO",
        login: "Login",
        register: "Register",
        arabic_text:"Arabic"
    }, 
    home: {
        label: "Ziyyara: Tuitions made Simple ",
        sub_label:"connect us to make your life simple.",
        t_life: "Teaching for Life",
        t_life_sub: "Interactive Whiteboard",
        t_tommorow: "Teaching for Tomorrow",
        t_tommorow_sub: "One to one Video Chat",
        t_knowledge: "Teaching for Knowledge",
        t_knowledge_sub: "Choose your own Tutor",
        find_tutor: "Find Tutor",
        find_sub: "Find subjects",
        education_text: "Delivering Education",
        education_content: "Ziyyara is a One-on-One LIVE online tuition platform where students and teachers connect regardless of geography.",
        education_text_link: "I am ready to learn",
        anywhere_text: "Online Tuition Anytime",
        anywhere_content: "At sunrise or at sunset, spend some time doing what matter to you and we'll take care of the rest.",
        anywhere_text_link: "Let's go",
        foryou_text: "Ziyyara is here for you",
        foryou_content: "Online Studying is made fun and easy. We deploy a need-based approach that allows parents and students to plan their schedule as per their requirement along with periodic assessments.",
        foryou_text_link: "Get started",
        howitworks: "How it works?",
        findtutor: "Find your tutor",
        needthem: "A huge selection of tutor whenever you need them",
        booksession: "Book a session",
        anytime: "Anytime, Anywhere, Anyplace",
        startlearning: "Start learning",
        easyas: "As easy as 1,2,3",
        readytolearn: "Ready to Learn",
        find_tutor_down: "Find Tutor",
        find_sub_down: "Find subjects",
    },
    footer: {
        company: "Company",
        about: "About Us",
        howitworks: "How it works",
        careers: "Career",
        explore: "Explore",
        students: "Student's",
        tutor: "Tutor's",
        faq: "FAQ's",
        reachus: "Reach Us",
        omanaddresstitle: "Oman Address",
        address: "Flat 301, Al Izz Building, Al Barakat Street, Al Hail South, Muscat, Oman",
        indadresstitle: "India Address",
        indadress: "C-360, LGF, Defence Colony South Delhi New Delhi – 110024 India",
        generalenquires: "General Inquiries",

        t_c: "Terms and Conditions",
        policy: "Privacy Policy",
        copyright: {
            ziyyara: "Ziyyara",
            reserved: "All Rights Reserved",
        },
    },

    our_selective_tutors:{

      title:"Some Of Our Selective Tutors",
      known_languages_title:"Known Languages",
      subjects_title:"Subjects: ",

      tutorFirst:{
      name:"Prabhmeet Singh",
      country:'India ',
      subjects:'Mathematics, Physics, Science',
      description:'I work as lecturer in college .My subjects are maths ,physics.',
      known_languages:'English'
    },
   
    tutorSecond:{
      name:"Nidhi",
      country:'India ',
      subjects:' Mathematics ,Chemistry,Biology, Physics ,Science',
      description:' I have more than 5 years experience in teaching and I am a certified, trained teacher from UNIVERSITY OF DELHI',
      known_languages:' English'
    },
    tutorThird:{
      name:"Priyanka",
      country:'India ',
      subjects:'Mathematics, English',
      description:'I am Priyanka, with teaching Experience of 5 years. My agenda and technique focuses on Transformative learning along with the subject Knowledge. Keen focus is to maintain the interest of the student',
      known_languages:' English'
    },
  
  
    tutorFourth:{
      name:"Bhavya Sachdeva", 
      country:'India ',
      subjects:'French',
      description:'I love speaking and teaching french which is such a beautiful, soft and melodious language. I am a research scholar of french literature with more than 8 years of experience of teaching French.',
      known_languages:' English, French,'
    },
    tutorFifth:{
      name:"Sonia Sharma",
      country:'India',
      subjects:'Mathematics, English, Social Science, Hindi',
      description:'Have experience in teaching students of IB, CBSE, GSC, ICSE board for all subjects till class 8 and History, Civics and Geography till class 12',
      known_languages:' English, Hindi'
    },
  
    tutorSix:{
      name:"Manju Shaji",
      country:'India ',
      subjects:' Mathematics, Chemistry , Biology, Physics',
      description:'An Efficient And Experienced Teacher Who Is Capable Of Changing The Teaching Methodology As Per The Needs Of The Students.',
      known_languages:' English, Hindi'
    },
  
    // tutorSeven:{
    //   name:"Dina Elwazeery",
    //   country:'Egypt ',
    //   subjects:'English',
    //   description:' Qualified English tutor with more than 10 years of experience and native Arabic speaker. I love teaching kids at foudnation.',
    //   known_languages:'English, Arabic'
    // },
    tutorEight:{
      name:"Aman Kumar Mishra",
      country:'India ',
      subjects:'Mathematics, English,  Chemistry,  Physics ',
      description:'I try to help both struggling and gifted students reach their full potential by supplementing the instructions they receive in class and guiding them towards study practices and aides that can help.',
      known_languages:'  English, Hindi'
    },
  
    // tutorNine:{
    //   name:"Heba Moumtaz",
    //   country:'Egypt ',
    //   description:'Heba is Arabic teacher with an experience of 8 years and have an experience in teaching Arabic online andcan teach Egyptian Arabic or Quran Tajweed.',
    //   subjects:'Arabic',
    //   known_languages:' English, Arabic'
    // },

    tutorTen:{
      name:"Milanka Andjic", 
      country:'Montenegro ',
      subjects:'English',
      description:"Hello.My name is teacher Mika. I've been teaching English for more than ten years.I graduated English language and literature and since than I've been teaching English at school.",
      known_languages:' English'
    },
    tutorEleven:{
      name:" Ankita Sood", 
      country:'India ',
      subjects:'English',
      description:'Have been teaching creative writing, English literature and English Grammar for all boards CBSE, ICSE, IB and have taught kids in USA, AUSTRALIA, UK.',
      known_languages:' English'
    },

    tutorTwelve:{
      name:" Asmaa Mohammed Elmeslmany", 
      country:'Egypt ',
      subjects:'Spanish, Arabic, Quran',
      description:' معلمة قرآن و لغة عربية حاصلة علي ليسانس دراسات اسلامية و عربية خاتمة للقران وحاصلة علي اجازة بسند متصل بقراءة عاصم برواية حفص و شعبة و اجازة في متني التحفة و الجزرية و تعليم النحو بطريقه الحدوتة النحو',
      known_languages:'Arabic, Spanish'
    },

    tutorThirteen: { 
      name:"Hebat Allah Sabry Galeb", 
      country:'Egypt ',
      subjects:' Quran, Arabic',
      description:' ليسانس دار علوم وحاصلة علي دبلوم جامعة الامريكية بالفاهرة لتدريس اللغة العربية لغير الناطقين بهاوحاصلة علي دورات تجويد باللغة الانجليزي',
      known_languages:'Arabic, English'
    },
  tutorFourteen :{
    name: "Arjoo Agrawal",
    country:"India",
    subjects:"Mathematics",
    description:" Best CBSE Maths tutor imparting the knowledge of mathematical formula’s to the students of class 6-9"
   , known_languages:'English'
  },
  tutorFifteen :{
    name: "Alpa Dhingra",
    country:"India",
    subjects:"English",
    description:" A well experienced IB & IGCSE English tutor with a rich experience of 13 years. She works hand in hand with students to impart the learning."
, known_languages:' English'
  },
  tutorSixteen :{
    name: "Roshni Kathuria",
    country:"India",
    subjects:"English",
    description:"  She believes in the holistic development of kids where the main focus is on the basics for children’s growth. She imparts English lessons to the students of class 1-8 in a most natural and fun way."
, known_languages:' English'
  }
},
    about: {
        title: "Who we are",
        deliver: "Delivering services to…",
        student: "Student's",
        studentcontent: "With your favorite teacher and subjects at your fingertips, Ziyyara satisfies your learning  cravings and connects you with possibilities — more time and energy for yourself and those you love.",
        studentlink: "Learn Now",
        tutor: "Tutor’s",
        tutorcontent: "Ziyyara’s innovative learning-focused solutions enhance your success by transforming the way you teach.Open your doors to an entire teaching capability and see your revenue grow whenever you are whatever time you need.",
        tutorlink: "Join Now",
        ziyara: "Ziyyara Foundation",
        ziyaracontent: " More than 260 million children worldwide are out of school, yet more than half of those in  education are not learning, the World Bank has warned. At Ziyyara foundation we would like to  change that one school at a time. 15% of profit at Ziyyara will go to help a school in rural area by providing them with effective tool to learn, and hopefully we will change the student, the family and the society to a better future.",
        ziyaralink: "Change Now",
    },
    howitworks: {
        title: "How it works",
        registertitle: "Tutor's Registration Process",
        login: "Tutor's Login Process",
        stu_reg_log: "Student Registration & Login Process"
    },
    career: {
        title: "Career",
        para1: "At Ziyyara we value our team members as an asset for the organization, believe in empowering them and  enabling  them to discover their potential at work. We create an environment where skills are honed, achievements are   honored, and every contribution is valued.",
        para2: "Today, we are on a rapid growth path wherein we aim to sustain & strengthen our position as providers of  quality  services. This can only be achieved by the contribution of all our team members. Our focus is on    relentless value     addition to enhance the competencies of our people.",
        para3: "Our HR processes help you develop as a professional, grow in life and thereby contribute to the overall        growth of        the organization.",
        para4: " If you want to be a part of “Team Ziyyara”, send your resume to "
    },
    faq: {
      title: "FAQ",
      q1: "How does Ziyyara work?",
      q1a: "Ziyyara is an online one-on-one tuition platform which gives you focused academic support in a wide selection of subjects at the school level.Expert online tutors from Ziyyara will engage in LIVE, 1-on-1 tuition sessions with students to provide customized lessons and help them learn at their own pace.",
      q2: "Why should I use Google Chrome only?",
      q2a: "Ziyyara only recommends to use Google Chrome as it supports all the features and provides a best user experience.",
      q3: "What is the duration of each tuition sessions?",
      q3a: "Our online tuition sessions will last for 60 minutes. We have structured our lesson plans in such a way that the student-teacher interaction is no longer than 60 minutes, which has been   shown to be the threshold beyond which a student’s ability to learn deteriorates   significantly.",
      q4: "How does Ziyyara teach online work?",
      q4a: "Ziyyara uses an advanced interactive virtual classroom system that provides a real classroom experience in which our online tutors can focus entirely on teaching their student’s. It has an interactive virtual whiteboard, two-way video/audio and chat capability, and provides multiple innovative learning elements that make learning engaging and fun.",
      q5: "Will I have the access to the recorded sessions?",
      q5a: "All material discussed in class is made available to the students along with the recordings  of the tuition sessions for a specified period of the time. Parents can also access & review  the recorded video sessions at any time!",
      q6: "Does Ziyyara provides any study material or notes?",
      q6a: "We use the same book that the student follows at school as a point of reference while developing an individual learning plan for them. If required then the same individual learning plan will be shared with the parent and the student.",
      q7: "Can Online tuitions can be good as regular tuitions?",
      q7a: "Online tuitions are virtual home tuitions that take place over the internet. The only  difference between the two is your online, e-tutor is available at any time convenient to  our child, with the added benefit of safe learning from the comfort of your own home. It        also saves the child from the risk of commuting, thereby avoiding long hours of travel to a tuition center. With online tuitions, you can learn from anywhere, at any time.",
      q8: " What are the requirements for online classes at Ziyyara?",
      q8a_p1: " A modern PC or Laptop with Windows 7 or later version",
      q8a_p2: " A high-quality Headset with Microphone (Recommended: Webcam)",
      q8a_p3: " A reliable Broadband Internet Connection (Recommended: 2Mbps+)",
      q8a_p4: "  A digital pad/pen to write on whiteboard",
      q8a_p5: "We will perform a Technical Check of your system so that our IT support team can assist you to optimize your computer for Ziyyara e-Tutoring.",
      q9: " Will my tutor just give me the answers?",
      q9a: " Ziyyara’s philosophy is to help students understand the concepts behind what they are  working on, so that our students can learn how to solve the problem and reach the answer on their own. Ziyyara is not an answer-giving service.",
      q10: "Can I work with the same tutor again?",
      q10a: "Yes. You can add tutors to your Favorites list to see when they are available to have another session. If one of your favorite tutors is working with another student, you can get in line or let us find another great tutor to help you.",
      q11: " What if my tutor can't help me?",
      q11a: "If your tutor doesn’t know how to help with your specific problem then you will be allocated an another tutor and you can proceed with your session in next 2 hours. If you ever have a session, you're not completely happy with, you can request a credit for the minutes used. We will review the session, and if it doesn't meet our standards for quality or accuracy, we can credit those minutes back to your account!",
      q12: " Do I need an expertise in computer?",
      q12a: "No, definetly not. Just the basic computer skills like browsing the internet or checking e-mails will be enough. If you can log on and read this, you are already skilled to involve in this program. With online tuitions, you can learn from anywhere, at any time.",
      q13: "Is it possible to book a session at the same time?",
      q13a: " You can book any session at-least 2 hours before it starts. We offer packages and single doubt sessions. ",
      q14: " Do you have any terms & conditions and privacy policies?",
      q14a: "Yes, you can find our Terms &Conditions and Privacy Policy.",
    

      q15: "Recommended Classroom tip.",
      q15a: " Be prepared before your session by putting on your headset. Trying to do it during the    session    will require you to complete the setup process again, which you can only do outside of a live  lesson. So, use your time wisely by being prepared 15 minutes before the session.",
      q16: " What happens if I run out of credit?",
      q16a: "You credit your wallet before you start your online session, and you will be charged accordingly as it a session-based program.",
      q17: " Should I be concerned about money in my Wallet?",
      q17a: " We use a trusted third-party online payment provider, so whenever you recharge your wallet by crediting some amount, so it happens through a secure gateway. Most prominently, we don’t keep your bank account details on our website.",
      q18: "If signed once, so will it be a long-term contract?",
      q18a: "  No. This platform works on a pay as you go basis.",
      q19: " Consider the possibility that I am unhappy with my online tutor session.",
      q19a: " There may be rare instances when your tutor session doesn’t meet your expectations – neither Ziyyara nor the tutors want this to happen. Rest assured we will see to the situation and  our team will cross verify with both the parties. If there is a disagreement with a tutor,  we will assess the situation and decide accordingly.",
      q20: " Why should I have to pay for online tutoring?",
      q20a: " Tutor at Ziyyara will come handy at the rarest time when you can’t get hold of any teacher from school or private tuitions. Our flexible approach makes online tutoring a cost-effective alternative, so you will be paying for Tutor’s time, expertise and their timely assistance.",
      q21: "How do I contact Customer Support?",
      q21a: " Send us an email at info@ziyyara.in. We're available through Sunday to Saturday from 7 AM 9 PM Muscat Time."
  }, 

};
