<!-- <div class="loader-wrapper" *ngIf="loader"> 
<ngx-ui-loader [bgsType]="loaderService?.config?.bgsType"></ngx-ui-loader>
 </div> -->




<div class="loader-wrapper" *ngIf="loaderMain">
    <div class="loader-cont">
        <div class="dl">
            <img src="assets/images/z.webp">
            <div class="dl__container">
                <div class="dl__corner--top"></div>
                <div class="dl__corner--bottom"></div>
            </div>
            <div class="dl__square"></div>
        </div>
    </div>
</div>



<router-outlet>
</router-outlet>

<span class="fixed" title="Go to Top" (click)="scroltop()">
  <i class="fa fa-arrow-circle-up"></i>
</span>