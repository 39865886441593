import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-karnataka',
  templateUrl: './karnataka.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class KarnatakaComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What are the subjects taught by online home tutors in Karnataka at Ziyyara?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "There are several subjects covered by Ziyyara in providing online Karnataka home tuition. These are: 1. Science 2. Maths 3. Commerce 4. Arabic 5. English 6. Hindi 7. Computer Science 8. French And many more."
    }
  },{
    "@type": "Question",
    "name": "What grade do students need home tutoring?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara provides online home tuition in Karnataka from 1st to 12th grade of all boards, i.e., CBSE, IGCSE, and ICSE."
    }
  },{
    "@type": "Question",
    "name": "Can I get a home tutor for specific subjects?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyara provides an online tutor for each subject a student wants to get taught by professional tutors."
    }
  },{
    "@type": "Question",
    "name": "Is my money safe at Ziyyara’s online home tuition for Karnataka?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, the money you will pay is safe at Ziyyara Edutech, and here at, online Karnataka home tutors, we will also give the receipt of your paid fee to you and your mentor."
    }
  },{
    "@type": "Question",
    "name": "What if I do not like the trial class?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If you don't like the trial class at online Karnataka home tutors, we will arrange another online home tutor for you to match your subject requirement."
    }
  }]
}


cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
   this.titleService.setTitle("  Online Home Tuition In Karnataka | Home Tutor In Karnataka   ");
   var desc="- At Ziyyara Edutech, you don't need to worry about your child's Online home tuition in Karnataka fees as we offer cost-efficient online tutors in Karnataka."
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ name: 'keywords', content:"home tuition for karnataka, home tuition in karnataka, home tutor in karnataka, home tutors in karnataka, karnataka home tuition, karnataka home tutor, karnataka online home tutor, karnataka tutor for online tuition, online tuition in karnataka, online tutor for karnataka, tuition in karnataka, tuition online in karnataka, tutor in karnataka"})
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema)
  }

}
