import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-arunachal',
  templateUrl: './arunachal.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class ArunachalComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "I’m a student, how can I find an online tuition tutor in Arunachal Pradesh?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You can call or whatsapp us to know more about the tutors. Also you can register on our official website i.e. www.ziyyara.in and fill your preferences like subject you need online home tuition in Arunachal Pradesh, level, language, etc. and we will get back to you at the earliest by shortlisting the best tutor for you."
    }
  },{
    "@type": "Question",
    "name": "How can I get in touch with a tutor offering online tutoring in Arunachal Pradesh?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "In order to initiate a contact with a tutor, you need to register at our official website. Once you have successfully registered on our website, we will get in touch with you (email, phone or whatsapp chat) to know more about your requirements."
    }
  },{
    "@type": "Question",
    "name": "Can I get recorded classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, we give flexibility to all the students to get recorded classes of the classes they took. Recorded classes that satisfy particular learning needs of the students are provided by home tutors in Arunachal Pradesh to the students, upon request."
    }
  },{
    "@type": "Question",
    "name": "What if the subject I want to take Arunachal Pradesh home tutoring online classes on isn’t listed?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "In case the subjects you want to learn are not listed in the subject list, then you are advised to get in touch with us. We have a pool of teachers who are highly experienced in different subjects, so you can surely get the best teachers by getting in touch with us. We have many Arunachal Pradesh home tutors who meet your requirements, and allow you to get the best tutors who are experienced in that particular subject you are looking for."
    }
  }]
}

cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }


  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
    this.titleService.setTitle("Online Home Tuition in Arunachal Pradesh | Tutors in Arunachal Pradesh");
    this.meta.updateTag({name:'description', content:' The online home tuition for Arunachal Pradesh students delivered by us guide students throughout their school and help realize their dreams in the starting phase of their life.'})
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
    this.meta.updateTag({name: 'keywords', content:"home tuition in Arunachal pradesh, home tuition in Arunachal pradesh, tuition in Arunachal pradesh, home tutor in Arunachal pradesh,home tuition in Arunachal pradesh, home tuition for Arunachal pradesh, tuition in Arunachal pradesh, Arunachal pradesh home tuition, Online tuition in Arunachal pradesh, tuition online in Arunachal pradesh, Arunachal pradesh home tuition, Arunachal pradesh online tuition, online home tuition in Arunachal pradesh, home online tuition in Arunachal pradesh, online home tuition for Arunachal pradesh"})

  }

}
