import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-coimbatore',
  templateUrl: './coimbatore.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class CoimbatoreComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Which subjects’ coaching are available at your platform?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our online tutors in Coimbatore cover a wide variety of subjects like Maths, English, Science, Computing, Languages, Physics, Chemistry, Psychology, Business, Economics, Art, etc. Besides you can also learn French, German Chinese, Spanish, etc. during online tuition in Coimbatore."
    }
  },{
    "@type": "Question",
    "name": "When classes related to online home tuition for Coimbatore students are held?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Lessons are one-to-one and the time is decided mutually."
    }
  },{
    "@type": "Question",
    "name": "How can I get in touch with the support team?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You can reach our friendly support team by dropping an email at our official ID or can call us directly. We'd love to hear from you."
    }
  },{
    "@type": "Question",
    "name": "What if the assigned tutor is not able to clear my doubt?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "In case home tutors in Coimbatore assigned to you are not able to clear your doubt related to a specific problem, you will be allocated another tutor. You can immediately continue your classes so that your studies don’t get affected at all.  We will review the session, and if everything goes well, you can continue taking online Coimbatore home tutoring from thenew assigned tutor."
    }
  }]
}


cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle("  Online Home Tuition in Coimbatore | Tutors in Coimbatore | Tuition in Coimbatore");
   var desc=" To find a best online Coimbatore home tutor is not an easy task. But your search for the best online tuition in Coimbatore ends at Ziyyara as we offer pedagogy online learning. "
    this.meta.updateTag({ name: 'description', content: desc })
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url)
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
    this.meta.updateTag({name: 'keywords', content: 'home tuition in coimbatore, tuition in coimbatore, home tutor in coimbatore, tutors in coimbatore, home tuition in coimbatore, home tuition for coimbatore, tuition in coimbatore, coimbatore home tuition, Online tuition in coimbatore, tuition online in coimbatore, coimbatore home tuition, coimbatore online tuition, online home tuition in coimbatore, home online tuition in coimbatore, online home tuition for coimbatore',})
   
  }
 
  
}
