import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-lucknow',
  templateUrl: './lucknow.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class LucknowComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How online tuition classes in Lucknow work?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Online lessons or tuition online in Lucknow is quite different and purposeful as compared to offline classes."
    }
  },{
    "@type": "Question",
    "name": "What equipment is required?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You need to have a strong and stable internet connection to take online home tuition in Lucknow along with having a computer or laptop. To make classes more productive, all the students are recommended to use a headset with a microphone. You can also use a tablet or a smartphone for the video and audio. The tutors in Lucknow use whiteboards, and all are web-based.We have our inbuilt software through which both the tutors and students can see each other and can understand what is being taught in lucknow home tuition online classes."
    }
  },{
    "@type": "Question",
    "name": "Do you provide free trial as well?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, before the first lesson, all the students are allowed to book a free demo class so that they can get a better understanding about our teaching style. We don’t say that we provide the best quality online tuition in Lucknow rather give you a chance to judge our teaching style yourself."
    }
  },{
    "@type": "Question",
    "name": "Will it be difficult for my children to understand the technology used in online tutoring in Lucknow?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "These days, children are very smart and they are well-versed with the latest online platforms. Still in case, your children face any difficulty while taking home tuition classes in Lucknow, our Lucknow tutor for online tuition is highly experienced and will help your children in developing familiarity about the relevant software. There are chances that your children might take some time in getting familiarity with the concepts, but our tutors in Lucknow will leave no stone untouched in making your children pro with the latest technology."
    }
  },{
    "@type": "Question",
    "name": "What will happen in case technology stops working?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, there is a possibility that the internet might fail in the midst of the class. For such problems Ziyyara's coordinating team assists both the parties (including student & tutor). We always keep Plan B with us so that in case of any technical glitch, immediate assistance can be offered. If something doesn’t work at all, you can always reschedule your online home tuition for Lucknow at Ziyyara."
    }
  }]
}

cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }


  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
    this.titleService.setTitle("Online Home Tuition In Lucknow | Tutors in Lucknow | Home Tuition");
    this.meta.updateTag({name:'description', content:' We at Ziyyara make education accessible for those who look for an affordable online tuition in Lucknow. Get tuition from our online home tutors in Lucknow at any time.'})
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url)
    this.meta_service.removeStructuredData();
    this.meta.updateTag({name: 'keywords', content:'home tuition in lucknow, tuition in lucknow, tutor in lucknow, home tutors in lucknow, tutor in lucknow, home tuition in lucknow, home tuition for lucknow, tuition in lucknow, lucknow home tuition, Online tuition in lucknow, tuition online in lucknow, lucknow home tuition, lucknow online tuition, online home tuition in lucknow, home online tuition in lucknow'})
    this.meta_service.insertSchema(this.websiteSchema)

}
}