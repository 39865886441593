import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-noida',
  templateUrl: './noida.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class NoidaComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why should I choose Ziyyara’s Noida home tutor for my child’s tutoring?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our tutors offer real-time 2-way interactive classes for all major subjects of classes 5-10 of CBSE, ICSE and other State Boards. With expert online tutor for Noida, interactive white board, topic-wise study content and real-time doubt solving online classes are some factors that make us special from the rest."
    }
  },{
    "@type": "Question",
    "name": "Are these online classes recorded or LIVE?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "All the classes offered by us are LIVE by Noida online home tutor. We strongly believe that real-time interaction is vital for promoting effective learning."
    }
  },{
    "@type": "Question",
    "name": "At Ziyyara, is it possible to join a session mid-way?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, you can take online Noida home tuition classes anytime. You can join a batch mid-way as well, but we recommend you to take regular classes so that you can enjoy the flexibility of learning at your own pace. Though you can take the recordings of the previous sessions at no extra cost."
    }
  },{
    "@type": "Question",
    "name": "Can I get personalized online Noida home tutors’ tutoring for my son?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, you can. Since we provide 1-on-1 tutoring services by the dedicated home tutors in Noida, so all the classes can be customized based on the needs and requirements of the students."
    }
  },{
    "@type": "Question",
    "name": "Any other thing that I need to know related to online classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, parents must ensure that their children stay attentive and appear for the session offered by Ziyyara’s tutors in Noida on time. For smoother functioning of online home tuition in Noida classes, parents must check the proper functioning hardware as required. In case of any issue, you are requested to inform the same to Ziyyara’s coordinating team so that different classes can be fixed at another time."
    }
  }]
}


cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

 ngOnInit() {
  this.slides = this.chunk(this.cards, 3);
  this.titleService.setTitle("  Online Home Tuition in Noida | Tutors in Noida | Tuition in Noida");
  var desc=" Choose Ziyyara’s Online Home Tuition in Noida for all subjects. Our online home tutors in Noida offer the best teachings to the students of IB, CBSE, ICSE and other boards."
   this.meta.updateTag({ name: 'description', content: desc })

   this.meta.updateTag({ name: 'keywords', content:"home tuition in noida, tuition in noida, home tutor in noida, home tutors in noida, home tuition in noida, home tuition for noida, tuition in noida, noida home tuition, Online tuition in noida, tuition online in noida, noida home tuition, noida online tuition, online home tuition in noida, home online tuition in noida, online home tuition for noida"})
   var url=  location.pathname;
   this.meta_service.createCanonicalURL(url)
   this.meta_service.removeStructuredData();
   this.meta_service.insertSchema(this.websiteSchema)
    
  }
 
}
