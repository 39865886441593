import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-accountancy',
  templateUrl: './accountancy.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})


export class AccountancyComponent implements OnInit {

 
 
 
  tutorData=[  {
    name:" Arzoo Agarwal",
    exp:" 4 years ",
    grade:"6th to 10th "
  },{
    name:"Meenakshi Bhardwaj",
    exp:"10 years",
    grade:"1st to 12th "
 },{
    name:" Sonia Sharma",
    exp:"6 years",
    grade:"9th to 12th "   
  
  }     

]
 websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    
    {
    "@type": "Question",
    "name": "What is the meaning of Accounting?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Accounting is the art of identifying business terms, measuring them in terms of money, recording in the books of account, classifying those data, summarizing the data and making it interpretable and communicable. However, Accounting books consist of the records related to changes in liabilities, assets, equity, expense and revenue which is covered in Ziyyara’s online tuition of Accountancy by our expert Accounts tutors."
    }
  },
  
  {
    "@type": "Question",
    "name": "Why Accountancy online tuition?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A student can save a lot of commuting time by taking Accountancy online tuition as they don’t have to go to a teacher’s place to get tutored. Ziyyara’s one-on-one online tuition classes allow a student to take classes at the comfort of their homes at any time. A student just needs a desktop or a laptop and a good internet connection and with the help of these devices you are ready to take Accountancy home tuition classes."
    }
  },
  
  {
    "@type": "Question",
    "name": "How can students achieve high scores in Accountancy subjects with Ziyyara?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The concept of Online tuition is quite similar to Accountancy home classes and Ziyyara understands the need for personalized attention or a sense of belonging which helps the students to ask questions without hesitation, therefore results in achieving high scores."
    }
  },
  
  {
    "@type": "Question",
    "name": "How does Ziyyara’s Online Accountancy Tuition give you an extra boost rather than other tuitions?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Ziyyar offers one-on-one live online tuition which is deemed as one of the best features of online learning or called as online home tuition of Accountancy. Every student needs one-on-one attention, hence the reason for undivided attention which gives a boost to the student and they feel valued."
    }
  },
  {
    "@type": "Question",
    "name": "How is Ziyyara's One-on-One Online tuition of Accountancy different from Accountancy coaching classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "At Ziyyara, we don’t offer one to many student options and use a holistic approach while teaching. Our platform is following one tutor and one student policy and quite similar to Accountancy home tuition as it happens at home in your familiar surroundings."
    }
  }
,
  {
    "@type": "Question",
    "name": "Why do Ziyyara's students never give up their class, but instead recommend other students to join?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "(a). Live online interactive classes (b). Pay as you go payment option (c). Regular revision & assessments (d). One-to-one live classes instead of group classes. (e). Online whiteboard- audio/video enabled (f). Class recording option (helps at the time of revision) (g). Freedom to Ask Questions (h). Affordable Price (i). Study from the comfort of your familiar surroundings"
    }
  }]
}

cards = [
  {
    title: 'Economics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Economics.jpg'
  },
  {
    title: 'Maths',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Mathematics.jpg'
  },
  {
    title: 'English',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/English.jpg'
  },
  {
    title: 'Physics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Physics.jpg'
  },
  {
    title: 'History',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/History.jpg'
  },
  {
    title: 'Hindi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Hindi.jpg'
  },
  {
    title: 'Geography',   
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Geography.jpg'
  },
  {
    title: 'Business Studies',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Business-Studies.jpg'
  },
  {
    title: 'Biology',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Biology.jpg'
  },
  {
    title: 'Political-Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Political-Science.jpg'
  },
  // {
  //   title: 'Accountancy',
  //  // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
  //   buttonText: 'Read',
  //   img: '/assets/images/Accountancy.jpg'
  // },
  {
    title: 'Chemistry',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Chemistry.jpg'
  },

  {
    title: 'Computer Science',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/Computer-Science.jpg'
  },
  {
    title: 'Vedic Mathematics',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/vedic-math.webp'
  },
];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }
  ngOnInit() {

    //slider
    this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle("Best Online Tuition for Accountancy | Accountancy Online Tuition");
   var desc=" Ziyyara offers one-on-one live online tuition for accountancy. We offer Accountancy online tuition classes with the help of best accountancy tutors. For free demo call us on +91 9654271931"
   this.meta.updateTag({ name: 'description', content: desc })
   var keywords="accountancy tuition, accountancy tutor, accountancy tuition near me, , Accountancy home tutor, tutor for Accountancy, Accountancy home tutors, Accountancy tutor at home, Accountancy tuition tutor, Accountancy private tutor, private tutor for Accountancy, Online tuition for Accountancy, online tuition Accountancy, Accountancy online tuition, tuition for Accountancy, Accountancy tuition classes, tuition classes of Accountancy";
   this.meta.updateTag({ name: 'keywords', content: keywords });
   var url=  location.pathname;

   var ogtitle=" Best Online Tuition for Accountancy | Accountancy Online Tuition";
   this.meta.updateTag({ property: 'og:title', content: ogtitle });
   var ogdesc="Ziyyara offers one-on-one live online tuition for accountancy. We offer Accountancy online tuition classes with the help of best accountancy tutors. For free demo call us on +91 9654271931";
   this.meta.updateTag({ property:'og:description', content: ogdesc });

   var twittertitle="Best Online Tuition for Accountancy | Accountancy Online Tuition";
   this.meta.updateTag({ name:"twitter:title", content: twittertitle });
   var twitterdesc="Ziyyara offers one-on-one live online tuition for accountancy. We offer Accountancy online tuition classes with the help of best accountancy tutors. For free demo call us on +91 9654271931";
   this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
   this.meta_service.createCanonicalURL(url);
   this.meta_service.removeStructuredData();
   this.meta_service.insertSchema(this.websiteSchema);
}

generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  if(subject.includes("vedic"))
  
  {
    return "/vedic-math-workshop"
  
  } 
    else if (subject.includes("computer")){
      return '/home-tuition/online-home-tuition-for-computer-science'
    }
    else if (subject.includes("business")){
      return '/home-tuition/online-home-tuition-for-business-studies'
    }
  else {

    return '/home-tuition/online-home-tuition-for-'+subject
  }
}
// }
//   return '/home-tuition/online-home-tuition-for-'+subject
// }

}
