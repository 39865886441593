import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { HeaderFrontComponent } from './components/header-front/header-front.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SharedModule } from '../shared/shared.module';
import { ConfirmationBoxComponent } from '../shared/components/confirmation-box/confirmation-box.component';
import { FooterFrontComponent } from './components/footer-front/footer.component';
import { MatMenuModule } from '@angular/material';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { HomeSignUpFormComponent } from './components/home-sign-up-form/home-sign-up-form.component';
import { BoardsFooterComponent } from './components/boards-footer/boards-footer.component';
import { CitiesFooterComponent } from './components/cities-footer/cities-footer.component';
import { ModalSignUpComponent } from './components/modal-sign-up/modal-sign-up.component';
import { MatIconModule } from '@angular/material/icon';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TestingComponent } from './components/testing/testing.component';
 
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    SharedModule,
    MatIconModule
  ],
  declarations: [
    SidenavComponent,
    HeaderComponent,
    FooterComponent,
    HeaderFrontComponent,
    FooterFrontComponent,
    RegistrationFormComponent,
    HomeSignUpFormComponent,
    BoardsFooterComponent,
    CitiesFooterComponent,
    ModalSignUpComponent,
    TestingComponent,
  
  ],

  exports: [
    HeaderFrontComponent,
    HeaderComponent,
    FooterComponent,
    SidenavComponent,
    FooterFrontComponent,
    RegistrationFormComponent,
    HomeSignUpFormComponent,
    CitiesFooterComponent,
    BoardsFooterComponent,
    TestingComponent
 
  ],
  entryComponents: [ConfirmationBoxComponent, HomeSignUpFormComponent,ModalSignUpComponent]
})
export class CommonComponentModule {

}
