import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-isc',
  templateUrl: './isc.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class IscComponent implements OnInit {

  



tutorData=[  {
  name:"Saruchi",
  exp:" 4 years ",
  grade:"6th to 12th "
},{
  name:"Shivani gogia",
  exp:"10 years",
  grade:"1st to 5th "
},{
  name:"Fayaz Bhatt",
  exp:"5 years",
  grade:"9th to 12th "

}

]
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Do you think online ISC home tuition will prove effective for my child?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, advancement in technology has brought significant changes in the education system as well. Gone are the days when students needed to travel a lot to reach their tuition center for taking classes. Today online tutoring offers many benefits which include better understanding, ease, better clarity of concepts, reduced cost and much more."
    }
  },{
    "@type": "Question",
    "name": "If I clear the Class X Exam from another board and get admission in the next class of ISC?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You will get a chance to study at higher class but to get promoted to class XI, it is essential to pass the previous exam from a recognized board."
    }
  },{
    "@type": "Question",
    "name": "How to enroll at Ziyyara for online ISC home classes?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Registration at Ziyyara is quite easy. You first have to register at Ziyyara so that you can get a free demo for online ISC home tuition."
    }
  },{
    "@type": "Question",
    "name": "What is ISC and the ISC board full form?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The detailed and complete name of ISC is Indian School Certificate. The board takes higher secondary exams or 12th class exams each year during Feb-March."
    }
  },{
    "@type": "Question",
    "name": "Do all the pupils of the ISC board get promoted to higher class? How much do I need to score in subjects to get promoted?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Students who study harder by taking Ziyyara’s online tuition of ISC don’t fail the ISC examination. In comparison to other board papers, exams asked in ISC board are quite relaxed and there is lenient checking, which help students easily crack the examination. But, pupils who are not motivated at all find even this board difficult and are not able to get the lowest passing marks. Students are supposed to get not less than 35% in ISC Board. So enroll for Ziyyara’s ISC home tuition classes now"
    }
  },{
    "@type": "Question",
    "name": "What is the difficulty level of this in contrast to different boards?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Course wise, not much alterations are seen. But the English taught in ISC Board is considered difficult as compared to other boards, but the other core subjects are not that difficult. One can boost their scores in this board in case they study harder by taking Ziyyara’s online ISC home tutors assistance. In terms of weightage, both ISC and CBSE have equal weightage."
    }
  }]
}
  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle(" Online Tuition For ISC Board | Online ISC Board Tuition  ");
   var desc=" Ziyyara offers one-to-one online tuition for ISC Board students. They implement pedagogy teaching methods while delivering online ISC board tuition. Book a free demo."
    this.meta.updateTag({ name: 'description', content: desc })
    var url=  location.pathname;
    var ogtitle="Online Tuition For ISC Board | Online ISC Board Tuition";
    this.meta.updateTag({ property: 'og:title', content: ogtitle });
   var ogdesc="  Ziyyara offers one-to-one online tuition for ISC Board students. They implement pedagogy teaching methods while delivering online ISC board tuition. Book a free demo.   ";
   this.meta.updateTag({ property:'og:description', content: ogdesc });
 
   var twittertitle="Online Tuition For ISC Board | Online ISC Board Tuition";
   this.meta.updateTag({ name:"twitter:title", content: twittertitle });
  var twitterdesc="   Ziyyara offers one-to-one online tuition for ISC Board students. They implement pedagogy teaching methods while delivering online ISC board tuition. Book a free demo. ";
  this.meta.updateTag({ name:'twitter:description', content: twitterdesc });
    this.meta_service.createCanonicalURL(url);
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
    this.meta.updateTag({name: 'keywords', content:"ISC board, what is ISC, ISC board syllabus, ISC board in india, ISC online tutiton, online ISC tuition, ISC online home tuition, ISC home tuition, ISC home tutor, ISC Home Tuition, Private Tutors For ISC, Isc home tuition Classes, ISC tutor, tuition of ISC"})
 
  }


  slides: any = [[]];
  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }
  
 
  cards = [
    {
      title: 'IB Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-ib-board.webp'
    },
    {
      title: 'ICSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-icse-board.webp'
    },
    {
      title: 'CBSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cbse-board.webp'
    },
    {
      title: 'ISC Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-isc-board.webp'
    },
    {
      title: 'IGCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-igcse-board.webp'
    },
   
    {
      title: 'CISCE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cisce-board.webp'
    },
    {
      title: 'GCSE Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-gcse-board.webp'
    },
    {
      title: 'Cambridge International-Board',
     // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
      buttonText: 'Read',
      img: '/assets/images/boards/online-tuition-for-cambridge-international-board.webp'
    },
   
  
  
  ];
  generateSubjectLink(sub:string){

    var subject=sub.toString().toLowerCase().replace(" ","-");
    return '/home-tuition/online-home-tuition-for-'+subject;
  }
}
