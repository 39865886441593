import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';

import { CommonComponentModule } from '../common-component/common-component.module';
import { GroupStudyComponent } from './group-study/group-study.component';
import { ViewProfileComponent } from './view-profile/view-profile.component';

const pageRoutes: Routes = [

  { path: '', component: GroupStudyComponent },
  { path: 'view', component: ViewProfileComponent },
];

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    SharedModule,
    CommonComponentModule,
    RouterModule.forChild(pageRoutes),

  ],
  declarations: [GroupStudyComponent,ViewProfileComponent],
  entryComponents: []
})
export class GroupStudyModule { }


