import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
declare var Swal: any;
@Component({
  selector: 'app-assam',
  templateUrl: './assam.component.html',
  styleUrls: ['../home-tuition/home-tuition.component.css']
})
export class AssamComponent implements OnInit {
websiteSchema={
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Am I allowed to reject Assam home tutor if not satisfied with his/her teaching style?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, you have the right to accept and reject online tutor for Assam in case you find some issue in his/her teaching style. However, we are sure that there would not be any valid reasons for rejection as we have a team of expert tutors who leave no stone untouched in clearing all the concepts. Some tutors might have the mother tongue influence on their accent but trust us they are the best tutors in their field."
    }
  },{
    "@type": "Question",
    "name": "If once an Assam online home tutor is assigned, can I get the same tutor for the entire year?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, if a student wants an online tuition in Assam from the same tutor, then it’s possible as all the teachers work with us on a regular basis. But tutors can be changed if required, upon students’ request. And in case of teachers discontinuation from our platform, we provide the best replacement so that studies of children don’t get affected at all."
    }
  },{
    "@type": "Question",
    "name": "Can I trust Ziyyara’s new tutors offering online tutoring in Assam?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We suggest that parents also sit with their children while they take demo class from Ziyyara’ Assam home tutors. The parents are suggested to share their valuable feedback with the coordinating team, so that the changes, if required, can be made. We take in charge of Assam home tutoring and stay connected with all the parents."
    }
  }]
}

cards = [
  {
    title: 'Ahmedabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ahmedabad.webp'
  },

  {
    title: ' Andaman Nicobar',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-andaman-nicobar.webp'
  },
  {
    title: 'Arunanchal Pradesh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-arunanchal-pradesh.webp'
  },
  {
    title: 'Assam',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-assam.webp'
  },
  {
    title: 'Banglore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-banglore.webp'
  },
  {
    title: 'Chandigarh',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chandigarh.webp'
  },
  {
    title: 'Chennai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-chennai.webp'
  },
  {
    title: 'Coimbatore',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-coimbatore.webp'
  },
  {
    title: 'Delhi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-delhi.webp'
  },
  {
    title: 'Gujrat',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gujrat.webp'
  },
  {
    title: 'Gurugram',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-gurugram.webp'
  },
  {
    title: 'Hyderabad',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-hyderabad.webp'
  },
  {
    title: 'Jaipur',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-jaipur.webp'
  },
  {
    title: 'Karnataka',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-karnataka.webp'
  },
  {
    title: 'Kerala',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kerala.webp'
  },
  {
    title: 'Kolkata',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-kolkata.webp'
  },
  {
    title: 'Lucknow',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-lucknow.webp'
  },
  {
    title: 'Mumbai',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-mumbai.webp'
  },
  {
    title: 'Noida',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-noida.webp'
  },
  {
    title: 'Pune',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-pune.webp'
  },
  {
    title: 'Ranchi',
   // description: 'Some quick example text to build on the card title and make up the bulk of the card content',
    buttonText: 'Read',
    img: '/assets/images/cities/online-tuition-in-ranchi.webp'
  },

];
slides: any = [[]];
chunk(arr, chunkSize) {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}
 
generateSubjectLink(sub:string){

  var subject=sub.toString().toLowerCase()
  

  if(subject.includes("andaman")){
    return '/home-tuition/online-home-tuition-in-andaman-nicobar'
   }
else if(subject.includes("arunanchal")){
    return '/home-tuition/online-home-tuition-in-arunachal-pradesh'
   }

   else {return '/home-tuition/online-home-tuition-in-'+subject }
    
}

  constructor(private titleService: Title, private meta: Meta, private meta_service : MetaService) { }

  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
   this.titleService.setTitle("Online Home Tuition in Assam | Tutors in Assam | Tuition in Assam");
   var desc=" Ziyyara’s home tutor in Assam are well-versed and updated with IB, CBSE & ICSE curriculum. Get online tuition in Assam for class 5-12 for all subjects and ace your exams."
    this.meta.updateTag({ name: 'description', content: desc })
    var url=  location.pathname;
    this.meta_service.createCanonicalURL(url)
    this.meta_service.removeStructuredData();
    this.meta_service.insertSchema(this.websiteSchema);
    this.meta.updateTag({name:"keywords", content:"home tuition in Assam, home tuition in Assam, tuition in Assam, home tutor in Assam, home tuition in Assam, home tuition for Assam, tuition in Assam, Assam home tuition, Online tuition in Assam, tuition online in Assam, Assam home tuition, Assam online tuition, online home tuition in Assam, home online tuition in Assam, online home tuition for Assam"})
 
  }
}
